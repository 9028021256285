import React, { useEffect, useState } from "react";
import { Grid, Dimmer, Loader, Header } from "semantic-ui-react";
import { useStitchAuth } from "../StitchAuth";
import { useStitchProviders } from "../StitchProviders";
import { ResponsiveBar } from "@nivo/bar";

function Chart(props) {
  return (
    <Grid.Column>
      <Header>
        <Header.Subheader>{props.title}</Header.Subheader>
      </Header>
      <ResponsiveBar
        data={props.data}
        height={30 * props.data.length}
        keys={["benchmark", "cost"]}
        indexBy="name_npi"
        layout="horizontal"
        groupMode="grouped"
        margin={{ top: 0, right: 10, bottom: 100, left: 310 }}
        padding={0.3}
        valueScale={{ type: "linear" }}
        indexScale={{ type: "band", round: true }}
        colors={{ scheme: "nivo" }}
        axisTop={null}
        axisRight={null}
        axisBottom={{
          tickSize: 5,
          tickPadding: 5,
          tickRotation: -45,
          legend: "",
          legendPosition: "middle",
          legendOffset: 50,
        }}
        axisLeft={{
          tickSize: 5,
          tickPadding: 5,
          tickRotation: 0,
          legend: "",
          legendPosition: "middle",
          legendOffset: -60,
        }}
        labelSkipWidth={12}
        labelSkipHeight={12}
        labelTextColor={{
          from: "color",
          modifiers: [["darker", 1.6]],
        }}
        legends={[
          {
            dataFrom: "keys",
            anchor: "top-center",
            direction: "row",
            justify: false,
            translateX: 120,
            translateY: 0,
            itemsSpacing: 2,
            itemWidth: 100,
            itemHeight: 20,
            itemDirection: "left-to-right",
            itemOpacity: 0.85,
            symbolSize: 20,
            effects: [
              {
                on: "hover",
                style: {
                  itemOpacity: 1,
                },
              },
            ],
          },
        ]}
      />
    </Grid.Column>
  );
}

export default function Overview(props) {
  const { userCustomData } = useStitchAuth();
  const { contractOne, actions } = useStitchProviders();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (userCustomData) {
      actions.loadContract(userCustomData.group_id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userCustomData]);

  useEffect(() => {
    if (contractOne) {
      setLoading(false);
    }
  }, [contractOne]);

  if (loading) {
    return (
      <div className="messages box">
        <Dimmer active inverted>
          <Loader inverted>Loading</Loader>
        </Dimmer>
      </div>
    );
  } else {
    return (
      <div className="messages box">
        {contractOne && userCustomData && (
          <Grid style={{ margin: 0 }}>
            <Grid.Row columns="one">
              <Chart
                title="PCP Performance - with Estiamted Benchmarks and Average Total Spends"
                data={contractOne.pcp_performance}
              />
            </Grid.Row>
          </Grid>
        )}
      </div>
    );
  }
}
