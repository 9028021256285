import React, { useState, useEffect } from "react";
import { Grid, Form, Dropdown, Modal, Dimmer, Loader } from "semantic-ui-react";
import ErrorBoundary from "react-error-boundary";
import { useStitchAuth } from "../StitchAuth";

export default function FormUser(props) {
  const { userCustomData, actions } = useStitchAuth();
  const [name, setName] = useState("");
  const [namePublic, setNamePublic] = useState("");
  const [npi, setNpi] = useState("");
  const [timeZone, setTimeZone] = useState("America/Los_Angeles");
  const [adt, setAdt] = useState("none");
  const timeZoneOptions = [
    {
      key: "America/New_York",
      text: "America/New_York",
      value: "America/New_York",
    },
    {
      key: "America/Chicago",
      text: "America/Chicago",
      value: "America/Chicago",
    },
    {
      key: "America/Los_Angeles",
      text: "America/Los_Angeles",
      value: "America/Los_Angeles",
    },
  ];
  const [changed, setChanged] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();
    actions.updateCustomData({
      name: name,
      namePublic: namePublic,
      npi: npi,
      timeZone: timeZone,
      adtAlert: adt !== "none",
      adtTarbet: adt,
    });
    setChanged(true);
  };

  useEffect(() => {
    if (userCustomData) {
      setName(userCustomData.name || "");
      setNamePublic(userCustomData.namePublic || "");
      setNpi(userCustomData.npi || "");
      setAdt(userCustomData.adtTarget || "none");
      setTimeZone(userCustomData.timeZone || "America/New_York");
    }
  }, [userCustomData]);

  if (!userCustomData) {
    return (
      <div className="messages box">
        <Dimmer active inverted>
          <Loader inverted>Loading</Loader>
        </Dimmer>
      </div>
    );
  } else {
    return (
      <ErrorBoundary>
        <Grid style={{ margin: 0, paddingBottom: "5em", paddingLeft: "2em" }}>
          <Grid.Row columns={2}>
            <Grid.Column>
              <Form>
                <Form.Input
                  fluid
                  name="name"
                  label="Name"
                  placeholder="{First Name} {Last Name}"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
                <Form.Input
                  fluid
                  name="name"
                  label="Name (patient-facing)"
                  placeholder="Dr. {Last Name}"
                  value={namePublic}
                  onChange={(e) => setNamePublic(e.target.value)}
                />
                <Form.Input
                  fluid
                  name="npi"
                  label="NPI"
                  placeholder="11 digit number"
                  value={npi}
                  onChange={(e) => setNamePublic(e.target.value)}
                />
                <Form.Field>
                  <label>Default Timezone</label>
                  <Dropdown
                    placeholder="."
                    fluid
                    selection
                    options={timeZoneOptions}
                    value={timeZone}
                    onChange={(e, v) => setTimeZone(v.value)}
                  />
                </Form.Field>
                <Form.Field>
                  <label>ADT Daily E-mail Notification</label>
                  <Dropdown
                    placeholder="."
                    fluid
                    selection
                    options={[
                      {
                        key: "none",
                        text: "No Email Notification",
                        value: "none",
                      },
                      {
                        key: "mine",
                        text: "Only for My Patients",
                        value: "mine",
                      },
                      {
                        key: "all",
                        text: "All ACO Patients",
                        value: "all",
                      },
                    ]}
                    value={adt}
                    onChange={(e, v) => setAdt(v.value)}
                  />
                </Form.Field>
                <Form.Button primary onClick={handleSubmit}>
                  Change Settings
                </Form.Button>
              </Form>
            </Grid.Column>
            <Grid.Column></Grid.Column>
          </Grid.Row>
        </Grid>
        <Modal
          open={changed}
          basic
          onClose={() => setChanged(false)}
          closeIcon
          size="small"
        >
          <Modal.Header>We updated your settings.</Modal.Header>
        </Modal>
      </ErrorBoundary>
    );
  }
}
