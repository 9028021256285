import React, { useEffect, useState } from "react";
import { Grid, Dimmer, Loader, Table } from "semantic-ui-react";
import { useStitchAuth } from "../StitchAuth";
import { numberWithCommas } from "../Common/utils";
import accounting from "accounting-js";

export default function Financials(props) {
  const {
    userCustomData,
    db: { financials },
  } = useStitchAuth();
  const [data, setData] = useState();
  const [loading, setLoading] = useState(true);
  const nonDollars = [
    "TIMES: Risk Score",
    "TIMES: Eligible Months",
    "TIMES: DCE Regional Rate Baseline Adjustment",
    "TIMES: Retrospective Trend Adjustment",
    "Completion Factor for Claims-Based Payments",
  ];
  const boldStyle = [
    "Benchmark Expenditure for All Aligned Beneficiaries",
    "EQUALS: Benchmark Expenditure after Discount & Retention Withhold",
    "EQUALS: Benchmark Expenditure after Earned Quality",
    "EQUALS: Total Cost of Care before Stop-Loss",
    "Total Cost of Care with Estimated IBNR Claims-Based Payments",
    "Gross Savings (Losses)",
    "Gross Savings (Losses) to be shared with DCE",
  ];
  useEffect(() => {
    if (userCustomData) {
      financials.findOne({ owner_id: userCustomData.group_id }).then((res) => {
        setData(res);
      });
      setLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userCustomData, financials]);

  if (loading) {
    return (
      <div className="messages box">
        <Dimmer active inverted>
          <Loader inverted>Loading</Loader>
        </Dimmer>
      </div>
    );
  } else {
    return (
      <div className="messages box">
        {userCustomData && data && (
          <Grid style={{ margin: 0 }}>
            <Grid.Row columns={1}>
              <Grid.Column>
                <p>
                  You can compare three different models for calculating the
                  Financial Statement and IBNR estimates (colored cells in the
                  table). Each model uses a different parameter set with varying
                  assumptions. <b>Model G (Global)</b> uses parameters estiamted
                  from all ACOs in our database, while <b>Model L (Local)</b>{" "}
                  applies parameters from only the target ACO.{" "}
                  <b>Model W (Weighted)</b> is a Bayesian mix of Model G and
                  Model L. The building blocks of these models follow a standard{" "}
                  <a href="https://en.wikipedia.org/wiki/Chain-ladder_method">
                    Chain-Ladder method
                  </a>
                  , a prominent actuarial loss reserving technique.
                </p>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row columns={1}>
              <Grid.Column>
                <p>
                  <b>Parameters in this Report</b>
                </p>
                <p>
                  Performance Year: <b>{data.params["Performance Year"]}</b>,
                  Custom Benchmark Boost Factor:{" "}
                  <b>{Math.round(data.params["boost_factor"] * 1000) / 1000}</b>
                  , AD Retrospective Trend:{" "}
                  <b>{data.params["A&D Retrospective Trend"]}</b>, ESRD
                  Retrospective Trend:{" "}
                  <b>{data.params["ESRD Retrospective Trend"]}</b>, Shared
                  Savings Rate: <b>{data.params["É Shared Savings Rate"]}</b>
                </p>
                <p>
                  Custom Benhmark Boost Factor takes into account of
                  re-adjustment of Risk Score, Normalization Factor, and
                  Retrospective Adjustment Factor.
                </p>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row columns={1}>
              <Grid.Column>
                <Table size="small" textAlign="right" collapsing>
                  <Table.Header>
                    <Table.Row>
                      <Table.HeaderCell></Table.HeaderCell>
                      {[
                        "AD",
                        "ESRD",
                        "TOTAL",
                        "MODEL W",
                        "MODEL L",
                        "MODEL G",
                      ].map((x) => (
                        <Table.HeaderCell key={x}>{x}</Table.HeaderCell>
                      ))}
                    </Table.Row>
                  </Table.Header>
                  <Table.Body>
                    {data.fsdata.map((item) => (
                      <Table.Row
                        key={item.CID}
                        style={{
                          fontWeight: boldStyle.includes(item.varname)
                            ? 800
                            : 400,
                        }}
                        positive={boldStyle.includes(item.varname)}
                      >
                        <Table.Cell textAlign="left">
                          {item.CID}. {item.varname}
                        </Table.Cell>
                        <Table.Cell>
                          {item.AD !== 0 && (
                            <span
                              className={
                                item.AD > 0 ? "text black" : "text red"
                              }
                            >
                              {nonDollars.includes(item.varname)
                                ? numberWithCommas(
                                    Math.round(item.AD * 100) / 100
                                  )
                                : accounting.format(item.AD, {
                                    format: {
                                      pos: "%s %v",
                                      neg: "(%s %v)",
                                      zero: "%s  -- ",
                                    },
                                  })}
                            </span>
                          )}
                        </Table.Cell>
                        <Table.Cell>
                          {item.ESRD !== 0 && (
                            <span
                              className={
                                item.ESRD > 0 ? "text black" : "text red"
                              }
                            >
                              {nonDollars.includes(item.varname)
                                ? numberWithCommas(
                                    Math.round(item.ESRD * 100) / 100
                                  )
                                : accounting.format(item.ESRD, {
                                    format: {
                                      pos: "%s %v",
                                      neg: "(%s %v)",
                                      zero: "%s  -- ",
                                    },
                                  })}
                            </span>
                          )}
                        </Table.Cell>
                        <Table.Cell>
                          {item.TOTAL !== 0 && (
                            <span
                              className={
                                item.TOTAL > 0 ? "text black" : "text red"
                              }
                            >
                              {nonDollars.includes(item.varname)
                                ? numberWithCommas(
                                    Math.round(item.TOTAL * 100) / 100
                                  )
                                : accounting.format(item.TOTAL, {
                                    format: {
                                      pos: "%s %v",
                                      neg: "(%s %v)",
                                      zero: "%s  -- ",
                                    },
                                  })}
                            </span>
                          )}
                        </Table.Cell>
                        <Table.Cell warning={true}>
                          {item.ESTIMATE_W !== 0 && (
                            <span
                              className={
                                item.ESTIMATE_W > 0 ? "text black" : "text red"
                              }
                            >
                              {nonDollars.includes(item.varname)
                                ? numberWithCommas(
                                    Math.round(item.ESTIMATE_W * 100) / 100
                                  )
                                : accounting.format(item.ESTIMATE_W, {
                                    format: {
                                      pos: "%s %v",
                                      neg: "(%s %v)",
                                      zero: "%s  -- ",
                                    },
                                  })}
                            </span>
                          )}
                        </Table.Cell>
                        <Table.Cell warning={true}>
                          {item.ESTIMATE_L !== 0 && (
                            <span
                              className={
                                item.ESTIMATE_L > 0 ? "text black" : "text red"
                              }
                            >
                              {nonDollars.includes(item.varname)
                                ? numberWithCommas(
                                    Math.round(item.ESTIMATE_L * 100) / 100
                                  )
                                : accounting.format(item.ESTIMATE_L, {
                                    format: {
                                      pos: "%s %v",
                                      neg: "(%s %v)",
                                      zero: "%s  -- ",
                                    },
                                  })}
                            </span>
                          )}
                        </Table.Cell>
                        <Table.Cell warning={true}>
                          {item.ESTIMATE_G !== 0 && (
                            <span
                              className={
                                item.ESTIMATE_G > 0 ? "text black" : "text red"
                              }
                            >
                              {nonDollars.includes(item.varname)
                                ? numberWithCommas(
                                    Math.round(item.ESTIMATE_G * 100) / 100
                                  )
                                : accounting.format(item.ESTIMATE_G, {
                                    format: {
                                      pos: "%s %v",
                                      neg: "(%s %v)",
                                      zero: "%s  -- ",
                                    },
                                  })}
                            </span>
                          )}
                        </Table.Cell>
                      </Table.Row>
                    ))}
                  </Table.Body>
                </Table>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        )}
      </div>
    );
  }
}
