import React, { useEffect, useState } from "react";
import { Grid, Dimmer, Loader, List, Header } from "semantic-ui-react";
import { useStitchAuth } from "../StitchAuth";

export default function Files(props) {
  const { userCustomData } = useStitchAuth();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (userCustomData) {
      setLoading(false);
    }
  }, [userCustomData]);

  if (loading) {
    return (
      <div className="messages box">
        <Dimmer active inverted>
          <Loader inverted>Loading</Loader>
        </Dimmer>
      </div>
    );
  } else {
    return (
      <div className="messages box">
        <Grid style={{ margin: 0 }}>
          <Grid.Row columns={2}>
            <Grid.Column>
              <p>
                These are the list of files that are processed and loaded to
                ARQ. You can check if any data files are missing or lagging to
                power ARQ.
              </p>
              <Header>Participant List</Header>
              <List>
                <List.Item>
                  {(
                    userCustomData.files.find(
                      (x) => x.filetype === "Participant List"
                    ) || {}
                  ).fn || "None"}
                </List.Item>
              </List>
              <Header>Member Alignment Report</Header>
              <List>
                <List.Item>
                  {(
                    userCustomData.files.find(
                      (x) => x.filetype === "Member Alignment Report"
                    ) || {}
                  ).fn || "None"}
                </List.Item>
              </List>
              <Header>Provider Alignment Report</Header>
              <List>
                <List.Item>
                  {(
                    userCustomData.files.find(
                      (x) => x.filetype === "Provider Alignment Report"
                    ) || {}
                  ).fn || "None"}
                </List.Item>
              </List>
              <Header>Risk Adjustment Report </Header>
              <List>
                <List.Item>
                  {(
                    userCustomData.files.find(
                      (x) => x.filetype === "Risk Adjustment Report"
                    ) || {}
                  ).fn || "None"}
                </List.Item>
              </List>
              <Header>Claim and Claim Line Feed (CCLF)</Header>
              <List>
                {(
                  userCustomData.files.filter(
                    (x) => x.filetype === "Claim and Claim Line Feed (CCLF)"
                  ) || []
                ).map((x) => (
                  <List.Item key={x.fn}>
                    CCLF{x.cclf_num} | {x.fn}
                  </List.Item>
                ))}
              </List>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </div>
    );
  }
}
