import React from "react";
import { Menu, Header } from "semantic-ui-react";
import { StitchProvidersProvider } from "../StitchProviders";
import AppBar from "../Common/AppBar";
import Workspace from "../Common/Workspace";
import "../Common/Layout.css";
import Overview from "./Overview";
import Wcrfs from "./Wcrfs";
import ClaimLags from "./ClaimLags";
import Financials from "./Financials";
import Files from "./Files";
import PCPPerformance from "./PCPPerformance";
import { useParams, useHistory } from "react-router-dom";

export default function Contracts(props) {
  const { view } = useParams();
  const history = useHistory();

  return (
    <StitchProvidersProvider>
      <div className="app-layout">
        <AppBar />
        <Workspace />
        <div className="channels box">
          <Header as="h4" style={{ paddingLeft: "1em" }}>
            Contracts
          </Header>
          <Menu vertical secondary style={{ background: "transparent" }}>
            <Menu.Item
              active={view === "overview"}
              onClick={() => history.push("/contracts/overview")}
            >
              #&nbsp;&nbsp;overview
            </Menu.Item>
            <Menu.Item
              active={view === "claimlags"}
              onClick={() => history.push("/contracts/claimlags")}
            >
              #&nbsp;&nbsp;claim-lags
            </Menu.Item>
            <Menu.Item
              active={view === "financials"}
              onClick={() => history.push("/contracts/financials")}
            >
              #&nbsp;&nbsp;financial-stmt
            </Menu.Item>
            <Menu.Item
              active={view === "weeklyclaims"}
              onClick={() => history.push("/contracts/weeklyclaims")}
            >
              #&nbsp;&nbsp;weekly-claims
            </Menu.Item>
            <Menu.Item
              active={view === "files"}
              onClick={() => history.push("/contracts/files")}
            >
              #&nbsp;&nbsp;data-files
            </Menu.Item>
            <Menu.Item
              active={view === "pcpperformance"}
              onClick={() => history.push("/contracts/pcpperformance")}
            >
              #&nbsp;&nbsp;pcp-performance
            </Menu.Item>
          </Menu>
        </div>
        <div className="header-panel box">
          <Header as="h4">
            Contract Statistics:&nbsp;
            {(!view || view === "overview") && "Overview"}
            {view === "weeklyclaims" && "Weeky Claim Reduction Files"}
            {view === "claimlags" &&
              "Claim Lags with IBNR (Incurred But Not Reported) Estimations"}
            {view === "financials" && "Financial Statement"}
            {view === "files" && "Processed Data Files"}
            {view === "pcpperformance" && "PCP Performance Estimates"}
            <Header.Subheader>
              Population and financial statistics
            </Header.Subheader>
          </Header>
        </div>
        {(!view || view === "overview") && <Overview />}
        {view === "claimlags" && <ClaimLags />}
        {view === "financials" && <Financials />}
        {view === "weeklyclaims" && <Wcrfs />}
        {view === "files" && <Files />}
        {view === "pcpperformance" && <PCPPerformance />}
      </div>
    </StitchProvidersProvider>
  );
}
