import React from "react";
import { app } from "./../stitch";
import PropTypes from "prop-types";
import {
  hasLoggedInUser,
  logoutCurrentUser,
  getCurrentUser,
  getCustomData,
  signupUser,
  loginUser,
  sendResetPasswordEmail,
  resetPassword,
  confirmEmail,
} from "./../stitch/authentication";

// Create a React Context that lets us expose and access auth state
// without passing props through many levels of the component tree
const StitchAuthContext = React.createContext();

// Create a React Hook that lets us get data from our auth context
export function useStitchAuth() {
  const context = React.useContext(StitchAuthContext);
  if (!context) {
    throw new Error(`useStitchAuth must be used within a StitchAuthProvider`);
  }
  return context;
}

// Create a component that controls auth state and exposes it via
// the React Context we created.
export function StitchAuthProvider(props) {
  const [authState, setAuthState] = React.useState({
    isLoggedIn: hasLoggedInUser(),
    accessLevel: "full", // full or limited for now
    currentUser: getCurrentUser(),
    userCustomData: null,
    otherUsers: [],
    db: {
      members: null,
      providers: null,
      contracts: null,
      wcrfs: null,
      users: null,
      groups: null,
      pafs: null,
      userstats: null,
      ibnrs: null,
      financials: null,
    },
  });

  // We useMemo to improve performance by eliminating some re-renders
  const authInfo = React.useMemo(() => {
    // Authentication Actions
    const handleLogout = async () => {
      const { isLoggedIn } = authState;
      if (isLoggedIn) {
        await logoutCurrentUser();
        setAuthState({
          ...authState,
          isLoggedIn: false,
          currentUser: null,
          userCustomData: null,
          otherUsers: [],
        });
      } else {
        console.log(`can't handleLogout when no user is logged in`);
      }
    };

    const handleSignup = async (email, password) => {
      await signupUser(email, password);
    };

    const handleConfirmEmail = async (token, tokenId) => {
      confirmEmail(token, tokenId);
    };

    const handleEmailPasswordLogin = async (email, password) => {
      const loggedInUser = await loginUser(email, password);

      setAuthState({
        ...authState,
        isLoggedIn: true,
        currentUser: loggedInUser,
        //accessLevel: "full",
        db: {
          members: loggedInUser
            .mongoClient("mongodb-atlas")
            .db("arq1")
            .collection("members"),
          providers: loggedInUser
            .mongoClient("mongodb-atlas")
            .db("arq1")
            .collection("providers"),
          contracts: loggedInUser
            .mongoClient("mongodb-atlas")
            .db("arq1")
            .collection("contracts"),
          wcrfs: loggedInUser
            .mongoClient("mongodb-atlas")
            .db("arq1")
            .collection("wcrfs"),
          users: loggedInUser
            .mongoClient("mongodb-atlas")
            .db("arq1")
            .collection("users"),
          groups: loggedInUser
            .mongoClient("mongodb-atlas")
            .db("arq1")
            .collection("groups"),
          pafs: loggedInUser
            .mongoClient("mongodb-atlas")
            .db("arq1")
            .collection("paf"),
          userstats: loggedInUser
            .mongoClient("mongodb-atlas")
            .db("arq1")
            .collection("userstats"),
          ibnrs: loggedInUser
            .mongoClient("mongodb-atlas")
            .db("arq1")
            .collection("ibnrs"),
          financials: loggedInUser
            .mongoClient("mongodb-atlas")
            .db("arq1")
            .collection("financials"),
        },
      });
    };

    const handleResetPasswordSend = async (email) => {
      sendResetPasswordEmail(email);
    };

    const handleResetPassword = async (token, tokenId, newPassword) => {
      resetPassword(token, tokenId, newPassword);
    };

    const handleRefreshCustomData = async () => {
      const customData = await getCustomData();
      const otherUsers = await getOtherUsers(customData.group_id);

      const userstat = {
        owner_id: customData.group_id,
        date: new Date().toISOString().substring(0, 10),
        email: customData.email,
        npi: customData.npi || "",
      };

      authState.currentUser
        .mongoClient("mongodb-atlas")
        .db("arq1")
        .collection("userstats")
        .updateOne(userstat, { $set: userstat }, { upsert: true });

      setAuthState({
        ...authState,
        userCustomData: customData,
        otherUsers: otherUsers,
        accessLevel: customData.accessLevel || "full",
        db: {
          members: authState.currentUser
            .mongoClient("mongodb-atlas")
            .db("arq1")
            .collection("members"),
          providers: authState.currentUser
            .mongoClient("mongodb-atlas")
            .db("arq1")
            .collection("providers"),
          contracts: authState.currentUser
            .mongoClient("mongodb-atlas")
            .db("arq1")
            .collection("contracts"),
          wcrfs: authState.currentUser
            .mongoClient("mongodb-atlas")
            .db("arq1")
            .collection("wcrfs"),
          users: authState.currentUser
            .mongoClient("mongodb-atlas")
            .db("arq1")
            .collection("users"),
          groups: authState.currentUser
            .mongoClient("mongodb-atlas")
            .db("arq1")
            .collection("groups"),
          pafs: authState.currentUser
            .mongoClient("mongodb-atlas")
            .db("arq1")
            .collection("pafs"),
          userstats: authState.currentUser
            .mongoClient("mongodb-atlas")
            .db("arq1")
            .collection("userstats"),
          ibnrs: authState.currentUser
            .mongoClient("mongodb-atlas")
            .db("arq1")
            .collection("ibnrs"),
          financials: authState.currentUser
            .mongoClient("mongodb-atlas")
            .db("arq1")
            .collection("financials"),
        },
      });
    };

    const updateCustomData = async (customData) => {
      const mongodb = app.currentUser.mongoClient("mongodb-atlas");
      const users = mongodb.db("arq1").collection("users");
      setAuthState({
        ...authState,
        userCustomData: {
          ...authState.userCustomData,
          ...customData,
        },
      });
      users.findOneAndUpdate(
        { id: authState.currentUser.id },
        { $set: customData }
      );
    };

    const getOtherUsers = async (groupID) => {
      return await authState.currentUser
        .mongoClient("mongodb-atlas")
        .db("arq1")
        .collection("users")
        .find({ group_id: groupID });
    };

    const handleUserSignup = async (
      email,
      password,
      name,
      namePublic,
      npi,
      userCustomData,
      myPassword
    ) => {
      try {
        await loginUser(userCustomData.email, myPassword);
        await signupUser(email, password);
        const loggedInUser = await loginUser(email, password);
        const customData = {
          name: name,
          namePublic: namePublic,
          timeZone: userCustomData.timeZone,
          role: "user",
          group_id: userCustomData.group_id,
          id: loggedInUser.id,
          email: email,
          npi: npi,
          accessLevel: "limited",
        };
        await authState.db.users.insertOne(customData);

        await loginUser(userCustomData.email, myPassword);
        return true;
      } catch {
        return false;
      }
    };

    const handleWorkspaceChange = async (groupIdNew) => {
      await authState.db.users.updateOne(
        { id: authState.currentUser.id },
        { $set: { group_id: groupIdNew } }
      );
      await handleRefreshCustomData();
    };

    const {
      isLoggedIn,
      accessLevel,
      currentUser,
      userCustomData,
      otherUsers,
      db,
    } = authState;
    const value = {
      isLoggedIn,
      accessLevel,
      currentUser,
      userCustomData,
      otherUsers,
      db,
      actions: {
        handleLogout,
        handleSignup,
        handleConfirmEmail,
        handleEmailPasswordLogin,
        handleResetPasswordSend,
        handleResetPassword,
        handleRefreshCustomData,
        updateCustomData,
        getOtherUsers,
        handleUserSignup,
        handleWorkspaceChange,
      },
    };
    return value;
  }, [authState]);
  return (
    <StitchAuthContext.Provider value={authInfo}>
      {props.children}
    </StitchAuthContext.Provider>
  );
}
StitchAuthProvider.propTypes = {
  children: PropTypes.element,
};
