import { useLocation } from "react-router-dom";

export function numberWithCommas(x, prefix = "") {
  if (x) {
    return prefix + x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  } else {
    return ".";
  }
}

export function truncate(str, n) {
  return str.length > n ? str.substr(0, n - 1) + "..." : str;
}

export function nFormatter(num, digits, prefix = "") {
  var si = [
    { value: 1, symbol: "" },
    { value: 1e3, symbol: "k" },
    { value: 1e6, symbol: "M" },
    { value: 1e9, symbol: "G" },
    { value: 1e12, symbol: "T" },
    { value: 1e15, symbol: "P" },
    { value: 1e18, symbol: "E" },
  ];
  var i;
  for (i = si.length - 1; i > 0; i--) {
    if (num >= si[i].value) {
      break;
    }
  }
  return prefix + (num / si[i].value).toFixed(digits) + si[i].symbol;
}

export function toTitleCase(x) {
  return x.replace(/\w*/g, function (txt) {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
  });
}

export function removeSpecialChars(x) {
  return x.replace(/[^\w\s]/gi, "");
}

export function validateEmail(email) {
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
}

export function useQuery() {
  const query = new URLSearchParams(useLocation().search);
  return {
    view: query.get("view"),
    npi: query.get("npi"),
    sort: query.get("sort"),
    page: query.get("page"),
    raf: query.get("raf"),
    serviceType: query.get("serviceType"),
    dateRangeStart: query.get("dateRangeStart"),
    dateRangeEnd: query.get("dateRangeEnd"),
  };
}

export function getEndOfMonth(ymRef) {
  const monthMap = {
    "01": 31,
    "02": 28,
    "03": 31,
    "04": 30,
    "05": 31,
    "06": 30,
    "07": 31,
    "08": 31,
    "09": 30,
    10: 31,
    11: 30,
    12: 31,
  };
  const ym = ymRef ? ymRef : new Date().toISOString().substring(0, 7);
  const yearMonth = ym.split("-");
  const year = parseInt(yearMonth[0]);
  const month = yearMonth[1];
  let endDate = monthMap[month];
  if (year % 4 === 0 && month === "02") {
    endDate++;
  }
  return ym + "-" + endDate;
}
