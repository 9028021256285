// React
import React from "react";

// Components & Hooks
//import MemberList from "./MemberList/MemberList";
import ProviderList from "./ProviderList/ProviderList";
import ProviderDetails from "./ProviderDetails/ProviderDetails";
import AWVs from "./AWVList/MemberList";
import MemberDetails from "./MemberDetails/MemberDetails";
import Settings from "./Settings/Settings";
import Contracts from "./Contracts/Contracts";
import UserInformation from "./Agreement/UserInformation";
import Login from "./Login";
import Signup from "./Signup";
import ConfirmEmail from "./ConfirmEmail";
import PasswordReset from "./PasswordReset";
import PasswordResetSend from "./PasswordResetSend";
import MemberList from "./MemberList/MemberList";
import { StitchAuthProvider, useStitchAuth } from "./StitchAuth";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import "semantic-ui-less/semantic.less";

window.YTConfig = {
  host: "https://www.youtube.com",
};

App.propTypes = {};
export default function App() {
  return (
    <StitchAuthProvider>
      <Router>
        <Switch>
          <Route path="/confirmemail">
            <ConfirmEmail />
          </Route>
          <Route path="/passwordreset">
            <PasswordReset />
          </Route>
          <Route path="/passwordresetsend">
            <PasswordResetSend />
          </Route>
          <Route path="/signup">
            <Signup />
          </Route>
          <Route path="/login">
            <Login />
          </Route>
          <PrivateRoute path="/userinformation">
            <UserInformation />
          </PrivateRoute>
          <PrivateRoute path="/settings/:viewDefault">
            <Settings />
          </PrivateRoute>
          <PrivateRoute path="/awvs/:memberId">
            <MemberDetails />
          </PrivateRoute>
          <PrivateRoute path="/awvs">
            <AWVs />
          </PrivateRoute>
          <PrivateRoute path="/members/:memberId">
            <MemberDetails />
          </PrivateRoute>
          <PrivateRoute path="/members">
            <MemberList />
          </PrivateRoute>
          <PrivateRoute path="/providers/:providerId">
            <ProviderDetails />
          </PrivateRoute>
          <PrivateRoute path="/providers">
            <ProviderList />
          </PrivateRoute>
          <PrivateRoute path="/contracts/:view">
            <Contracts />
          </PrivateRoute>
          <Route path="*">
            <Redirect to={{ pathname: "/login" }} />
          </Route>
        </Switch>
      </Router>
    </StitchAuthProvider>
  );
}

function PrivateRoute({ children, ...rest }) {
  const { isLoggedIn, accessLevel } = useStitchAuth();

  if (isLoggedIn) {
    return (
      <Route
        {...rest}
        render={({ location }) => {
          const loc = location.pathname.split("/")[1];
          if (accessLevel === "full") {
            return children;
          } else {
            if (loc === "awvs" || loc === "members" || loc === "settings") {
              return children;
            } else {
              return (
                <Redirect
                  to={{
                    pathname: "/awvs",
                    state: { from: location },
                  }}
                />
              );
            }
          }
        }}
      />
    );
  } else {
    return (
      <Route
        {...rest}
        render={({ location }) => (
          <Redirect
            to={{
              pathname: "/login/",
              state: { from: location },
            }}
          />
        )}
      />
    );
  }
}
