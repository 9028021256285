import React, { useState, useEffect } from "react";
import { Grid, Form, Header, Message, Dimmer, Loader } from "semantic-ui-react";
import { useStitchAuth } from "../StitchAuth";

export default function Accounts(props) {
  const { userCustomData, actions } = useStitchAuth();
  const [newAccount, setNewAccount] = useState({
    name: "",
    namePublic: "",
    email: "",
    password: "",
    npi: "",
    myPassword: "",
  });
  const [formError, setFormError] = useState(false);
  const [adminRole, setAdminRole] = useState(true);
  const [processing, setProcessing] = useState(false);

  useEffect(() => {
    if (userCustomData) {
      setAdminRole(userCustomData.role === "admin");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userCustomData]);

  const handleAddAccount = async () => {
    if (
      newAccount.name === "" ||
      newAccount.namePublic === "" ||
      newAccount.email === "" ||
      newAccount.password === "" ||
      newAccount.npi === "" ||
      newAccount.myPassword === ""
    ) {
      setFormError(true);
    } else {
      setProcessing(true);

      const res = await actions.handleUserSignup(
        newAccount.email,
        newAccount.password,
        newAccount.name,
        newAccount.namePublic,
        newAccount.npi,
        userCustomData,
        newAccount.myPassword
      );
      if (res) {
        setFormError(false);
        setNewAccount({
          name: "",
          namePublic: "",
          email: "",
          password: "",
          npi: "",
          myPassword: "",
        });
        setProcessing(false);
      } else {
        setFormError(true);
        setProcessing(false);
      }
    }
  };

  if (!userCustomData) {
    return (
      <div className="messages box">
        <Dimmer active inverted>
          <Loader inverted>Loading</Loader>
        </Dimmer>
      </div>
    );
  } else {
    return (
      <div className="messages box">
        {processing && (
          <Dimmer active inverted>
            <Loader inverted>Loading</Loader>
          </Dimmer>
        )}
        <Grid style={{ margin: 0 }}>
          <Grid.Row>
            <Grid.Column width={9} textAlign="left">
              <Header as="h4">
                Add a New User
                <Header.Subheader>
                  Please fill out the information below to add a new user
                  account.
                </Header.Subheader>
              </Header>
              <Form error={formError}>
                <Form.Input
                  fluid
                  name="name"
                  label="Name"
                  placeholder="{First Name} {Last Name}"
                  value={newAccount.name}
                  disabled={!adminRole}
                  onChange={(e) =>
                    setNewAccount({ ...newAccount, name: e.target.value })
                  }
                />
                <Form.Input
                  fluid
                  name="namePublic"
                  label="Name (patient-facing)"
                  placeholder="Dr. {Last Name}"
                  value={newAccount.namePublic}
                  disabled={!adminRole}
                  onChange={(e) =>
                    setNewAccount({ ...newAccount, namePublic: e.target.value })
                  }
                />
                <Form.Input
                  fluid
                  name="useremail"
                  label="User Email of the New User"
                  placeholder="email address"
                  value={newAccount.email}
                  disabled={!adminRole}
                  onChange={(e) =>
                    setNewAccount({ ...newAccount, email: e.target.value })
                  }
                />
                <Form.Input
                  fluid
                  name="userpassword"
                  label="User Password"
                  placeholder="password"
                  type="password"
                  value={newAccount.password}
                  disabled={!adminRole}
                  onChange={(e) =>
                    setNewAccount({ ...newAccount, password: e.target.value })
                  }
                />
                <Form.Input
                  fluid
                  name="npi"
                  label="NPI (National Provider Identifier)"
                  placeholder="1234567890"
                  value={newAccount.npi}
                  disabled={!adminRole}
                  onChange={(e) =>
                    setNewAccount({ ...newAccount, npi: e.target.value })
                  }
                />
                <Form.Input
                  fluid
                  type="password"
                  name="myPassword"
                  label='To verify yourself, plase type "YOUR" password'
                  placeholder="my.password"
                  value={newAccount.myPassword}
                  disabled={!adminRole}
                  onChange={(e) =>
                    setNewAccount({ ...newAccount, myPassword: e.target.value })
                  }
                />
                <Message
                  error
                  header="Missing or Wrong Information."
                  content="Please fill out all the fields OR check if your password is correct."
                />
                <Form.Button
                  primary
                  onClick={handleAddAccount}
                  disabled={!adminRole}
                >
                  Add a New Account
                </Form.Button>
              </Form>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </div>
    );
  }
}
