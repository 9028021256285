import React from "react";
import { Table, Header, Icon, Label } from "semantic-ui-react";
import ErrorBoundary from "react-error-boundary";
import { useHistory, Link } from "react-router-dom";
import { nFormatter, useQuery } from "../Common/utils";

export default function ContentTable(props) {
  const history = useHistory();
  let query = useQuery();

  const handleRowClick = (npi) => {
    history.push({
      pathname: `/providers/${npi}/profile`,
    });
  };

  const sortableColumns = [
    { name: "Name", key: "name" },
    { name: "# Members", key: "memberCnt" },
    { name: "AWV", key: "awv" },
    { name: `Risk (Y${new Date().getFullYear() - 2000})`, key: "riskScore" },
    { name: `Risk (Y${new Date().getFullYear() - 2001})`, key: "riskScorePY" },
    { name: `Cost (Y${new Date().getFullYear() - 2000})`, key: "spend" },
    { name: `Cost (Y${new Date().getFullYear() - 2001})`, key: "spendPY" },
    { name: "HCC Closed", key: "hccClosedCnt" },
    { name: "HCC Open", key: "hccOpenCnt" },
    { name: "Gaps-in-Care", key: "gicCnt" },
    { name: "NPV", key: "npv" },
  ];

  return (
    <ErrorBoundary>
      <Table selectable basic="very" size="small">
        <Table.Header>
          <Table.Row>
            {sortableColumns.map((col) => (
              <Table.HeaderCell
                key={col.key}
                onClick={() => {
                  if (query.sort === `${col.key}Desc`) {
                    history.push({
                      search:
                        `?view=${query.view || "all-providers"}` +
                        `&sort=${col.key}Asc`,
                    });
                  } else {
                    history.push({
                      search:
                        `?view=${query.view || "all-providers"}` +
                        `&sort=${col.key}Desc`,
                    });
                  }
                }}
              >
                {col.name}
                {![`${col.key}Asc`, `${col.key}Desc`].includes(query.sort) && (
                  <Icon name="sort" className="grey text" />
                )}
                {query.sort === `${col.key}Asc` && <Icon name="sort up" />}
                {query.sort === `${col.key}Desc` && <Icon name="sort down" />}
              </Table.HeaderCell>
            ))}
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {props.providerData.map((d, i) => (
            <Table.Row
              key={d._id}
              onClick={() => {
                if (d.member_cnt > 0) {
                  handleRowClick(d.npi);
                } else {
                  alert("The selected provider does not have any member...");
                }
              }}
            >
              <Table.Cell>
                <Header as="h5">
                  <Link to={`#`}>
                    {i + 1}. {d.firstName} {d.lastName}
                  </Link>
                  <Label size="mini" color={d._org_data.isPCP ? "blue" : "red"}>
                    {d._org_data.isPCP ? "PCP" : "nonPCP"}
                  </Label>
                  <Header.Subheader>
                    <small>
                      {d.credential}, {d._org_data.Specialty}, NPI: {d.npi}
                    </small>
                  </Header.Subheader>
                </Header>
              </Table.Cell>
              <Table.Cell textAlign="center">
                <Header as="h5">{d.member_cnt}</Header>
              </Table.Cell>
              <Table.Cell textAlign="center">
                <Header as="h5">{Math.round(d.awv * 1000) / 10}%</Header>
              </Table.Cell>
              <Table.Cell textAlign="center">
                <Header as="h5">
                  {Math.round(d.raf_my * 1000) / 1000}
                  <Header.Subheader>
                    <small>
                      {Math.round((d.raf_my / d.raf_py) * 1000) / 10}%
                    </small>
                  </Header.Subheader>
                </Header>
              </Table.Cell>
              <Table.Cell textAlign="center">
                <Header as="h5">{Math.round(d.raf_py * 1000) / 1000}</Header>
              </Table.Cell>
              <Table.Cell textAlign="center">
                <Header as="h5">
                  {nFormatter(d.total_cost_my, 0, "$")}
                  <Header.Subheader>
                    <small>
                      {Math.round((d.total_cost_my / d.total_cost_py) * 1000) /
                        10}
                      %
                    </small>
                  </Header.Subheader>
                </Header>
              </Table.Cell>
              <Table.Cell textAlign="center">
                <Header as="h5">{nFormatter(d.total_cost_py, 0, "$")}</Header>
              </Table.Cell>
              <Table.Cell textAlign="center">
                <Header as="h5">
                  {Math.round(d.hcc_closed_cnt * 100) / 100}
                </Header>
              </Table.Cell>
              <Table.Cell textAlign="center">
                <Header as="h5">
                  {Math.round(d.hcc_recapture_cnt * 100) / 100}
                </Header>
              </Table.Cell>
              <Table.Cell textAlign="center">
                <Header as="h5">
                  {Math.round(d.gic_open_cnt * 100) / 100}
                </Header>
              </Table.Cell>
              <Table.Cell textAlign="center">
                <Header as="h5">{Math.round(d.npv * 100) / 100}</Header>
              </Table.Cell>
            </Table.Row>
          ))}
        </Table.Body>
      </Table>
    </ErrorBoundary>
  );
}
