import React, { useState, createContext } from "react";
import PropTypes from "prop-types";
import { getYM } from "./insights";
import { useStitchAuth } from "./StitchAuth";

// Create a React Context that lets us expose and access auth state
// without passing props through many levels of the component tree
const StitchMembersContext = createContext();

// Create a React Hook that lets us get data from our auth context
export function useStitchMembers() {
  const context = React.useContext(StitchMembersContext);
  if (!context) {
    throw new Error(
      `useStitchMembers must be used within a StitchMembersProvider`
    );
  }
  return context;
}

export function StitchMembersProvider(props) {
  const {
    currentUser,
    userCustomData,
    db: { members },
  } = useStitchAuth();
  const [memberOne, setMemberOne] = useState();

  // We useMemo to improve performance by eliminating some re-renders
  const memberInfo = React.useMemo(() => {
    const reset = () => {
      setMemberOne(null);
    };

    const loadMember = async (memberId) => {
      let memberData = null;
      memberData = await members.findOne({
        mbi: memberId,
        owner_id: userCustomData.group_id,
      });
      if (memberData) {
        setMemberOne(memberData);
      } else {
        setMemberOne({ error: "no member found" });
      }
    };

    const loadMembers = async (groupID, ymRef = getYM()) => {
      const memberData = await currentUser.functions.findMembers(
        "all-members",
        "any",
        "any",
        "riskScoreAsc",
        1
      );
      return memberData;
    };

    const value = {
      memberOne,
      actions: {
        reset,
        loadMember,
        loadMembers,
      },
    };

    return value;
  }, [memberOne, members, currentUser, userCustomData.group_id]);
  return (
    <StitchMembersContext.Provider value={memberInfo}>
      {props.children}
    </StitchMembersContext.Provider>
  );
}
StitchMembersProvider.propTypes = {
  children: PropTypes.element,
};
