import React, { useEffect, useState } from "react";
import { Grid, Dimmer, Loader, Table, Button } from "semantic-ui-react";
import { useStitchAuth } from "../StitchAuth";
import { numberWithCommas } from "../Common/utils";

export default function ClaimLags(props) {
  const {
    userCustomData,
    db: { ibnrs },
  } = useStitchAuth();
  const [data, setData] = useState();
  const [loading, setLoading] = useState(true);
  const [category, setCategory] = useState("weighted");

  useEffect(() => {
    if (userCustomData) {
      ibnrs
        .findOne({ owner_id: userCustomData.group_id, category: category })
        .then((res) => {
          setData(res);
        });
      setLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userCustomData, ibnrs, category]);

  if (loading) {
    return (
      <div className="messages box">
        <Dimmer active inverted>
          <Loader inverted>Loading</Loader>
        </Dimmer>
      </div>
    );
  } else {
    return (
      <div className="messages box">
        {userCustomData && data && (
          <Grid style={{ margin: 0 }}>
            <Grid.Row columns={1}>
              <Grid.Column>
                <Button.Group>
                  <Button
                    primary={category === "weighted"}
                    onClick={() => setCategory("weighted")}
                  >
                    Model W
                  </Button>
                  <Button
                    primary={category === "local"}
                    onClick={() => setCategory("local")}
                  >
                    Model L
                  </Button>
                  <Button
                    primary={category === "global"}
                    onClick={() => setCategory("global")}
                  >
                    Model G
                  </Button>
                </Button.Group>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row columns={1}>
              <Grid.Column>
                <p>
                  You can compare three different models for calculating the
                  IBNR estimates (colored cells in the table). Each model uses a
                  different parameter set with varying assumptions.{" "}
                  <b>Model G (Global)</b> uses parameters estiamted from all
                  ACOs in our database, while <b>Model L (Local)</b> applies
                  parameters from only the target ACO. <b>Model W (Weighted)</b>{" "}
                  is a Bayesian mix of Model G and Model L. The building blocks
                  of these models follow a standard{" "}
                  <a href="https://en.wikipedia.org/wiki/Chain-ladder_method">
                    Chain-Ladder method
                  </a>
                  , a prominent actuarial loss reserving technique.
                </p>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row columns={1}>
              <Grid.Column>
                <p>Estimated Parameters:</p>
                <p>
                  Month-over-Month Member Drop Rate:{" "}
                  <b>{Math.round((1 - data.param.rate_mbr) * 10000) / 100}%</b>,
                  Cost Ratio between Month 1 and Month 2:{" "}
                  <b>{Math.round(data.param.rate_mo1 * 10000) / 100}%</b>, Cost
                  Ratio Month 2-3:{" "}
                  <b>{Math.round(data.param.rate_mo2 * 10000) / 100}%</b>, Cost
                  Ratio Month 3-4:{" "}
                  <b>{Math.round(data.param.rate_mo3 * 10000) / 100}%</b>, Cost
                  Ratio Month 4-5:{" "}
                  <b>{Math.round(data.param.rate_mo4 * 10000) / 100}%</b>
                </p>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row columns={1}>
              <Grid.Column>
                <Table size="small" textAlign="right" collapsing>
                  <Table.Header>
                    <Table.Row>
                      <Table.Cell>Paid \ Incurred</Table.Cell>
                      {data.data[0].map((x) => (
                        <Table.Cell key={x.col}>{x.col}</Table.Cell>
                      ))}
                    </Table.Row>
                  </Table.Header>
                  <Table.Body>
                    <Table.Row>
                      <Table.Cell>(Num Members)</Table.Cell>
                      {data.data[0].map((x) => (
                        <Table.Cell
                          key={x.col}
                          warning={x.category !== "observed"}
                        >
                          {numberWithCommas(Math.round(x.value))}
                        </Table.Cell>
                      ))}
                    </Table.Row>
                    {data.data.slice(1).map((row) => (
                      <Table.Row key={row[0].row}>
                        <Table.Cell>{row[0].row}</Table.Cell>
                        {row.map((x) => (
                          <Table.Cell
                            key={x.col}
                            warning={
                              x.category !== "observed" && x.value !== ""
                            }
                          >
                            {x.value !== "" && (
                              <span
                                style={{
                                  fontWeight:
                                    x.row === "Total" ||
                                    x.col === "Subtotal" ||
                                    x.row === "Subtotal"
                                      ? 600
                                      : 400,
                                }}
                              >
                                ${numberWithCommas(Math.round(x.value))}
                              </span>
                            )}
                          </Table.Cell>
                        ))}
                      </Table.Row>
                    ))}
                  </Table.Body>
                </Table>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        )}
      </div>
    );
  }
}
