import React, { useState, useEffect } from "react";
import { Menu, Header, Dimmer, Loader, Grid, Divider } from "semantic-ui-react";
import { StitchMembersProvider } from "../StitchMembers";
import AppBar from "../Common/AppBar";
//import ContractWarning from "../Common/ContractWarning";
import Workspace from "../Common/Workspace";
import "../Common/Layout.css";
import ContentTable from "./ContentTable";
import { useStitchAuth } from "../StitchAuth";
import { useQuery } from "../Common/utils";
import { useHistory } from "react-router-dom";

export default function ProviderList() {
  let query = useQuery();
  const history = useHistory();

  const {
    userCustomData,
    db: { providers },
  } = useStitchAuth();
  const [loading, setLoading] = useState(true);
  const [providerData, setProviderData] = useState([]);
  const [rawData, setRawData] = useState([]);
  const viewDesc = {
    "all-providers": "All Providers",
    "zero-awv": "Providers with No AWV",
    "awv-below-50": "Providers with Less than 50% AWV Completed",
    "awv-over-50": "Providers with More than 50% AWV Completed",
    "cost-increased-yoy": "Providers with Year-over-Year Cost Increases",
    "cost-decreased-yoy": "Providers with Year-over-Year Cost Decreases",
    "risk-increased-yoy": "Providers with Year-over-Year RAF Score Increases",
    "risk-decreased-yoy": "Providers with Year-over-Year RAF Score Decreases",
    "pcp-only":
      "Providers who are PCPs (Family Medicine, Internal Medicine, etc.)",
    "non-pcps":
      "Providers who are non-PCPs, such as Nerphologists and Oncologists",
  };

  useEffect(() => {
    if (providers) {
      setLoading(true);
      providers
        .find(
          { owner_id: userCustomData.group_id },
          { projection: { members: 0 } }
        )
        .then((res) => {
          setRawData(res);
          setLoading(false);
        });
    }
  }, [providers, userCustomData]);

  useEffect(() => {
    const handleFilterSort = (viewKey, sortKey) => {
      let tmp = rawData.slice();

      if (viewKey === "all-providers") {
      } else if (viewKey === "zero-awv") {
        tmp = tmp.filter((x) => x.awv === 0);
      } else if (viewKey === "awv-below-50") {
        tmp = tmp.filter((x) => x.awv < 0.5);
      } else if (viewKey === "awv-over-50") {
        tmp = tmp.filter((x) => x.awv >= 0.5);
      } else if (viewKey === "cost-increased-yoy") {
        tmp = tmp.filter((x) => x.total_cost_my / x.total_cost_py > 1.0);
      } else if (viewKey === "cost-decreased-yoy") {
        tmp = tmp.filter((x) => x.total_cost_my / x.total_cost_py <= 1.0);
      } else if (viewKey === "risk-increased-yoy") {
        tmp = tmp.filter((x) => x.raf_my / x.raf_py > 1.0);
      } else if (viewKey === "risk-decreased-yoy") {
        tmp = tmp.filter((x) => x.raf_my / x.raf_py <= 1.0);
      } else if (viewKey === "pcp-only") {
        tmp = tmp.filter((x) => x._org_data.isPCP);
      } else if (viewKey === "non-pcps") {
        tmp = tmp.filter((x) => !x._org_data.isPCP);
      }

      if (sortKey === "memberCntDesc") {
        tmp.sort((a, b) => b.member_cnt - a.member_cnt);
      } else if (sortKey === "memberCntAsc") {
        tmp.sort((a, b) => a.member_cnt - b.member_cnt);
      } else if (sortKey === "awvDesc") {
        tmp.sort((a, b) => b.awv - a.awv);
      } else if (sortKey === "awvAsc") {
        tmp.sort((a, b) => a.awv - b.awv);
      } else if (sortKey === "riskScoreDesc") {
        tmp.sort((a, b) => b.raf_my - a.raf_my);
      } else if (sortKey === "riskScoreAsc") {
        tmp.sort((a, b) => a.raf_my - b.raf_my);
      } else if (sortKey === "riskScorePYDesc") {
        tmp.sort((a, b) => b.raf_py - a.raf_py);
      } else if (sortKey === "riskScorePYAsc") {
        tmp.sort((a, b) => a.raf_py - b.raf_py);
      } else if (sortKey === "spendDesc") {
        tmp.sort((a, b) => b.total_cost_my - a.total_cost_my);
      } else if (sortKey === "spendAsc") {
        tmp.sort((a, b) => a.total_cost_my - b.total_cost_my);
      } else if (sortKey === "spendPYDesc") {
        tmp.sort((a, b) => b.total_cost_py - a.total_cost_py);
      } else if (sortKey === "spendPYAsc") {
        tmp.sort((a, b) => a.total_cost_py - b.total_cost_py);
      } else if (sortKey === "hccClosedCntDesc") {
        tmp.sort((a, b) => b.hcc_closed_cnt - a.hcc_closed_cnt);
      } else if (sortKey === "hccClosedCntAsc") {
        tmp.sort((a, b) => a.hcc_closed_cnt - b.hcc_closed_cnt);
      } else if (sortKey === "hccOpenCntDesc") {
        tmp.sort((a, b) => b.hcc_recapture_cnt - a.hcc_recapture_cnt);
      } else if (sortKey === "hccOpenCntAsc") {
        tmp.sort((a, b) => a.hcc_recapture_cnt - b.hcc_recapture_cnt);
      } else if (sortKey === "gicCntDesc") {
        tmp.sort((a, b) => b.gic_open_cnt - a.gic_open_cnt);
      } else if (sortKey === "gicCntAsc") {
        tmp.sort((a, b) => a.gic_open_cnt - b.gic_open_cnt);
      } else if (sortKey === "npvDesc") {
        tmp.sort((a, b) => b.npv - a.npv);
      } else if (sortKey === "npvAsc") {
        tmp.sort((a, b) => a.npv - b.npv);
      }

      setProviderData(tmp);
    };

    if (!query.view) {
      history.push({ search: "?view=all-providers&sort=memberCntDesc" });
    } else {
      handleFilterSort(query.view, query.sort);
    }
  }, [query.view, query.sort, rawData, history]);

  const handleLFChange = (newView) => {
    // view, npi, sort, page
    history.push({
      pathname: "/providers",
      search: `?view=${newView}&sort=${query.sort || "memberCntDesc"}`,
    });
  };

  if (loading) {
    return (
      <div className="app-layout">
        <AppBar />
        <Workspace />
        <Dimmer active inverted>
          <Loader inverted>Loading</Loader>
        </Dimmer>
      </div>
    );
  } else {
    return (
      <div className="app-layout">
        <AppBar />
        <Workspace />
        <div className="channels box">
          <Header as="h4" style={{ paddingLeft: "1em" }}>
            View Options
          </Header>
          <Menu vertical secondary style={{ background: "transparent" }}>
            <Menu.Item
              active={query.view === "all-providers"}
              onClick={() => handleLFChange("all-providers")}
            >
              #&nbsp;&nbsp;all-providers
            </Menu.Item>
            <Divider />
            <Menu.Item
              active={query.view === "zero-awv"}
              onClick={() => handleLFChange("zero-awv")}
            >
              #&nbsp;&nbsp;zero-awv
            </Menu.Item>
            <Menu.Item
              active={query.view === "awv-below-50"}
              onClick={() => handleLFChange("awv-below-50")}
            >
              #&nbsp;&nbsp;awv-below-50
            </Menu.Item>
            <Menu.Item
              active={query.view === "awv-over-50"}
              onClick={() => handleLFChange("awv-over-50")}
            >
              #&nbsp;&nbsp;awv-over-50
            </Menu.Item>
            <Divider />
            <Menu.Item
              active={query.view === "cost-increased-yoy"}
              onClick={() => handleLFChange("cost-increased-yoy")}
            >
              #&nbsp;&nbsp;cost-increased-yoy
            </Menu.Item>
            <Menu.Item
              active={query.view === "cost-decreased-yoy"}
              onClick={() => handleLFChange("cost-decreased-yoy")}
            >
              #&nbsp;&nbsp;cost-decreased-yoy
            </Menu.Item>
            <Menu.Item
              active={query.view === "risk-increased-yoy"}
              onClick={() => handleLFChange("risk-increased-yoy")}
            >
              #&nbsp;&nbsp;risk-increased-yoy
            </Menu.Item>
            <Menu.Item
              active={query.view === "risk-decreased-yoy"}
              onClick={() => handleLFChange("risk-decreased-yoy")}
            >
              #&nbsp;&nbsp;risk-decreased-yoy
            </Menu.Item>
            <Divider />
            <Menu.Item
              active={query.view === "pcp-only"}
              onClick={() => handleLFChange("pcp-only")}
            >
              #&nbsp;&nbsp;pcp-only
            </Menu.Item>
            <Menu.Item
              active={query.view === "non-pcps"}
              onClick={() => handleLFChange("non-pcps")}
            >
              #&nbsp;&nbsp;non-pcps
            </Menu.Item>
          </Menu>
        </div>
        <div className="header-panel box">
          <Grid>
            <Grid.Row columns={2}>
              <Grid.Column>
                <Header as="h4">
                  Provider-level Aggregated Data:{" "}
                  {viewDesc[query.view] || "All Providers"}
                  <Header.Subheader>
                    All numbers are aggregated across the PCP's members.
                    <br />
                    <small>last updated at {new Date().toLocaleString()}</small>
                  </Header.Subheader>
                </Header>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </div>
        <div
          className="messages box"
          style={{ padding: "1em", paddingBottom: "5em" }}
        >
          <StitchMembersProvider>
            <Grid>
              <Grid.Row columns={1}>
                <Grid.Column>
                  <ContentTable providerData={providerData} />
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </StitchMembersProvider>
        </div>
      </div>
    );
  }
}
