import React, { useState, useEffect } from "react";
import {
  Menu,
  Header,
  Breadcrumb,
  Dimmer,
  Loader,
  Grid,
  Popup,
} from "semantic-ui-react";
import { useStitchAuth } from "../StitchAuth";
import {
  StitchProvidersProvider,
  useStitchProviders,
} from "../StitchProviders";
import AppBar from "../Common/AppBar";
import Workspace from "../Common/Workspace";
import "../Common/Layout.css";
import {
  useParams,
  useHistory,
  useLocation,
  useRouteMatch,
  Link,
  Switch,
  Route,
} from "react-router-dom";
import Profile from "./Profile";
import HccDetails from "./HccDetails";
import GicDetails from "./GicDetails";
import FacDetails from "./FacDetails";
import PafReports from "./PafReports";
import { useQuery } from "../Common/utils";

function HeaderSection(props) {
  const { providerOne } = useStitchProviders();

  return (
    <div className="header-panel box">
      <Grid>
        {providerOne && (
          <Grid.Row columns={1}>
            <Grid.Column>
              <Header as="h4">
                <span style={{ color: "#869AB8" }}>
                  {providerOne.firstName} {providerOne.lastName}
                  {providerOne.credential !== "" &&
                    ", " + providerOne.credential}{" "}
                  >
                </span>{" "}
                {props.title}
                <Header.Subheader>
                  <small>
                    {providerOne.npi}, {providerOne._org_data.Specialty}, last
                    updated at {new Date().toLocaleString()}
                  </small>
                </Header.Subheader>
              </Header>
            </Grid.Column>
          </Grid.Row>
        )}
      </Grid>
    </div>
  );
}

export default function ProviderDetails() {
  const { userCustomData } = useStitchAuth();
  const { providerId } = useParams();
  const history = useHistory();
  const [loading, setLoading] = useState(true);
  let query = useQuery();
  let { path, url } = useRouteMatch();
  const location = useLocation();

  const viewList = [
    {
      pathname: "profile",
      disabled: false,
      divider: false,
    },
    {
      pathname: "hcc-details",
      disabled: false,
      divider: false,
    },
    {
      pathname: "gic-details",
      disabled: false,
      divider: false,
    },
    {
      pathname: "fac-details",
      disabled: false,
      divider: false,
    },
    {
      pathname: "paf-reports",
      disabled: false,
      divider: false,
    },
  ];

  useEffect(() => {
    if (userCustomData && loading) {
      setLoading(false);
    }
  }, [userCustomData, loading]);

  if (loading) {
    return (
      <div className="app-layout">
        <AppBar />
        <Workspace />
        <Dimmer active inverted>
          <Loader inverted>Loading</Loader>
        </Dimmer>
      </div>
    );
  } else {
    return (
      <StitchProvidersProvider>
        <div className="app-layout">
          <AppBar />
          <Workspace />
          <div className="channels box">
            <Breadcrumb style={{ paddingLeft: "1em", fontSize: "0.8em" }}>
              <Breadcrumb.Section
                link
                style={{ color: "#3c4257" }}
                onClick={() =>
                  history.push({
                    pathname: "/providers",
                    search: `?sort=${query.sort || "scireDesc"}`,
                  })
                }
              >
                providers
              </Breadcrumb.Section>
              <Breadcrumb.Divider
                icon="right angle"
                style={{ color: "#3c4257" }}
              />
              <Breadcrumb.Section style={{ color: "#3c4257" }}>
                provider-details
              </Breadcrumb.Section>
            </Breadcrumb>
            <Header as="h4" style={{ paddingLeft: "1em" }}>
              Provider Details
            </Header>
            <Menu vertical secondary style={{ background: "transparent" }}>
              {viewList.map((view) => (
                <Menu.Item
                  key={view.pathname}
                  disabled={view.disabled}
                  active={
                    location.pathname ===
                    `/providers/${providerId}/${view.pathname}`
                  }
                >
                  {view.disabled ? (
                    <Popup
                      trigger={<span>{`#  ${view.pathname}`}</span>}
                      position="bottom left"
                      content={
                        <div style={{ width: "120px" }}>{view.disabledMsg}</div>
                      }
                    />
                  ) : (
                    <Link
                      to={`${url}/${view.pathname}`}
                      style={{ color: "#3c4257" }}
                    >
                      {`#  ${view.pathname}`}
                    </Link>
                  )}
                </Menu.Item>
              ))}
            </Menu>
          </div>
          <Switch>
            <Route path={`${path}/profile`}>
              <HeaderSection providerId={providerId} title="Profile" />
              <Profile providerId={providerId} />
            </Route>
            <Route path={`${path}/hcc-details`}>
              <HeaderSection providerId={providerId} title="HCC Details" />
              <HccDetails providerId={providerId} />
            </Route>
            <Route path={`${path}/gic-details`}>
              <HeaderSection
                providerId={providerId}
                title="Gaps-in-Care Details"
              />
              <GicDetails providerId={providerId} />
            </Route>
            <Route path={`${path}/fac-details`}>
              <HeaderSection providerId={providerId} title="Facility Details" />
              <FacDetails providerId={providerId} />
            </Route>
            <Route path={`${path}/paf-reports`}>
              <HeaderSection
                providerId={providerId}
                title="Patient Assessment Form (PDF)"
              />
              <PafReports providerId={providerId} />
            </Route>
          </Switch>
        </div>
      </StitchProvidersProvider>
    );
  }
}
