import React, { useState, createContext } from "react";
import PropTypes from "prop-types";
import { useStitchAuth } from "./StitchAuth";

// Create a React Context that lets us expose and access auth state
// without passing props through many levels of the component tree
const StitchProvidersContext = createContext();

// Create a React Hook that lets us get data from our auth context
export function useStitchProviders() {
  const context = React.useContext(StitchProvidersContext);
  if (!context) {
    throw new Error(
      `useStitchProviders must be used within a StitchMembersProvider`
    );
  }
  return context;
}

export function StitchProvidersProvider(props) {
  const {
    db: { providers, contracts },
  } = useStitchAuth();
  const [providerOne, setProviderOne] = useState();
  const [contractOne, setContractOne] = useState();

  // We useMemo to improve performance by eliminating some re-renders
  const providerInfo = React.useMemo(() => {
    const reset = () => {
      setProviderOne(null);
    };

    const loadProvider = async (group_id, providerId) => {
      let providerData = await providers.findOne({
        owner_id: group_id,
        npi: providerId,
      });
      if (providerData) {
        setProviderOne(providerData);
      } else {
        setProviderOne({ error: "no member found" });
      }
    };

    const loadContract = async (group_id) => {
      let contractData = await contracts.findOne({ owner_id: group_id });
      if (contractData) {
        setContractOne(contractData);
      } else {
        setContractOne({ error: "no member found" });
      }
    };

    const value = {
      providerOne,
      contractOne,
      actions: {
        reset,
        loadProvider,
        loadContract,
      },
    };
    return value;
  }, [providerOne, contractOne, providers, contracts]);

  return (
    <StitchProvidersContext.Provider value={providerInfo}>
      {props.children}
    </StitchProvidersContext.Provider>
  );
}

StitchProvidersProvider.propTypes = {
  children: PropTypes.element,
};
