import React, { useState, useEffect } from "react";
import { Menu, Header } from "semantic-ui-react";
import "../Common/Layout.css";
import FormUser from "./FormUser";
import Accounts from "./Accounts";
import AddUser from "./AddUser";
import AppBar from "../Common/AppBar";
import Workspace from "../Common/Workspace";
import "../Common/Layout.css";
import { useHistory, useParams } from "react-router-dom";

export default function Settings() {
  const { viewDefault } = useParams();
  const [view, setView] = useState(viewDefault || "user");
  const history = useHistory();

  useEffect(() => {
    if (viewDefault) {
      setView(viewDefault);
    }
  }, [viewDefault]);

  const handleViewChange = (newView) => {
    setView(newView);
    history.push("/settings/" + newView);
  };

  return (
    <div>
      <div className="app-layout">
        <AppBar />
        <Workspace />
        <div className="channels box">
          <Header as="h4" style={{ paddingLeft: "1em" }}>
            Settings
          </Header>
          <Menu vertical secondary style={{ background: "transparent" }}>
            <Menu.Item
              active={view === "my-setting"}
              onClick={() => handleViewChange("my-setting")}
            >
              #&nbsp;&nbsp;my-settings
            </Menu.Item>
            <Menu.Item
              active={view === "user-stats"}
              onClick={() => handleViewChange("user-stats")}
            >
              #&nbsp;&nbsp;users-stats
            </Menu.Item>
            <Menu.Item
              active={view === "add-user"}
              onClick={() => handleViewChange("add-user")}
            >
              #&nbsp;&nbsp;add-user
            </Menu.Item>
          </Menu>
        </div>
        <div className="header-panel box">
          {view === "my-setting" && (
            <Header as="h4">
              Settings {">"} My Settings
              <Header.Subheader>Change your account settings</Header.Subheader>
            </Header>
          )}
          {view === "user-stats" && (
            <Header as="h4">
              Settings {">"} Other Users in this Account
              <Header.Subheader>
                See other users in this account.
              </Header.Subheader>
            </Header>
          )}
          {view === "add-user" && (
            <Header as="h4">
              Settings {">"} Add a New User
              <Header.Subheader>
                Add a new user to this account.
              </Header.Subheader>
            </Header>
          )}
        </div>
        <div className="messages">
          {view === "my-setting" && <FormUser />}
          {view === "user-stats" && <Accounts />}
          {view === "add-user" && <AddUser />}
        </div>
      </div>
    </div>
  );
}
