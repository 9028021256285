import React from "react";
import { Table, Header, Label, Popup, Icon } from "semantic-ui-react";
import ErrorBoundary from "react-error-boundary";
import Moment from "react-moment";
import "moment-timezone";
//import moment from 'moment-timezone';
import { useHistory, Link } from "react-router-dom";
import { useStitchAuth } from "../StitchAuth";
import { nFormatter } from "../Common/utils";
import { useQuery } from "../Common/utils";

export default function ContentTable(props) {
  const { userCustomData } = useStitchAuth();
  const history = useHistory();
  let query = useQuery();

  const sortableColumns = [
    { name: "Name", key: "name" },
    { name: `Risk (Y${new Date().getFullYear() - 2000})`, key: "riskScore" },
    { name: `Risk (Y${new Date().getFullYear() - 2001})`, key: "riskScorePY" },
    { name: `Cost (Y${new Date().getFullYear() - 2000})`, key: "spend" },
    { name: `Cost (Y${new Date().getFullYear() - 2001})`, key: "spendPY" },
    { name: "HCC Closed", key: "hccClosedCnt" },
    { name: "HCC Open", key: "hccOpenCnt" },
    { name: "Gaps-in-Care", key: "gicCnt" },
    { name: "NPV", key: "npv" },
  ];

  const handleRowClick = (mbi) => {
    history.push({
      pathname: `/members/${mbi}/profile`,
      search:
        `?view=${props.view || "all-members"}` +
        `&npi=${props.npi || userCustomData.npi || "any"}` +
        `&raf=${query.raf || "any"}` +
        `&sort=${props.sort || "riskScoreDesc"}` +
        `&page=${props.page || 1}`,
    });
  };

  return (
    <ErrorBoundary>
      <Table selectable basic="very" size="small">
        <Table.Header>
          <Table.Row>
            {sortableColumns.map((col) => (
              <Table.HeaderCell
                key={col.key}
                onClick={() => {
                  if (query.sort === `${col.key}Desc`) {
                    history.push({
                      search:
                        `?view=${query.view || "all-members"}` +
                        `&npi=${query.npi || "any"}` +
                        `&sort=${col.key}Asc` +
                        `&page=${query.page || 1}`,
                    });
                  } else {
                    history.push({
                      search:
                        `?view=${query.view || "all-members"}` +
                        `&npi=${query.npi || "any"}` +
                        `&sort=${col.key}Desc` +
                        `&page=${query.page || 1}`,
                    });
                  }
                }}
              >
                {col.name}
                {![`${col.key}Asc`, `${col.key}Desc`].includes(query.sort) && (
                  <Icon name="sort" className="grey text" />
                )}
                {query.sort === `${col.key}Asc` && <Icon name="sort up" />}
                {query.sort === `${col.key}Desc` && <Icon name="sort down" />}
              </Table.HeaderCell>
            ))}
            <Table.HeaderCell textAlign="center">AWV Due</Table.HeaderCell>
            <Table.HeaderCell textAlign="center">ADT</Table.HeaderCell>
            <Table.HeaderCell textAlign="center">PCP</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {props.memberData.map((d) => (
            <Table.Row key={d._id} onClick={() => handleRowClick(d.mbi)}>
              <Table.Cell>
                <Header as="h5">
                  <Link to={`#`}>{d.name}</Link>
                  <Header.Subheader>
                    <small>
                      {d.gender},&nbsp;
                      {Math.floor(
                        (new Date().getTime() -
                          new Date(d.dob.slice(0, 10)).getTime()) /
                          31557600000
                      )}{" "}
                      y/o,&nbsp;
                      {d.mbi}
                    </small>
                  </Header.Subheader>
                </Header>
              </Table.Cell>
              <Table.Cell textAlign="center">
                <Header as="h5">
                  {Math.round(d.raf_my * 1000) / 1000}
                  <Header.Subheader>
                    <small>
                      {Math.round((d.raf_my / d.raf_py) * 1000) / 10}%
                    </small>
                  </Header.Subheader>
                </Header>
              </Table.Cell>
              <Table.Cell textAlign="center">
                <Header as="h5">{Math.round(d.raf_py * 1000) / 1000}</Header>
              </Table.Cell>
              <Table.Cell textAlign="center">
                <Header as="h5">
                  {nFormatter(d.total_cost_my, 0, "$")}
                  <Header.Subheader>
                    <small>
                      {Math.round((d.total_cost_my / d.total_cost_py) * 1000) /
                        10}
                      %
                    </small>
                  </Header.Subheader>
                </Header>
              </Table.Cell>
              <Table.Cell textAlign="center">
                <Header as="h5">{nFormatter(d.total_cost_py, 0, "$")}</Header>
              </Table.Cell>
              <Table.Cell textAlign="center">
                {d.hcc_closed.length}&nbsp;
                <Popup
                  content={
                    <span>
                      {d.hcc_closed.map((x) => (
                        <Label size="mini" basic key={x}>
                          {x}
                        </Label>
                      ))}
                    </span>
                  }
                  trigger={<Icon color="green" name="info circle" />}
                ></Popup>
              </Table.Cell>
              <Table.Cell textAlign="center">
                {d.hcc_recapture.length}&nbsp;
                <Popup
                  content={
                    <span>
                      {d.hcc_recapture.map((x) => (
                        <Label size="mini" color="red" basic key={x}>
                          {x}
                        </Label>
                      ))}
                    </span>
                  }
                  trigger={<Icon color="red" name="info circle" />}
                ></Popup>
              </Table.Cell>
              <Table.Cell textAlign="center">
                {d.gic_open.length}&nbsp;
                <Popup
                  content={
                    <span>
                      {d.gic_open.map((x) => (
                        <Label size="mini" color="red" basic key={x.name}>
                          {x.name}
                        </Label>
                      ))}
                    </span>
                  }
                  trigger={<Icon color="red" name="info circle" />}
                ></Popup>
              </Table.Cell>
              <Table.Cell textAlign="center">
                <Header as="h5">{d.npv}</Header>
              </Table.Cell>
              <Table.Cell textAlign="center">
                <Header as="h5">{d.awv_is_due}</Header>
              </Table.Cell>
              <Table.Cell textAlign="center">
                {d.inpatientTs > new Date().getTime() - 86400000 * 3 && (
                  <Label basic size="mini" color="red">
                    IP:&nbsp;
                    <Moment
                      format="M/D/YY"
                      tz={userCustomData.timeZone}
                      date={d.inpatientTs}
                    />
                  </Label>
                )}
                {d.emergencyTs > new Date().getTime() - 86400000 * 3 && (
                  <Label basic size="mini" color="purple">
                    ED:&nbsp;
                    <Moment
                      format="M/D/YY"
                      tz={userCustomData.timeZone}
                      date={d.emergencyTs}
                    />
                  </Label>
                )}
                {d.dischargeTs > new Date().getTime() - 86400000 * 3 && (
                  <Label basic size="mini" color="green">
                    DS:&nbsp;
                    <Moment
                      format="M/D/YY"
                      tz={userCustomData.timeZone}
                      date={d.dischargeTs}
                    />
                  </Label>
                )}
              </Table.Cell>
              <Table.Cell textAlign="center">
                <small>{d.pcp_name}</small>
              </Table.Cell>
            </Table.Row>
          ))}
        </Table.Body>
      </Table>
    </ErrorBoundary>
  );
}
