import React, { useEffect, useState } from "react";
import {
  Grid,
  Dimmer,
  Loader,
  Label,
  Header,
  Popup,
  Divider,
  Button,
} from "semantic-ui-react";
import { useStitchAuth } from "../StitchAuth";
import { useStitchMembers } from "../StitchMembers";
import "moment-timezone";
import { Link, useHistory } from "react-router-dom";
import { numberWithCommas } from "../Common/utils";

export default function Profile(props) {
  const { userCustomData, accessLevel } = useStitchAuth();
  const { memberOne, actions } = useStitchMembers();
  const history = useHistory();
  const [loading, setLoading] = useState(true);
  const [awvCheck, setAWVCheck] = useState(false);

  useEffect(() => {
    if ((memberOne || {}).mbi !== props.memberId) {
      actions.loadMember(props.memberId);
    }

    if (
      ((memberOne || {}).mbi === props.memberId &&
        accessLevel === "limited" &&
        userCustomData.npi !== (memberOne || {}).pcp_npi) ||
      (memberOne || {}).error === "no member found"
    ) {
      history.push({
        pathname: "/members",
        search: `?&npi=${userCustomData.npi}`,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.memberId, memberOne]);

  useEffect(() => {
    if (memberOne && memberOne.error !== "no member found") {
      fetch(
        "https://data.mongodb-api.com/app/awv-simple-checkin-hcopc/endpoint/check_awv_completed",
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ query: props.memberId }),
        }
      ).then((res) => {
        res.json().then((x) => {
          if (x !== null) {
            setAWVCheck(true);
          }
        });
      });
      setLoading(false);
    }
  }, [memberOne, props.memberId]);

  const handleAWVCheck = async () => {
    await fetch(
      "https://data.mongodb-api.com/app/awv-simple-checkin-hcopc/endpoint/complete_awv",
      {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ query: props.memberId }),
      }
    );
    setAWVCheck(true);
  };

  if (loading) {
    return (
      <div className="messages box">
        <Dimmer active inverted>
          <Loader inverted>Loading</Loader>
        </Dimmer>
      </div>
    );
  } else {
    return (
      <div className="messages box">
        {memberOne && userCustomData && (
          <Grid style={{ margin: 0 }}>
            <Grid.Row columns="four">
              <Grid.Column>
                <Header as="h2">
                  {memberOne.name}
                  <Header.Subheader>Member Name</Header.Subheader>
                </Header>
              </Grid.Column>
              <Grid.Column>
                <Header as="h4">
                  {memberOne.address.line1}
                  <br />
                  {memberOne.address.city}, {memberOne.address.state}{" "}
                  {memberOne.address.zipCode}
                  <Header.Subheader>Member Address</Header.Subheader>
                </Header>
              </Grid.Column>
              <Grid.Column>
                <Header as="h4">
                  <Link to={`/providers/${memberOne.pcp_npi}/profile`}>
                    {memberOne.pcp_name}
                    <br />
                    (NPI: {memberOne.pcp_npi})
                  </Link>
                  <Header.Subheader>PCP</Header.Subheader>
                </Header>
              </Grid.Column>
              <Grid.Column>
                {memberOne.awv === "N" && (
                  <Button
                    primary
                    onClick={() => handleAWVCheck()}
                    disabled={awvCheck}
                  >
                    {!awvCheck && "Click if AWV completed"}
                    {awvCheck && "Thanks for completing AWV"}
                  </Button>
                )}
                {memberOne.awv === "Y" && (
                  <Button disabled={true}>AWV Completed</Button>
                )}
                <br />
                <small className="text grey">
                  The final AWV Status will be updated/reconciled with the CCLF
                  and Weekly Claim Reduction Files from CMS.
                </small>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row columns="four">
              <Grid.Column>
                <Header as="h2">
                  {Math.round(memberOne.raf_my * 1000) / 1000}
                  <Header.Subheader>
                    RAF Estimate {`(Y${new Date().getFullYear() - 2000})`}
                  </Header.Subheader>
                </Header>
              </Grid.Column>
              <Grid.Column>
                <Header as="h2" className="teal">
                  {Math.round(memberOne.raf_py * 1000) / 1000}
                  <Header.Subheader>
                    RAF Estimate {`(Y${new Date().getFullYear() - 2001})`}
                  </Header.Subheader>
                </Header>
              </Grid.Column>
              <Grid.Column>
                <Header as="h2">
                  <Popup
                    content={
                      "Ideally, this should be over 103% by the end of the year."
                    }
                    trigger={
                      <span
                        className={
                          memberOne.raf_my / memberOne.raf_py > 1.03
                            ? "text green"
                            : "text red"
                        }
                      >
                        {Math.round(
                          (memberOne.raf_my / memberOne.raf_py) * 1000
                        ) / 10}
                        %
                      </span>
                    }
                  ></Popup>
                  <Header.Subheader>RAF Completion Ratio</Header.Subheader>
                </Header>
              </Grid.Column>
              <Grid.Column>
                <Header as="h2">
                  <Popup
                    content={
                      "Ideally, this should be below 7% by the end of the year."
                    }
                    trigger={
                      <span
                        className={
                          memberOne.acr_num / memberOne.acr_denom < 0.07
                            ? "text green"
                            : "text red"
                        }
                      >
                        {Math.round(
                          (memberOne.acr_num / memberOne.acr_denom) * 1000
                        ) / 10}
                        %
                      </span>
                    }
                  ></Popup>

                  <Header.Subheader>
                    All-cause Readmission Rate
                  </Header.Subheader>
                </Header>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row columns="four">
              <Grid.Column>
                <Header as="h2">
                  $
                  {numberWithCommas(
                    Math.round(memberOne.total_cost_my * 100) / 100
                  )}
                  <Header.Subheader>
                    Cost PMPY {`(Y${new Date().getFullYear() - 2000})`}
                  </Header.Subheader>
                </Header>
              </Grid.Column>
              <Grid.Column>
                <Header as="h2" className="teal">
                  $
                  {numberWithCommas(
                    Math.round(memberOne.total_cost_py * 100) / 100
                  )}
                  <Header.Subheader>
                    Cost PMPY {`(Y${new Date().getFullYear() - 2001})`}
                  </Header.Subheader>
                </Header>
              </Grid.Column>
              <Grid.Column>
                <Header as="h2">
                  <Popup
                    content={
                      "Ideally, this should be less than 100% by the end of the year."
                    }
                    trigger={
                      <span className="text red">
                        {Math.round(
                          (memberOne.total_cost_my / memberOne.total_cost_py) *
                            1000
                        ) / 10}
                        %
                      </span>
                    }
                  ></Popup>
                  <Header.Subheader>Cost Build-up Ratio</Header.Subheader>
                </Header>
              </Grid.Column>
              <Grid.Column>
                <Header as="h2">
                  <Popup
                    content={
                      "Unplanned Admission with Multiple Chronic Conditions."
                    }
                    trigger={<span>{Math.round(memberOne.uamcc)}</span>}
                  ></Popup>
                  <Header.Subheader>UAMCC Counts</Header.Subheader>
                </Header>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row columns="four">
              <Grid.Column>
                <Header as="h2">
                  <Popup
                    content={
                      "Ideally, this should be over 85% by the end of the year."
                    }
                    trigger={
                      <span
                        className={
                          memberOne.awv_is_due === "Y"
                            ? "text green"
                            : "text red"
                        }
                      >
                        {memberOne.awv_is_due === "Y"
                          ? `AWV Due: ${memberOne.awv_due_date}`
                          : `Not Due Yet`}
                      </span>
                    }
                  ></Popup>
                  <Header.Subheader>Is AWV Due</Header.Subheader>
                </Header>
              </Grid.Column>
              <Grid.Column>
                <Header as="h2">
                  {Math.round(memberOne.npv * 1000) / 1000}
                  <Header.Subheader>PCP Visit Counts</Header.Subheader>
                </Header>
              </Grid.Column>
              <Grid.Column>
                <Header as="h2">
                  {Math.round(memberOne.gic_open_cnt * 1000) / 1000}
                  <Header.Subheader>Open Gaps-in-Care</Header.Subheader>
                </Header>
              </Grid.Column>
              <Grid.Column>
                <Header as="h2">
                  {Math.round(memberOne.gic_closed_cnt * 1000) / 1000}
                  <Header.Subheader>Closed Gaps-in-Care</Header.Subheader>
                </Header>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row columns="four">
              <Grid.Column>
                <Header as="h2">
                  {Math.round(memberOne.hcc_closed_cnt * 1000) / 1000}
                  <Header.Subheader>HCC Captured</Header.Subheader>
                </Header>
              </Grid.Column>
              <Grid.Column>
                <Header as="h2">
                  {Math.round(memberOne.hcc_recapture_cnt * 1000) / 1000}
                  <Header.Subheader>HCC Recap. Opportunity</Header.Subheader>
                </Header>
              </Grid.Column>
              <Grid.Column>
                <Header as="h2">
                  {Math.round(memberOne.hcc_suspect_cnt * 1000) / 1000}
                  <Header.Subheader>HCC Susp. Opportunity</Header.Subheader>
                </Header>
              </Grid.Column>
              <Grid.Column>
                <Header as="h2">
                  {Math.round(memberOne.days_at_care * 1000) / 1000}
                  <Header.Subheader>Days at Care</Header.Subheader>
                </Header>
              </Grid.Column>
            </Grid.Row>
            <Divider />
            <Grid.Row columns="three">
              <Grid.Column>
                <Header as="h4">
                  <Link to={`/members/${memberOne.mbi}/hcc-insights`}>
                    HCC Closed
                  </Link>
                </Header>
                <br />
                {memberOne.hcc_closed.map((x) => (
                  <Label key={x} basic color="green">
                    {x}
                  </Label>
                ))}
              </Grid.Column>
              <Grid.Column>
                <Header as="h4">
                  <Link to={`/members/${memberOne.mbi}/hcc-insights`}>
                    HCC Recapture Opportunities
                  </Link>
                </Header>
                <br />
                {memberOne.hcc_recapture.map((x) => (
                  <Label key={x} basic color="red">
                    {x}
                  </Label>
                ))}
              </Grid.Column>
              <Grid.Column>
                <Header as="h4">
                  <Link to={`/members/${memberOne.mbi}/hcc-insights`}>
                    HCC Suspected
                  </Link>
                </Header>
                <br />
                {memberOne.hcc_suspect.map((x) => (
                  <Label key={x} basic color="purple">
                    {x}
                  </Label>
                ))}
              </Grid.Column>
            </Grid.Row>
          </Grid>
        )}
      </div>
    );
  }
}
