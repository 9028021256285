import React, { useEffect, useState } from "react";
import { Grid, Dimmer, Loader, Table, List, Header } from "semantic-ui-react";
import { useStitchMembers } from "../StitchMembers";
import { useStitchAuth } from "../StitchAuth";
import { useHistory } from "react-router-dom";

export default function QualityInsights(props) {
  const { memberOne, actions } = useStitchMembers();
  const [loading, setLoading] = useState(true);
  const { userCustomData, accessLevel } = useStitchAuth();
  const history = useHistory();

  useEffect(() => {
    if (memberOne && memberOne.error !== "no member found") {
      setLoading(false);
    }
  }, [memberOne]);

  useEffect(() => {
    actions.loadMember(props.memberId);
    if (
      ((memberOne || {}).mbi === props.memberId &&
        accessLevel === "limited" &&
        userCustomData.npi !== (memberOne || {}).pcp_npi) ||
      (memberOne || {}).error === "no member found"
    ) {
      history.push({
        pathname: "/members",
        search: `?&npi=${userCustomData.npi}`,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.memberId, memberOne]);

  if (loading) {
    return (
      <div className="messages box">
        <Dimmer active inverted>
          <Loader inverted>Loading</Loader>
        </Dimmer>
      </div>
    );
  } else {
    return (
      <div className="messages box" style={{ margin: 0 }}>
        <Grid>
          <Grid.Row columns={2}>
            <Grid.Column>
              <Header>Care Gap Details</Header>
              <Table basic="very" size="small" textAlign="center">
                <Table.Header>
                  <Table.Row>
                    <Table.HeaderCell textAlign="left">
                      Care Gap (Abbr)
                    </Table.HeaderCell>
                    <Table.HeaderCell textAlign="left">
                      Numerator
                    </Table.HeaderCell>
                    <Table.HeaderCell textAlign="left">
                      Numerator Evidence
                    </Table.HeaderCell>
                    <Table.HeaderCell textAlign="left">
                      Suggestions
                    </Table.HeaderCell>
                  </Table.Row>
                </Table.Header>
                <Table.Body>
                  {Object.values(memberOne.gic)
                    .filter((v) => v.denom)
                    .map((x) => {
                      return (
                        <Table.Row key={x.name}>
                          <Table.Cell textAlign="left">
                            {x.name} ({x.key})
                          </Table.Cell>
                          <Table.Cell textAlign="left">
                            {["NPV", "ENM"].includes(x.key) && (
                              <span>{x.num}</span>
                            )}
                            {[
                              "TFCC",
                              "DAH",
                              "ACR",
                              "UAMCC",
                              "EDV",
                              "AIA",
                            ].includes(x.key) && (
                              <span>{x.num_evidence.count}</span>
                            )}
                            {[
                              "AWV",
                              "ASC",
                              "DSF",
                              "FFR",
                              "TBC1",
                              "TBC2",
                              "TBC3",
                            ].includes(x.key) && (
                              <span
                                className={x.num ? "text green" : "text red"}
                              >
                                {x.num ? "Completed" : "Open"}
                              </span>
                            )}
                          </Table.Cell>
                          <Table.Cell textAlign="left">
                            {["NPV", "ENM"].includes(x.key) && (
                              <span>DoS: {x.num_evidence}</span>
                            )}
                            {[
                              "TFCC",
                              "DAH",
                              "ACR",
                              "UAMCC",
                              "EDV",
                              "AIA",
                            ].includes(x.key) && (
                              <span>
                                Cnt: {x.num_evidence.count}, DoS:{" "}
                                {x.num_evidence.dates.join(", ")}
                              </span>
                            )}
                            {[
                              "AWV",
                              "ASC",
                              "DSF",
                              "FFR",
                              "TBC1",
                              "TBC2",
                              "TBC3",
                            ].includes(x.key) && <span></span>}
                          </Table.Cell>
                          <Table.Cell textAlign="left">{x.action}</Table.Cell>
                        </Table.Row>
                      );
                    })}
                </Table.Body>
              </Table>
            </Grid.Column>
            <Grid.Column>
              <Header>
                AWV Cheat Sheet
                <Header.Subheader>
                  Please use this AWV (G0438/G0439) cheat sheet to improve your
                  AWV coding. Read the CPT descriptions below and see if you can
                  include these codes in the AWV claim.
                </Header.Subheader>
              </Header>
              <List bulleted>
                <List.Item>
                  <b>99497</b>: Advance care planning including the explanation
                  and discussion of advance directives such as standard forms
                  (with completion of such forms, when performed), by the
                  physician or other qualified health care professional; first
                  30 minutes, face-to-face with the patient, family member(s),
                  and/or surrogate. See <b>99458</b> for an additional 30
                  minutes service.
                </List.Item>
                <List.Item>
                  <b>G8482</b>: Influenza immunization administered or
                  previously received.
                </List.Item>
                <List.Item>
                  <b>G8752</b>: Systolic BP less than 140 mmHg.
                </List.Item>
                <List.Item>
                  <b>G8754</b>: Diastolic BP less 90 mmHg.
                </List.Item>
                <List.Item>
                  <b>G8431</b>: Screening for depression is documented as being
                  positive and a follow-up plan is documented.
                </List.Item>
                <List.Item>
                  <b>G8510</b>: Screening for depression is documented as
                  negative, a follow-up plan is not required.
                </List.Item>
                <List.Item>
                  <b>G9509</b>: Adult patients 18 years of age or older with
                  major depression or dysthymia who reached remission at twelve
                  months as demonstrated by a twelve-month (+/-60days) PHQ-9 or
                  PHQ-9M score of less than 5.
                </List.Item>
                <List.Item>
                  <b>G9664</b>: Patients who are currently statin therapy users
                  or received an order (prescription) for statin therapy.
                </List.Item>
                <List.Item>
                  <b>1100F</b>: Patient screened for future fall risk;
                  documentation of two or more falls in the past year or any
                  fall with injury in the past year.
                </List.Item>
                <List.Item>
                  <b>1101F</b>: Falls plan of care documented.
                </List.Item>
                <List.Item>
                  <b>3014F</b>: Screening mammography results documented and
                  reviewed.
                </List.Item>
                <List.Item>
                  <b>3017F</b>: Colorectal cancer screening results documented
                  and reviewed.
                </List.Item>
                <List.Item>
                  <b>3044F</b>: Most recent HbA1c level below 7%.
                </List.Item>
                <List.Item>
                  <b>3045F</b>: Most recent HbA1c level between 7% and 9%.
                </List.Item>
                <List.Item>
                  <b>3046F</b>: Most recent HbA1c level above 9%.
                </List.Item>
                <List.Item>
                  <b>4004F</b>: Patient screened for tobacco use AND received
                  tobacco cessation intervention (counseling, pharmacotherapy,
                  or both), if identified as a tobacco user. If currently a
                  non-tobacco user, report <b>1036F</b>.
                </List.Item>
              </List>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </div>
    );
  }
}
