import React, { useState, useEffect } from "react";
import {
  Menu,
  Header,
  Divider,
  Dimmer,
  Loader,
  Grid,
  Dropdown,
  Icon,
} from "semantic-ui-react";
import { StitchMembersProvider } from "../StitchMembers";
import AppBar from "../Common/AppBar";
//import ContractWarning from "../Common/ContractWarning";
import Workspace from "../Common/Workspace";
import "../Common/Layout.css";
import ContentTable from "./ContentTable";
import { useHistory } from "react-router-dom";
import { useStitchAuth } from "../StitchAuth";
import { useQuery } from "../Common/utils";
import { saveSync } from "save-file";
import { numberWithCommas } from "../Common/utils.js";

export default function MemberList() {
  let query = useQuery();
  const history = useHistory();
  const {
    currentUser,
    userCustomData,
    accessLevel,
    actions: { handleRefreshCustomData },
    db: { providers },
  } = useStitchAuth();
  const [loading, setLoading] = useState(true);
  const [provOptions, setProvOptions] = useState([]);
  const [memberData, setMemberData] = useState({ data: [] });

  const filterList = [
    { name: "all-members", query: {}, desc: "All Members", divider: true },
    {
      name: "awv-needed",
      query: { awv: "N" },
      desc: "Members who need Annual Wellness Visit",
    },
    {
      name: "npv-needed",
      query: { npv: 0 },
      desc: "Members who need PCP Visit",
      divider: true,
    },
    {
      name: "dm-no-cx-all",
      query: { hcc_all: "HCC19 Diabetes without Complication" },
      desc: "Members with Diabetes without Complications (both captured/open)",
    },
    {
      name: "dm-no-cx-open",
      query: { hcc_recapture: "HCC19 Diabetes without Complication" },
      desc: "Members with Diabetes without Complications (open)",
    },
    {
      name: "dm-w-cx-all",
      query: { hcc_all: "HCC18 Diabetes with Chronic Complications" },
      desc: "Members with Diabetes with Complications (both captured/open)",
    },
    {
      name: "dm-w-cx-open",
      query: { hcc_recapture: "HCC18 Diabetes with Chronic Complications" },
      desc: "Members with Diabetes with Complications (open)",
      divider: true,
    },
    {
      name: "ckd-3-all",
      query: { hcc_all: "HCC138 Chronic Kidney Disease, Moderate (Stage 3)" },
      desc: "Members with Chronic Kidney Disease Stage 3 (both captured/open)",
    },
    {
      name: "ckd-3-open",
      query: {
        hcc_recapture: "HCC138 Chronic Kidney Disease, Moderate (Stage 3)",
      },
      desc: "Members with Chronic Kidney Disease Stage 3 (open)",
    },
    {
      name: "ckd-4-all",
      query: { hcc_all: "HCC137 Chronic Kidney Disease, Severe (Stage 4)" },
      desc: "Members with Chronic Kidney Disease Stage 4 (both captured/open)",
    },
    {
      name: "ckd-4-open",
      query: {
        hcc_recapture: "HCC137 Chronic Kidney Disease, Severe (Stage 4)",
      },
      desc: "Members with Chronic Kidney Disease Stage 4 (open)",
    },
    {
      name: "ckd-5-all",
      query: { hcc_all: "HCC136 Chronic Kidney Disease, Stage 5" },
      desc: "Members with Chronic Kidney Disease Stage 5 (both captured/open)",
    },
    {
      name: "ckd-5-open",
      query: {
        hcc_recapture: "HCC136 Chronic Kidney Disease, Stage 5",
      },
      desc: "Members with Chronic Kidney Disease Stage 5 (open)",
    },
    {
      name: "dialysis-all",
      query: { hcc_all: "HCC134 Dialysis Status" },
      desc: "Members with End-Stage Renal Disease (both captured/open)",
    },
    {
      name: "dialysis-open",
      query: { hcc_recapture: "HCC134 Dialysis Status" },
      desc: "Members with End-Stage Renal Disease (open)",
      divider: true,
    },
    {
      name: "recent-inpatient",
      query: { inpatientTs: { $gt: new Date().getTime() - 86400000 * 3 } },
      desc: "Members with Recent Inpatient Events (last 72 hours, ADT feed)",
    },
    {
      name: "recent-emergency",
      query: { emergencyTs: { $gt: new Date().getTime() - 86400000 * 3 } },
      desc: "Members with Recent ER Events (last 72 hours, ADT feed)",
    },
    {
      name: "recent-discharge",
      query: { dischargeTs: { $gt: new Date().getTime() - 86400000 * 3 } },
      desc: "Members with Recent Discharge Events (last 72 hours, ADT feed)",
    },
  ];

  const sortParams = [
    {
      key: "riskScoreAsc",
      value: "riskScoreAsc",
      text: "RISK SCORE (Current, Low -> High)",
    },
    {
      key: "riskScoreDesc",
      value: "riskScoreDesc",
      text: "RISK SCORE (Current, High -> Low)",
    },
    {
      key: "riskScorePYAsc",
      value: "riskScorePYAsc",
      text: "RISK SCORE (Previous, Low -> High)",
    },
    {
      key: "riskScorePYDesc",
      value: "riskScorePYDesc",
      text: "RISK SCORE (Previous, High -> Low)",
    },
    {
      key: "spendAsc",
      value: "spendAsc",
      text: "MEDICAL SPEND (Current, Low -> High)",
    },
    {
      key: "spendDesc",
      value: "spendDesc",
      text: "MEDICAL SPEND (Current, High -> Low)",
    },
    {
      key: "spendPYAsc",
      value: "spendPYAsc",
      text: "MEDICAL SPEND (Previous, Low -> High)",
    },
    {
      key: "spendPYDesc",
      value: "spendPYDesc",
      text: "MEDICAL SPEND (Previous, High -> Low)",
    },
    { key: "nameAsc", value: "nameAsc", text: "NAME (A -> Z)" },
    { key: "nameDesc", value: "nameDesc", text: "NAME (Z -> A)" },
    {
      key: "hccClosedCntAsc",
      value: "hccClosedCntAsc",
      text: "CNT OF HCC CLOSED (Low -> High)",
    },
    {
      key: "hccClosedCntDesc",
      value: "hccClosedCntDesc",
      text: "CNT OF HCC CLOSED (High -> Low)",
    },
    {
      key: "hccOpenCntAsc",
      value: "hccOpenCntAsc",
      text: "CNT OF HCC OPEN (Low -> High)",
    },
    {
      key: "hccOpenCntDesc",
      value: "hccOpenCntDesc",
      text: "CNT OF HCC OPEN (High -> Low)",
    },
    {
      key: "gicCntAsc",
      value: "gicCntAsc",
      text: "CNT OF CARE GAPS (Low -> High)",
    },
    {
      key: "gicCntDesc",
      value: "gicCntDesc",
      text: "CNT OF CARE GAPS(High -> Low)",
    },
    {
      key: "npvAsc",
      value: "npvAsc",
      text: "NUMBER OF PCP VISITS (Low -> High)",
    },
    {
      key: "npvDesc",
      value: "npvDesc",
      text: "NUMBER OF PCP VISITS (High -> Low)",
    },
  ];

  const handleLFChange = (newView) => {
    // view, npi, sort, page
    history.push({
      pathname: "/members",
      search:
        `?view=${newView}` +
        `&npi=${query.npi || "any"}` +
        `&sort=${query.sort || "riskScoreDesc"}` +
        `&page=${query.page || 1}`,
    });
  };

  const exportMembers = async () => {
    setLoading(true);
    const res = await currentUser.functions.exportMembers(
      currentUser.id,
      query.npi
    );
    const csvFile = JSON.parse(res)
      .map((x) => x.join(","))
      .join("\n");
    await saveSync(csvFile, `memberList-v${query.view}-v${query.npi}.csv`);
    setLoading(false);
  };

  useEffect(() => {
    if (!userCustomData) {
      handleRefreshCustomData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userCustomData]);

  useEffect(() => {
    if (providers && userCustomData) {
      setLoading(false);
      providers
        .find(
          { owner_id: userCustomData.group_id },
          {
            projection: {
              npi: 1,
              _org_data: 1,
              firstName: 1,
              lastName: 1,
              nMembers: 1,
              member_cnt: 1,
            },
          }
        )
        .then((res) => {
          let tinMap = {};
          let tmp = res.map((x) => {
            if (x._org_data.tin in tinMap) {
              tinMap[x._org_data.tin].nMbr += x.nMembers || x.member_cnt || 0;
              tinMap[x._org_data.tin].npiLst += "::" + x.npi;
            } else {
              tinMap[x._org_data.tin] = {
                nMbr: x.nMembers || x.member_cnt || 0,
                npiLst: x.npi,
                key: x._org_data.tin,
              };
            }
            return {
              key: x.npi,
              value: x.npi,
              text: `${x.firstName} ${x.lastName} (nMbr: ${
                x.nMembers || x.member_cnt || 0
              }, npi: ${x.npi}, tin: ${x._org_data.tin})`,
            };
          });

          let tinLst = Object.values(tinMap).map((x) => {
            return {
              key: x.key,
              value: "TIN" + x.key,
              text: `TIN: ${x.key} (nMbr: ${x.nMbr})`,
            };
          });
          tmp.sort((a, b) => a.text.localeCompare(b.text));
          setProvOptions([
            { key: "any", value: "any", text: "Any Providers" },
            ...tmp,
            ...tinLst,
          ]);
        });
    }
  }, [providers, userCustomData]);

  useEffect(() => {
    if (currentUser && userCustomData) {
      setLoading(true);

      if (!query.view || !query.npi || !query.sort || !query.page) {
        // view, npi, sort, page
        history.push({
          pathname: "/members",
          search:
            `?view=${query.view || "all-members"}` +
            `&npi=${query.npi || userCustomData.npi || "any"}` +
            `&sort=${query.sort || "riskScoreDesc"}` +
            `&page=${query.page || 1}`,
        });
      } else {
        currentUser.functions
          .fetchMembers(
            currentUser.id,
            query.view,
            query.npi,
            query.sort,
            query.page
          )
          .then((res) => {
            setMemberData(JSON.parse(res));
            setLoading(false);
          });
      }
    }
  }, [
    history,
    currentUser,
    query.view,
    query.npi,
    query.sort,
    query.page,
    userCustomData,
  ]);

  if (loading) {
    return (
      <div className="app-layout">
        <AppBar />
        <Workspace />
        <Dimmer active inverted>
          <Loader inverted>Loading</Loader>
        </Dimmer>
      </div>
    );
  } else {
    return (
      <div className="app-layout">
        <AppBar />
        <Workspace />
        <div className="channels box">
          <Header as="h4" style={{ paddingLeft: "1em" }}>
            View Options
          </Header>
          <Menu vertical secondary style={{ background: "transparent" }}>
            {filterList.map((option) => (
              <div key={option.name}>
                <Menu.Item
                  active={query.view === option.name}
                  onClick={() => handleLFChange(option.name)}
                >
                  #&nbsp;&nbsp;{option.name}
                </Menu.Item>
                {option.divider && <Divider />}
              </div>
            ))}
          </Menu>
        </div>
        <div className="header-panel box">
          <Grid>
            <Grid.Row columns={1}>
              <Grid.Column>
                <Header as="h4">
                  {filterList.find((x) => x.name === query.view).desc}&nbsp;
                  <small>
                    <Icon
                      name="download"
                      color="grey"
                      onClick={() => exportMembers()}
                    />
                  </small>
                  <Header.Subheader>
                    <small>last updated at {new Date().toLocaleString()}</small>
                    <br />
                  </Header.Subheader>
                </Header>
              </Grid.Column>
              <Grid.Column textAlign="left">
                Select members of&nbsp;
                <Dropdown
                  placeholder="any PCP"
                  inline
                  scrolling
                  direction="right"
                  value={query.npi}
                  options={provOptions}
                  disabled={accessLevel === "limited"}
                  onChange={(e, { value }) => {
                    history.push({
                      pathname: "/members",
                      search:
                        `?view=${query.view || "all-members"}` +
                        `&npi=${value}` +
                        `&sort=${query.sort || "riskScoreDesc"}` +
                        `&page=1`,
                    });
                  }}
                />
                &nbsp;and Sort by&nbsp;
                <Dropdown
                  placeholder="Any"
                  inline
                  direction="left"
                  scrolling
                  value={query.sort}
                  options={sortParams}
                  onChange={(e, { value }) => {
                    history.push({
                      pathname: "/members",
                      search:
                        `?view=${query.view || "all-members"}` +
                        `&npi=${query.npi || "any"}` +
                        `&sort=${value}` +
                        `&page=1`,
                    });
                  }}
                />
                &nbsp;&nbsp; Showing {memberData.startIdx + 1} -{" "}
                {memberData.endIdx} of {numberWithCommas(memberData.numTotal)}
                &nbsp;&nbsp;&nbsp;
                <Icon
                  name="angle left"
                  onClick={() => {
                    history.push({
                      pathname: "/members",
                      search:
                        `?view=${query.view || "all-members"}` +
                        `&npi=${query.npi || "any"}` +
                        `&sort=${query.sort || "riskScoreDesc"}` +
                        `&page=${Math.max(parseInt(query.page) - 1 || 1, 1)}`,
                    });
                  }}
                />
                &nbsp;&nbsp;&nbsp;
                <Icon
                  name="angle right"
                  onClick={() => {
                    history.push({
                      pathname: "/members",
                      search:
                        `?view=${query.view || "all-members"}` +
                        `&npi=${query.npi || "any"}` +
                        `&sort=${query.sort || "riskScoreDesc"}` +
                        `&page=${Math.min(
                          memberData.pageMax,
                          parseInt(query.page) + 1
                        )}`,
                    });
                  }}
                />
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </div>
        <div
          className="messages box"
          style={{ padding: "1em", paddingBottom: "5em" }}
        >
          <StitchMembersProvider>
            <Grid>
              <Grid.Row columns={1}>
                <Grid.Column>
                  <ContentTable
                    memberData={memberData.data}
                    npi={query.npi}
                    view={query.view}
                    sort={query.sort}
                    page={query.page}
                  />
                </Grid.Column>
              </Grid.Row>
              <Grid.Row columns={1}>
                <Grid.Column textAlign="right">
                  <small>
                    Showing {memberData.startIdx + 1} - {memberData.endIdx} of{" "}
                    {numberWithCommas(memberData.numTotal)}&nbsp;&nbsp;&nbsp;
                    <Icon
                      name="angle left"
                      onClick={() => {
                        history.push({
                          pathname: "/members",
                          search:
                            `?view=${query.view || "all-members"}` +
                            `&npi=${query.npi || "any"}` +
                            `&sort=${query.sort || "riskScoreDesc"}` +
                            `&page=${Math.max(
                              parseInt(query.page) - 1 || 1,
                              1
                            )}`,
                        });
                      }}
                    />
                    &nbsp;&nbsp;&nbsp;
                    <Icon
                      name="angle right"
                      onClick={() => {
                        history.push({
                          pathname: "/members",
                          search:
                            `?view=${query.view || "all-members"}` +
                            `&npi=${query.npi || "any"}` +
                            `&sort=${query.sort || "riskScoreDesc"}` +
                            `&page=${Math.min(
                              memberData.pageMax,
                              parseInt(query.page) + 1
                            )}`,
                        });
                      }}
                    />
                  </small>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </StitchMembersProvider>
        </div>
      </div>
    );
  }
}
