import React, { useState } from "react";
import {
  Button,
  Form,
  Grid,
  Header,
  Image,
  Segment,
  Message,
} from "semantic-ui-react";
import { useStitchAuth } from "./StitchAuth";
import { useHistory } from "react-router-dom";

const SignupForm = (props) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [formError, setFormError] = useState(false);

  const { actions } = useStitchAuth();

  let history = useHistory();

  const handleSubmit = (event) => {
    actions
      .handleSignup(email, password)
      .then(() => {
        actions
          .handleEmailPasswordLogin(email, password)
          .then(() => {
            history.replace({ pathname: "/home" });
          })
          .catch(() => {
            setFormError(true);
          });
      })
      .catch(() => {
        setFormError(true);
      });
  };

  return (
    <Grid textAlign="center" style={{ height: "100vh" }} verticalAlign="middle">
      <Grid.Column style={{ maxWidth: 500 }}>
        <Header as="h2" textAlign="center" icon style={{ lineHeight: "1.5em" }}>
          <Image
            src={"/logos/logo_square.png"}
            style={{ height: "5em", width: "auto" }}
          />
          <Header.Content style={{ fontWeight: 300 }}>
            Sign-up
            <Header.Subheader
              style={{ lineHeight: "1.5em", paddingTop: "0.5em" }}
            >
              Welcome to the ARQ Platform! <br />
            </Header.Subheader>
          </Header.Content>
        </Header>
        <Form size="large" error={formError}>
          <Segment style={{ border: "0", boxShadow: "0 0 0 0" }}>
            <Message error>
              <Message.Content>
                <Message.Header>Sign-up Error</Message.Header>
                If you already have an account, please log-in{" "}
                <a href="/login">here</a>.
              </Message.Content>
            </Message>
            <Form.Input
              fluid
              icon="user"
              iconPosition="left"
              placeholder="E-mail address"
              value={email}
              onChange={(event) =>
                setEmail(event.target.value.toLowerCase().replace(/\s/g, ""))
              }
            />
            <Form.Input
              fluid
              icon="lock"
              iconPosition="left"
              placeholder="New password"
              type="password"
              value={password}
              onChange={(event) =>
                setPassword(event.target.value.replace(/\s/g, ""))
              }
            />
            <Button
              style={{ backgroundColor: "#335eea", color: "#fff" }}
              primary
              fluid
              size="large"
              onClick={handleSubmit}
            >
              Sign Up
            </Button>
            <p style={{ marginTop: "10px", color: "#869AB8", fontWeight: 300 }}>
              Already signed up? Log-in <a href={"/login"}>here</a>.
            </p>
          </Segment>
        </Form>
      </Grid.Column>
    </Grid>
  );
};

export default SignupForm;
