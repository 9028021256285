import React, { useEffect, useState } from "react";
import { Grid, Table, Dimmer, Loader, Header } from "semantic-ui-react";
import { useStitchMembers } from "../StitchMembers";
import { Link, useHistory } from "react-router-dom";
import { numberWithCommas, useQuery } from "../Common/utils";
import serviceOptions from "../data/service_options.json";
import { ResponsiveBar } from "@nivo/bar";
import { useStitchAuth } from "../StitchAuth";

export default function CostBreakdown(props) {
  const { userCustomData, accessLevel } = useStitchAuth();
  const { memberOne, actions } = useStitchMembers();
  const [loading, setLoading] = useState(true);
  const [dateRanges, setDateRanges] = useState([]);
  const [costData, setCostData] = useState({});
  const [serviceOptions2, setServiceOptions2] = useState([]);
  const [nivoData, setNivoData] = useState([]);
  let query = useQuery();
  let history = useHistory();

  useEffect(() => {
    if ((memberOne || {}).mbi !== props.memberId) {
      actions.loadMember(props.memberId);
    }
    if (
      ((memberOne || {}).mbi === props.memberId &&
        accessLevel === "limited" &&
        userCustomData.npi !== (memberOne || {}).pcp_npi) ||
      (memberOne || {}).error === "no member found"
    ) {
      history.push({
        pathname: "/members",
        search: `?&npi=${userCustomData.npi}`,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.memberId, memberOne]);

  useEffect(() => {
    if (memberOne && memberOne.error !== "no member found") {
      //setClaimsData(claimsTmp);
      setDateRanges(
        memberOne.sgc.sgcData
          .sort((a, b) => new Date(a.time) > new Date(b.time))
          .map((x) => [x.timeStart, x.time])
          .slice(-4)
      );
      let dataTmp = {};
      let colTmp = {};
      let nivoTmp = [];
      memberOne.sgc.sgcData.forEach((x) => {
        let nivoItem = {};

        Object.entries(x.breakdown).forEach((y) => {
          const key = x.timeStart + y[0];
          colTmp[y[0]] = 1;
          dataTmp[key] = y[1];
          dataTmp[x.timeStart] = dataTmp[x.timeStart]
            ? dataTmp[x.timeStart] + y[1]
            : y[1];

          if (serviceOptions.find((x) => x.key === y[0])) {
            nivoItem[serviceOptions.find((x) => x.key === y[0]).text] =
              Math.round(y[1]);
          }
        });
        nivoItem.time = x.timeStart.substring(0, 7);
        nivoItem.timeEnd = new Date(new Date(x.time).getTime() - 86400000)
          .toISOString()
          .substring(0, 7);
        nivoTmp.push(nivoItem);
      });

      setCostData(dataTmp);
      setNivoData(nivoTmp);
      setServiceOptions2(serviceOptions.filter((x) => colTmp[x.key]));

      setLoading(false);
    }
  }, [memberOne]);

  if (loading) {
    return (
      <div className="messages box">
        <Dimmer active inverted>
          <Loader inverted>Loading</Loader>
        </Dimmer>
      </div>
    );
  } else {
    return (
      <div className="messages" style={{ margin: 0 }}>
        <Grid style={{ margin: 0, padding: "1em" }}>
          <Grid.Row columns={2}>
            <Grid.Column>
              <Header as="h5">1. Tabular View</Header>
              <Table size="small" textAlign="center" collapsing>
                <Table.Header>
                  <Table.Row>
                    <Table.HeaderCell></Table.HeaderCell>
                    {dateRanges.map((x) => (
                      <Table.HeaderCell key={x[1]}>
                        {x[0].substring(0, 7)}
                        &nbsp;~
                        <br />
                        {new Date(new Date(x[1]).getTime() - 86400000)
                          .toISOString()
                          .substring(0, 7)}
                      </Table.HeaderCell>
                    ))}
                  </Table.Row>
                </Table.Header>
                <Table.Body>
                  <Table.Row
                    style={{ fontWeight: 700, backgroundColor: "#f6f9fb" }}
                  >
                    <Table.Cell textAlign="left">Total Cost</Table.Cell>
                    {dateRanges.map((x) => (
                      <Table.Cell key={x[0]} textAlign="right">
                        {costData[x[0]] ? (
                          <Link
                            to={{
                              pathname: `/members/${props.memberId}/claims-data`,
                              search:
                                `?view=${query.view || "all-members"}` +
                                `&npi=${query.npi || "any"}` +
                                `&raf=${query.raf || "any"}` +
                                `&sort=${query.sort || "riskScoreDesc"}` +
                                `&page=${query.page || 1}` +
                                `&serviceType=any` +
                                `&dateRangeStart=${x[0].substring(0, 7)}` +
                                `&dateRangeEnd=${x[1].substring(0, 7)}`,
                            }}
                          >
                            {numberWithCommas(Math.round(costData[x[0]]), "$")}
                          </Link>
                        ) : (
                          <span>.</span>
                        )}
                      </Table.Cell>
                    ))}
                  </Table.Row>
                  {serviceOptions2.map((serviceType) => (
                    <Table.Row key={serviceType.key}>
                      <Table.Cell textAlign="left">
                        <b>&nbsp;&nbsp;{serviceType.text}</b>
                      </Table.Cell>
                      {dateRanges.map((x) => (
                        <Table.Cell key={x[0]} textAlign="right">
                          {costData[x[0] + serviceType.key] ? (
                            <Link
                              to={{
                                pathname: `/members/${props.memberId}/claims-data`,
                                search:
                                  `?view=${query.view || "all-members"}` +
                                  `&npi=${query.npi || "any"}` +
                                  `&raf=${query.raf || "any"}` +
                                  `&sort=${query.sort || "riskScoreDesc"}` +
                                  `&page=${query.page || 1}` +
                                  `&serviceType=${serviceType.key}` +
                                  `&dateRangeStart=${x[0].substring(0, 7)}` +
                                  `&dateRangeEnd=${x[1].substring(0, 7)}`,
                              }}
                            >
                              {numberWithCommas(
                                Math.round(costData[x[0] + serviceType.key]),
                                "$"
                              )}
                            </Link>
                          ) : (
                            <span>.</span>
                          )}
                        </Table.Cell>
                      ))}
                    </Table.Row>
                  ))}
                </Table.Body>
              </Table>
            </Grid.Column>
            <Grid.Column>
              <Header as="h5">2. Chart View</Header>
              <ResponsiveBar
                data={nivoData}
                height={500}
                keys={serviceOptions2.map((serviceType) => serviceType.text)}
                indexBy="time"
                margin={{ top: 50, right: 180, bottom: 60, left: 80 }}
                padding={0.3}
                valueScale={{ type: "linear" }}
                indexScale={{ type: "band", round: true }}
                colors={{ scheme: "nivo" }}
                defs={[
                  {
                    id: "dots",
                    type: "patternDots",
                    background: "inherit",
                    color: "#38bcb2",
                    size: 4,
                    padding: 1,
                    stagger: true,
                  },
                  {
                    id: "lines",
                    type: "patternLines",
                    background: "inherit",
                    color: "#eed312",
                    rotation: -45,
                    lineWidth: 6,
                    spacing: 10,
                  },
                ]}
                fill={[
                  {
                    match: {
                      id: "IP Surgical",
                    },
                    id: "lines",
                  },
                  {
                    match: {
                      id: "OP ER",
                    },
                    id: "dots",
                  },
                ]}
                axisTop={null}
                axisRight={null}
                axisBottom={{
                  tickSize: 5,
                  tickPadding: 5,
                  tickRotation: -45,
                  legend: "time",
                  legendPosition: "middle",
                  legendOffset: 55,
                }}
                axisLeft={{
                  tickSize: 5,
                  tickPadding: 5,
                  tickRotation: 0,
                  legend: "cost",
                  legendPosition: "middle",
                  legendOffset: -60,
                }}
                labelSkipWidth={12}
                labelSkipHeight={12}
                labelTextColor={{ from: "color", modifiers: [["darker", 1.6]] }}
                legends={[
                  {
                    dataFrom: "keys",
                    anchor: "bottom-right",
                    direction: "column",
                    justify: false,
                    translateX: 120,
                    translateY: 0,
                    itemsSpacing: 2,
                    itemWidth: 100,
                    itemHeight: 20,
                    itemDirection: "left-to-right",
                    itemOpacity: 0.85,
                    symbolSize: 20,
                    effects: [
                      {
                        on: "hover",
                        style: {
                          itemOpacity: 1,
                        },
                      },
                    ],
                  },
                ]}
                onClick={(e) => {
                  history.push({
                    pathname: `/members/${props.memberId}/claims-data`,
                    search:
                      `?view=${query.view || "all-members"}` +
                      `&npi=${query.npi || "any"}` +
                      `&raf=${query.raf || "any"}` +
                      `&sort=${query.sort || "riskScoreDesc"}` +
                      `&page=${query.page || 1}` +
                      `&serviceType=${
                        serviceOptions.find((x) => x.text === e.id).key
                      }` +
                      `&dateRangeStart=${e.data.time}` +
                      `&dateRangeEnd=${e.data.timeEnd}`,
                  });
                }}
              />
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </div>
    );
  }
}
