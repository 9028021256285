import React from "react";
import { Table, Header, Icon } from "semantic-ui-react";
import ErrorBoundary from "react-error-boundary";
import "moment-timezone";
//import moment from 'moment-timezone';
import { useQuery } from "../Common/utils";
import { useHistory, Link } from "react-router-dom";
import { useStitchAuth } from "../StitchAuth";

export default function ContentTable(props) {
  const history = useHistory();
  let query = useQuery();
  const { userCustomData } = useStitchAuth();

  const sortableColumns = [
    { name: "Name", key: "name" },
    { name: "RAF", key: "riskScore" },
  ];
  const handleRowClick = (mbi) => {
    history.push({
      pathname: `/members/${mbi}/profile`,
      search:
        `?view=${props.view || "all-members"}` +
        `&npi=${props.npi || userCustomData.npi || "any"}` +
        `&raf=${query.raf || "any"}` +
        `&sort=${props.sort || "riskScoreDesc"}` +
        `&page=${props.page || 1}`,
    });
  };

  return (
    <ErrorBoundary>
      <Table selectable basic="very" size="small">
        <Table.Header>
          <Table.Row>
            {sortableColumns.map((col) => (
              <Table.HeaderCell
                key={col.key}
                onClick={() => {
                  if (query.sort === `${col.key}Desc`) {
                    history.push({
                      search:
                        `?view=${query.view || "all-members"}` +
                        `&npi=${query.npi || "any"}` +
                        `&sort=${col.key}Asc` +
                        `&page=${query.page || 1}`,
                    });
                  } else {
                    history.push({
                      search:
                        `?view=${query.view || "all-members"}` +
                        `&npi=${query.npi || "any"}` +
                        `&sort=${col.key}Desc` +
                        `&page=${query.page || 1}`,
                    });
                  }
                }}
              >
                {col.name}
                {![`${col.key}Asc`, `${col.key}Desc`].includes(query.sort) && (
                  <Icon name="sort" className="grey text" />
                )}
                {query.sort === `${col.key}Asc` && <Icon name="sort up" />}
                {query.sort === `${col.key}Desc` && <Icon name="sort down" />}
              </Table.HeaderCell>
            ))}
            <Table.HeaderCell textAlign="center">AWV Due</Table.HeaderCell>
            <Table.HeaderCell textAlign="center">
              G0439 {`(Y${new Date().getFullYear() - 2000})`}
            </Table.HeaderCell>
            <Table.HeaderCell textAlign="center">
              G0438 {`(Y${new Date().getFullYear() - 2000})`}
            </Table.HeaderCell>
            <Table.HeaderCell textAlign="center">
              G0439 {`(Y${new Date().getFullYear() - 2001})`}
            </Table.HeaderCell>
            <Table.HeaderCell textAlign="center">
              G0438 {`(Y${new Date().getFullYear() - 2001})`}
            </Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {props.memberData.map((d) => (
            <Table.Row key={d._id} onClick={() => handleRowClick(d.mbi)}>
              <Table.Cell>
                <Header as="h5">
                  <Link to={`#`}>{d.name}</Link>
                  <Header.Subheader>
                    <small>
                      {d.gender},&nbsp;
                      {Math.floor(
                        (new Date().getTime() -
                          new Date(d.dob.slice(0, 10)).getTime()) /
                          31557600000
                      )}{" "}
                      y/o,&nbsp;
                      {d.mbi}
                    </small>
                  </Header.Subheader>
                </Header>
              </Table.Cell>
              <Table.Cell textAlign="center">
                <Header as="h5">
                  {Math.round(d.raf_my * 1000) / 1000}
                  <Header.Subheader>
                    {d.hcc_recapture_cnt > 0 ? (
                      <small className="red text">
                        <b>{d.hcc_recapture_cnt} open HCCs</b>
                      </small>
                    ) : (
                      <small>all HCCs reported.</small>
                    )}
                  </Header.Subheader>
                </Header>
              </Table.Cell>
              <Table.Cell textAlign="center">
                {d.awv_is_due === "Y" ? (
                  <span className="red text">
                    <b>Yes</b>,&nbsp;
                    <small>
                      {d.awv_code} by {d.awv_due_date}
                    </small>
                  </span>
                ) : (
                  <span>No</span>
                )}
              </Table.Cell>
              <Table.Cell textAlign="center">
                <small>{d.awv_g0439_my}</small>
              </Table.Cell>
              <Table.Cell textAlign="center">
                <small>{d.awv_g0438_my}</small>
              </Table.Cell>
              <Table.Cell textAlign="center">
                <small>{d.awv_g0439_py}</small>
              </Table.Cell>
              <Table.Cell textAlign="center">
                <small>{d.awv_g0438_py}</small>
              </Table.Cell>
            </Table.Row>
          ))}
        </Table.Body>
      </Table>
    </ErrorBoundary>
  );
}
