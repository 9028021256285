import React, { useState, useEffect } from "react";
import {
  Menu,
  Header,
  Divider,
  Dimmer,
  Loader,
  Grid,
  Dropdown,
  Icon,
  Button,
} from "semantic-ui-react";
import { StitchMembersProvider } from "../StitchMembers";
import AppBar from "../Common/AppBar";
//import ContractWarning from "../Common/ContractWarning";
import Workspace from "../Common/Workspace";
import "../Common/Layout.css";
import ContentTable from "./ContentTable";
import { useHistory } from "react-router-dom";
import { useStitchAuth } from "../StitchAuth";
import { useQuery } from "../Common/utils";
import { saveSync, save } from "save-file";
import { numberWithCommas } from "../Common/utils.js";

export default function MemberList() {
  let query = useQuery();
  const history = useHistory();
  const {
    currentUser,
    userCustomData,
    accessLevel,
    actions: { handleRefreshCustomData },
    db: { providers, pafs },
  } = useStitchAuth();
  const [loading, setLoading] = useState(true);
  const [provOptions, setProvOptions] = useState([]);
  const [memberData, setMemberData] = useState({ data: [] });

  const filterList = [
    { name: "all-members", query: {}, desc: "All Members", divider: true },
    {
      name: "awv-is-due",
      query: { awv_is_due: "N" },
      desc: "Members who are due for AWV",
    },
    {
      name: "awv-completed",
      query: { awv_completed: "N" },
      desc: "Members who completed AWVs for the current year",
    },
    {
      name: "awv-incomplete",
      query: { awv_incomplete: "N" },
      desc: "Members who have not completed AWVs for the current year",
    },
  ];

  const sortParams = [
    {
      key: "riskScoreAsc",
      value: "riskScoreAsc",
      text: "RISK SCORE (Current, Low -> High)",
    },
    {
      key: "riskScoreDesc",
      value: "riskScoreDesc",
      text: "RISK SCORE (Current, High -> Low)",
    },
    { key: "nameAsc", value: "nameAsc", text: "NAME (A -> Z)" },
    { key: "nameDesc", value: "nameDesc", text: "NAME (Z -> A)" },
  ];

  const handleLFChange = (newView) => {
    // view, npi, sort, page
    history.push({
      pathname: "/awvs",
      search:
        `?view=${newView}` +
        `&npi=${query.npi || "any"}` +
        `&sort=${query.sort || "riskScoreDesc"}` +
        `&page=${query.page || 1}`,
    });
  };

  const exportMembers = async () => {
    setLoading(true);
    const res = await currentUser.functions.exportAWVs(
      currentUser.id,
      query.npi
    );
    const csvFile = JSON.parse(res)
      .map((x) => x.join(","))
      .join("\n");
    await saveSync(csvFile, `memberList-v${query.view}-v${query.npi}.csv`);
    setLoading(false);
  };

  useEffect(() => {
    if (!userCustomData) {
      handleRefreshCustomData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userCustomData]);

  useEffect(() => {
    if (providers && userCustomData) {
      setLoading(false);
      providers
        .find(
          { owner_id: userCustomData.group_id },
          {
            projection: {
              npi: 1,
              "_org_data.tin": 1,
              firstName: 1,
              lastName: 1,
              nMembers: 1,
              member_cnt: 1,
            },
          }
        )
        .then((res) => {
          let tinMap = {};
          let tmp = res.map((x) => {
            if (x._org_data.tin in tinMap) {
              tinMap[x._org_data.tin].nMbr += x.nMembers || x.member_cnt || 0;
              tinMap[x._org_data.tin].npiLst += "::" + x.npi;
            } else {
              tinMap[x._org_data.tin] = {
                nMbr: x.nMembers || x.member_cnt || 0,
                npiLst: x.npi,
                key: x._org_data.tin,
              };
            }
            return {
              key: x.npi,
              value: x.npi,
              text: `${x.firstName} ${x.lastName} (nMbr: ${
                x.nMembers || x.member_cnt || 0
              }, npi: ${x.npi}, tin: ${x._org_data.tin})`,
            };
          });

          let tinLst = Object.values(tinMap).map((x) => {
            return {
              key: x.key,
              value: "TIN" + x.key,
              text: `TIN: ${x.key} (nMbr: ${x.nMbr})`,
            };
          });
          tmp.sort((a, b) => a.text.localeCompare(b.text));
          setProvOptions([
            { key: "any", value: "any", text: "Any Providers" },
            ...tmp,
            ...tinLst,
          ]);
        });
    }
  }, [providers, userCustomData]);

  useEffect(() => {
    if (currentUser && userCustomData) {
      setLoading(true);

      if (!query.view || !query.npi || !query.sort || !query.page) {
        // view, npi, sort, page
        history.push({
          pathname: "/awvs",
          search:
            `?view=${query.view || "all-members"}` +
            `&npi=${query.npi || userCustomData.npi || "any"}` +
            `&sort=${query.sort || "riskScoreDesc"}` +
            `&page=${query.page || 1}`,
        });
      } else {
        currentUser.functions
          .fetchAWVs(
            currentUser.id,
            query.view,
            query.npi,
            query.sort,
            query.page
          )
          .then((res) => {
            setMemberData(JSON.parse(res));
            setLoading(false);
          });
      }
    }
  }, [
    history,
    currentUser,
    query.view,
    query.npi,
    query.sort,
    query.page,
    userCustomData,
  ]);

  if (loading) {
    return (
      <div className="app-layout">
        <AppBar />
        <Workspace />
        <Dimmer active inverted>
          <Loader inverted>Loading</Loader>
        </Dimmer>
      </div>
    );
  } else {
    return (
      <div className="app-layout">
        <AppBar />
        <Workspace />
        <div className="channels box">
          <Header as="h4" style={{ paddingLeft: "1em" }}>
            View Options
          </Header>
          <Menu vertical secondary style={{ background: "transparent" }}>
            {filterList.map((option) => (
              <div key={option.name}>
                <Menu.Item
                  active={query.view === option.name}
                  onClick={() => handleLFChange(option.name)}
                >
                  #&nbsp;&nbsp;{option.name}
                </Menu.Item>
                {option.divider && <Divider />}
              </div>
            ))}
          </Menu>
        </div>
        <div className="header-panel box">
          <Grid>
            <Grid.Row columns={1}>
              <Grid.Column>
                <Header as="h4">
                  {filterList.find((x) => x.name === query.view).desc}&nbsp;
                  <Header.Subheader>
                    <small>last updated at {new Date().toLocaleString()}</small>
                    <br />
                  </Header.Subheader>
                </Header>
              </Grid.Column>
              <Grid.Column>
                <Button primary size="mini" onClick={() => exportMembers()}>
                  Download the table in CSV.
                </Button>
                {query.npi !== "any" && !query.npi.includes("::") ? (
                  <Button
                    color="green"
                    size="mini"
                    onClick={() => {
                      setLoading(true);
                      pafs
                        .find({
                          owner_id: userCustomData.group_id,
                          npi: query.npi,
                        })
                        .then((data) => {
                          let dataTarget = data
                            .filter((x) => x.awv_group === "all")
                            .sort((a, b) =>
                              b.created_at.localeCompare(a.created_at)
                            );
                          save(
                            dataTarget[0].pdfBase64,
                            `${dataTarget[0].fn}.pdf`
                          );
                          setLoading(false);
                        });
                    }}
                  >
                    Download AWV PDFs (all).
                  </Button>
                ) : (
                  <Button color="green" disabled size="mini">
                    Select NPI to download AWV PDFs (all).
                  </Button>
                )}
                {query.npi !== "any" && !query.npi.includes("::") ? (
                  <Button
                    color="red"
                    size="mini"
                    onClick={() => {
                      setLoading(true);
                      pafs
                        .find({
                          owner_id: userCustomData.group_id,
                          npi: query.npi,
                        })
                        .then((data) => {
                          let dataTarget = data
                            .filter((x) => x.awv_group === "incomplete")
                            .sort((a, b) =>
                              b.created_at.localeCompare(a.created_at)
                            );
                          save(
                            dataTarget[0].pdfBase64,
                            `${dataTarget[0].fn}.pdf`
                          );
                          setLoading(false);
                        });
                    }}
                  >
                    Download AWV PDFs (only due).
                  </Button>
                ) : (
                  <Button color="red" disabled size="mini">
                    Select NPI to download AWV PDFs (only due).
                  </Button>
                )}
              </Grid.Column>
              <hr />
              <Grid.Column textAlign="left">
                Select members of&nbsp;
                <Dropdown
                  placeholder="any PCP"
                  inline
                  scrolling
                  search
                  direction="right"
                  value={query.npi}
                  options={provOptions}
                  disabled={accessLevel === "limited"}
                  onChange={(e, { value }) => {
                    history.push({
                      pathname: "/awvs",
                      search:
                        `?view=${query.view || "all-members"}` +
                        `&npi=${value}` +
                        `&sort=${query.sort || "riskScoreDesc"}` +
                        `&page=1`,
                    });
                  }}
                />
                &nbsp;and Sort by&nbsp;
                <Dropdown
                  placeholder="Any"
                  inline
                  direction="right"
                  scrolling
                  value={query.sort}
                  options={sortParams}
                  onChange={(e, { value }) => {
                    history.push({
                      pathname: "/awvs",
                      search:
                        `?view=${query.view || "all-members"}` +
                        `&npi=${query.npi || "any"}` +
                        `&sort=${value}` +
                        `&page=1`,
                    });
                  }}
                />
                &nbsp;&nbsp;Showing {memberData.startIdx + 1} -{" "}
                {memberData.endIdx} of {numberWithCommas(memberData.numTotal)}
                &nbsp;&nbsp;&nbsp;
                <Icon
                  name="angle left"
                  onClick={() => {
                    history.push({
                      pathname: "/awvs",
                      search:
                        `?view=${query.view || "all-members"}` +
                        `&npi=${query.npi || "any"}` +
                        `&sort=${query.sort || "riskScoreDesc"}` +
                        `&page=${Math.max(parseInt(query.page) - 1 || 1, 1)}`,
                    });
                  }}
                />
                &nbsp;&nbsp;&nbsp;
                <Icon
                  name="angle right"
                  onClick={() => {
                    history.push({
                      pathname: "/awvs",
                      search:
                        `?view=${query.view || "all-members"}` +
                        `&npi=${query.npi || "any"}` +
                        `&sort=${query.sort || "riskScoreDesc"}` +
                        `&page=${Math.min(
                          memberData.pageMax,
                          parseInt(query.page) + 1
                        )}`,
                    });
                  }}
                />
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </div>
        <div
          className="messages box"
          style={{ padding: "1em", paddingBottom: "5em" }}
        >
          <StitchMembersProvider>
            <Grid>
              <Grid.Row columns={1}>
                <Grid.Column>
                  <ContentTable
                    memberData={memberData.data}
                    npi={query.npi}
                    view={query.view}
                    sort={query.sort}
                    page={query.page}
                  />
                </Grid.Column>
              </Grid.Row>
              <Grid.Row columns={1}>
                <Grid.Column textAlign="right">
                  <small>
                    Showing {memberData.startIdx + 1} - {memberData.endIdx} of{" "}
                    {numberWithCommas(memberData.numTotal)}
                    &nbsp;&nbsp;&nbsp;
                    <Icon
                      name="angle left"
                      onClick={() => {
                        history.push({
                          pathname: "/members",
                          search:
                            `?view=${query.view || "all-members"}` +
                            `&npi=${query.npi || "any"}` +
                            `&sort=${query.sort || "riskScoreDesc"}` +
                            `&page=${Math.max(
                              parseInt(query.page) - 1 || 1,
                              1
                            )}`,
                        });
                      }}
                    />
                    &nbsp;&nbsp;&nbsp;
                    <Icon
                      name="angle right"
                      onClick={() => {
                        history.push({
                          pathname: "/members",
                          search:
                            `?view=${query.view || "all-members"}` +
                            `&npi=${query.npi || "any"}` +
                            `&sort=${query.sort || "riskScoreDesc"}` +
                            `&page=${Math.min(
                              memberData.pageMax,
                              parseInt(query.page) + 1
                            )}`,
                        });
                      }}
                    />
                  </small>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </StitchMembersProvider>
        </div>
      </div>
    );
  }
}
