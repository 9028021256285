import React, { useEffect, useState } from "react";
import { Grid, Table, Dimmer, Loader, Label } from "semantic-ui-react";
import { useStitchAuth } from "../StitchAuth";
import { useStitchMembers } from "../StitchMembers";
import Moment from "react-moment";
import "moment-timezone";
import { useHistory } from "react-router-dom";

export default function ClaimsData(props) {
  const { userCustomData, accessLevel } = useStitchAuth();
  const { memberOne, actions } = useStitchMembers();
  const [loading, setLoading] = useState(true);
  const [adtFeed, setAdtFeed] = useState([]);
  const history = useHistory();

  useEffect(() => {
    if ((memberOne || {}).mbi !== props.memberId) {
      actions.loadMember(props.memberId);
    }
    if (
      ((memberOne || {}).mbi === props.memberId &&
        accessLevel === "limited" &&
        userCustomData.npi !== (memberOne || {}).pcp_npi) ||
      (memberOne || {}).error === "no member found"
    ) {
      history.push({
        pathname: "/members",
        search: `?&npi=${userCustomData.npi}`,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.memberId, memberOne]);

  useEffect(() => {
    if (memberOne && memberOne.error !== "no member found") {
      let adtData = [...(memberOne.adtData || [])];
      adtData.reverse();
      setAdtFeed(adtData);
      setLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [memberOne]);

  if (loading) {
    return (
      <div className="messages box">
        <Dimmer active inverted>
          <Loader inverted>Loading</Loader>
        </Dimmer>
      </div>
    );
  } else {
    return (
      <div className="messages box" style={{ margin: 0 }}>
        <Grid>
          <Grid.Row columns={1}>
            <Grid.Column>
              <small className="grey text">
                NOTE: ADT (Admission, Discharge, and Transfer) data feeds are
                sent when a patient is admitted to a hospital, transferred to
                another facility, or discharged from the hospital. As these ADT
                events may change dynamically in practice, the data below may
                present some duplicates or missing information.
              </small>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row columns={1}>
            <Grid.Column textAlign="center">
              <Table basic="very" size="small" textAlign="center">
                <Table.Header>
                  <Table.Row>
                    <Table.HeaderCell width={3}>Visit Type</Table.HeaderCell>
                    <Table.HeaderCell width={3}>Time</Table.HeaderCell>
                    <Table.HeaderCell width={3}>Facility</Table.HeaderCell>
                    <Table.HeaderCell width={2}>
                      Admission Reason
                    </Table.HeaderCell>
                    <Table.HeaderCell width={8}>Diagnoses</Table.HeaderCell>
                  </Table.Row>
                </Table.Header>
                <Table.Body>
                  {adtFeed.map((d, i) => (
                    <Table.Row key={i}>
                      <Table.Cell>
                        {d.visitType === "Inpatient" && (
                          <Label color="red">Inpatient</Label>
                        )}
                        {d.visitType === "Emergency" && (
                          <Label color="purple">Emergency</Label>
                        )}
                        {d.visitType === "Preadmit" && (
                          <Label color="blue">Preadmit</Label>
                        )}
                        {!["Inpatient", "Emergency", "Preadmit"].some(
                          (x) => x === d.visitType
                        ) && <Label>{d.visitType}</Label>}
                      </Table.Cell>
                      <Table.Cell>
                        <span className="grey text">ADMT:</span>
                        {d.tsAdmit > 0 && (
                          <Moment
                            format="MM/DD/YYYY, hh:mm A"
                            tz={userCustomData.timeZone}
                            date={d.timeAdmit}
                          />
                        )}
                        <br />
                        <span className="grey text">DSCHRG:</span>
                        {d.tsDischarge > 0 && (
                          <Moment
                            format="MM/DD/YYYY, hh:mm A"
                            tz={userCustomData.timeZone}
                            date={d.timeDischarge}
                          />
                        )}
                      </Table.Cell>
                      <Table.Cell>
                        {d.facility.name}
                        <br />
                        <small className="grey text">
                          {d.facility.address1}
                          <br />
                          {d.facility.city}, {d.facility.state} {d.facility.zip}
                        </small>
                      </Table.Cell>
                      <Table.Cell>
                        {d.admissionReason}
                        <br />
                        <small className="grey text">
                          (source: {d.admissionSource})
                        </small>
                      </Table.Cell>
                      <Table.Cell textAlign="center">
                        {d.diagnosisCodes.map((x, j) => (
                          <Label key={j} basic size="mini" color="black">
                            {x.code}, {x.display}
                          </Label>
                        ))}
                      </Table.Cell>
                    </Table.Row>
                  ))}
                </Table.Body>
              </Table>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </div>
    );
  }
}
