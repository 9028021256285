import * as Realm from "realm-web";
import { app } from "./app.js";

export function hasLoggedInUser() {
  // Check if there is currently a logged in user
  return app.currentUser;
}

export function getCurrentUser() {
  // Return the user object of the currently logged in user
  return app.currentUser;
}

export const getCustomData = async () => {
  if (app.currentUser) {
    const mongodb = app.currentUser.mongoClient("mongodb-atlas");
    const users = mongodb.db("arq1").collection("users");
    const groups = mongodb.db("arq1").collection("groups");
    let customData = await users.findOne({ id: app.currentUser.id });

    if (!customData) {
      customData = {
        name: "",
        namePublic: "",
        timeZone: "America/Los_Angeles",
        role: "admin",
        group_id: app.currentUser.profile.email,
        id: app.currentUser.id,
        email: app.currentUser.profile.email,
        accessLevel: "full",
      };

      await users.insertOne(customData);
    }

    let groupData = await groups.findOne({
      group_id: customData.group_id || "",
    });

    if (!groupData) {
      groupData = {
        group_id: customData.group_id,
        hasBasicInformation: false,
        signedContract: false,
      };
      await groups.insertOne(groupData);
    }
    const data = { ...groupData, ...customData };
    return data;
  } else {
    return null;
  }
};

export function logoutCurrentUser() {
  return app.currentUser.logOut();
}

export async function signupUser(email, password) {
  return await app.emailPasswordAuth.registerUser(email, password);
}

export async function confirmEmail(token, tokenId) {
  return await app.emailPasswordAuth.confirmUser(token, tokenId);
}

export function loginUser(email, password) {
  const credentials = Realm.Credentials.emailPassword(email, password);
  //const credential = new UserPasswordCredential(email, password);
  try {
    const user = app.logIn(credentials);
    //assert(user.id===app.currentUser.id);
    return user;
  } catch (err) {
    console.error("Failed to log in", err);
  }
}

export async function sendResetPasswordEmail(email) {
  return await app.emailPasswordAuth.sendResetPasswordEmail(email);
}

export async function resetPassword(token, tokenId, newPassword) {
  //return await app.emailPasswordAuth.resetPassword(newPassword, token, tokenId);
  return await app.emailPasswordAuth.resetPassword(token, tokenId, newPassword);
}
