import React, { useState } from "react";
import { useStitchAuth } from "../StitchAuth";
import { List, Button, Loader, Dimmer } from "semantic-ui-react";
import { useHistory } from "react-router-dom";
import _ from "lodash";
import ReactTooltip from "react-tooltip";

export default function Workspace(props) {
  const { userCustomData, actions } = useStitchAuth();
  const [loading, setLoading] = useState(false);
  let history = useHistory();

  const handleWorkspaceChange = async (group_id) => {
    setLoading(true);
    await actions.handleWorkspaceChange(group_id);
    setLoading(false);
    history.push({
      pathname: "/members",
    });
  };

  if (userCustomData && userCustomData.workspaceAccess) {
    return (
      <div className="workspace box-small">
        <List>
          {_.map(userCustomData.workspaces, (x) => {
            return (
              <List.Item key={x.group_id}>
                <Button
                  size="mini"
                  onClick={() => handleWorkspaceChange(x.group_id)}
                  style={{
                    padding: "1em",
                    margin: "1px 1px 10px",
                    width: "40px",
                    height: "40px",
                  }}
                  active={x.group_id === userCustomData.group_id}
                  data-tip={x.name || x.code}
                >
                  {x.code}
                </Button>
              </List.Item>
            );
          })}
        </List>
        <ReactTooltip place="right" type="light" effect="solid" />
        {loading && (
          <Dimmer active inverted>
            <Loader inverted>Switching Workspace</Loader>
          </Dimmer>
        )}
      </div>
    );
  } else {
    return <div className="workspace"></div>;
  }
}
