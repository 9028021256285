import React, { useState } from 'react'
import { Button, Form, Grid, Header, Segment, Message } from 'semantic-ui-react'
import { useStitchAuth } from "./StitchAuth";
import { useLocation, } from "react-router-dom";

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const PasswordResetForm = props => {

  const [password, setPassword] = useState("");

  const [formError, setFormError] = useState(false);
  const [success, setSuccess] = useState(false);

  const { actions } = useStitchAuth();

  
  let query = useQuery();
  let token = query.get('token');
  let tokenId = query.get('tokenId');

  console.log(token)
  console.log(tokenId)

  const handleSubmit = event => {

    actions.handleResetPassword(token, tokenId, password)
      .then(
        () => {
          //history.replace({pathname: '/login'});
          setSuccess(true);
        }
      )
      .catch(
        () => { setFormError(true); }
      );
  }

  if (success){
    return (
    <Grid textAlign='center' style={{ height: '100vh' }} verticalAlign='middle'>
      <Grid.Column style={{ maxWidth: 450 }}>
        <Header as='h2' textAlign='center'>
          Password Reset Success!
          <Header.Subheader>
            Please visit the login page and re-login with your new password.
          </Header.Subheader>
        </Header> 
      </Grid.Column>
    </Grid>);
  }else{
    return (
      <Grid textAlign='center' style={{ height: '100vh' }} verticalAlign='middle'>
        <Grid.Column style={{ maxWidth: 450 }}>
          <Header as='h2' textAlign='center'>
            <Header.Content>Reset Password</Header.Content>
          </Header> 
          <Form size='large' error={formError}>
            <Segment style={{border: '0', boxShadow: '0 0 0 0'}}>
              <Message
                error
                header='Invalid Password'
                content='Password should have at least 6 characters.'
              />
              <Form.Input
                fluid
                icon='lock'
                iconPosition='left'
                placeholder='New Password'
                type='password'
                value={password}
                onChange={(event) => setPassword(event.target.value.replace(/\s/g, ''))}
              />
              <Button 
                style={{backgroundColor: "#335eea", color: "#fff"}}
                fluid size='large' 
                onClick={handleSubmit}>
                Change Password
              </Button>
            </Segment>
          </Form>
        </Grid.Column>
      </Grid>
    );
  }
}

export default PasswordResetForm;