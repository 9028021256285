import React, { useState, useEffect } from "react";
import {
  Menu,
  Header,
  Breadcrumb,
  Dimmer,
  Loader,
  Grid,
  Popup,
} from "semantic-ui-react";
import { useStitchAuth } from "../StitchAuth";
import { StitchMembersProvider, useStitchMembers } from "../StitchMembers";
import AppBar from "../Common/AppBar";
import Workspace from "../Common/Workspace";
import "../Common/Layout.css";
import {
  useParams,
  useHistory,
  useLocation,
  useRouteMatch,
  Link,
  Switch,
  Route,
} from "react-router-dom";
import Profile from "./Profile";
import ClaimsData from "./ClaimsData";
import ADTFeed from "./ADTFeed";
import CostBreakdown from "./CostBreakdown";
import RiskInsights from "./RiskInsights";
import QualityInsights from "./QualityInsights";
import { useQuery } from "../Common/utils";

function HeaderSection(props) {
  const { memberOne } = useStitchMembers();

  return (
    <div className="header-panel box">
      <Grid>
        {memberOne && (
          <Grid.Row columns={1}>
            <Grid.Column>
              <Header as="h4">
                <span style={{ color: "#869AB8" }}>{memberOne.name} ></span>{" "}
                {props.title}
                <Header.Subheader>
                  <small>
                    {memberOne.gender}, {memberOne.age} yrs old (
                    {(memberOne.dob || "").substring(0, 10)})
                  </small>
                </Header.Subheader>
                <Header.Subheader>
                  <small>last updated at {new Date().toLocaleString()}</small>
                </Header.Subheader>
              </Header>
            </Grid.Column>
          </Grid.Row>
        )}
      </Grid>
    </div>
  );
}

export default function MemberDetails() {
  const { userCustomData } = useStitchAuth();
  const { memberId } = useParams();
  const history = useHistory();
  const [loading, setLoading] = useState(true);
  let query = useQuery();
  let { path, url } = useRouteMatch();
  const location = useLocation();

  const viewList = [
    {
      pathname: "profile",
      disabled: false,
      divider: false,
    },
    {
      pathname: "cost-breakdown",
      disabled: false,
      divider: false,
    },
    {
      pathname: "claims-data",
      disabled: false,
      divider: false,
    },
    {
      pathname: "adt-feed",
      disabled: !(userCustomData || {}).adtConnected,
      divider: false,
      disabledMsg: "ADT not connected",
    },
    {
      pathname: "hcc-insights",
      disabled: false,
      divider: false,
    },
    {
      pathname: "gic-insights",
      disabled: false,
      divider: false,
    },
    {
      pathname: "suggestions",
      disabled: true,
      divider: false,
      disabledMsg: "Available in June 2022",
    },
  ];

  useEffect(() => {
    if (userCustomData && loading) {
      setLoading(false);
    }
  }, [userCustomData, loading]);

  if (loading) {
    return (
      <div className="app-layout">
        <AppBar />
        <Workspace />
        <Dimmer active inverted>
          <Loader inverted>Loading</Loader>
        </Dimmer>
      </div>
    );
  } else {
    return (
      <StitchMembersProvider>
        <div className="app-layout">
          <AppBar />
          <Workspace />
          <div className="channels box">
            <Breadcrumb style={{ paddingLeft: "1em", fontSize: "0.8em" }}>
              <Breadcrumb.Section
                link
                style={{ color: "#3c4257" }}
                onClick={() =>
                  history.push({
                    pathname: "/members",
                    search:
                      `?view=${query.view || "all-members"}` +
                      `&npi=${query.npi || userCustomData.npi || "any"}` +
                      `&raf=${query.raf || "any"}` +
                      `&sort=${query.sort || "riskScoreDesc"}` +
                      `&page=${query.page || 1}`,
                  })
                }
              >
                members
              </Breadcrumb.Section>
              <Breadcrumb.Divider
                icon="right angle"
                style={{ color: "#3c4257" }}
              />
              <Breadcrumb.Section style={{ color: "#3c4257" }}>
                member-details
              </Breadcrumb.Section>
            </Breadcrumb>
            <Header as="h4" style={{ paddingLeft: "1em" }}>
              Member Details
            </Header>
            <Menu vertical secondary style={{ background: "transparent" }}>
              {viewList.map((view) => (
                <Menu.Item
                  key={view.pathname}
                  disabled={view.disabled}
                  active={
                    location.pathname ===
                    `/members/${memberId}/${view.pathname}`
                  }
                >
                  {view.disabled ? (
                    <Popup
                      trigger={<span>{`#  ${view.pathname}`}</span>}
                      position="bottom left"
                      content={
                        <div style={{ width: "120px" }}>{view.disabledMsg}</div>
                      }
                    />
                  ) : (
                    <Link
                      to={`${url}/${view.pathname}`}
                      style={{ color: "#3c4257" }}
                    >
                      {`#  ${view.pathname}`}
                    </Link>
                  )}
                </Menu.Item>
              ))}
            </Menu>
          </div>
          <Switch>
            <Route path={`${path}/profile`}>
              <HeaderSection memberId={memberId} title="Profile" />
              <Profile memberId={memberId} />
            </Route>
            <Route path={`${path}/cost-breakdown`}>
              <HeaderSection memberId={memberId} title="Cost Breakdown" />
              <CostBreakdown memberId={memberId} />
            </Route>
            <Route path={`${path}/claims-data`}>
              <HeaderSection memberId={memberId} title="Claims Data" />
              <ClaimsData memberId={memberId} />
            </Route>
            <Route path={`${path}/adt-feed`}>
              <HeaderSection memberId={memberId} title="ADT Feed" />
              <ADTFeed memberId={memberId} />
            </Route>
            <Route path={`${path}/hcc-insights`}>
              <HeaderSection memberId={memberId} title="HCC Insights" />
              <RiskInsights memberId={memberId} />
            </Route>
            <Route path={`${path}/gic-insights`}>
              <HeaderSection
                memberId={memberId}
                title="Gaps-in-Care Insights"
              />
              <QualityInsights memberId={memberId} />
            </Route>
          </Switch>
        </div>
      </StitchMembersProvider>
    );
  }
}
