import React, { useEffect, useState } from "react";
import { Grid, Dimmer, Loader, Header, List, Button } from "semantic-ui-react";
import { useStitchAuth } from "../StitchAuth";
import { useStitchProviders } from "../StitchProviders";
import save from "save-file";
import { Document, Page, pdfjs } from "react-pdf";
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

export default function PafReports(props) {
  const {
    userCustomData,
    db: { pafs },
  } = useStitchAuth();
  const [pdf, setPdf] = useState();
  const [reportSelected, setReportSelected] = useState("");
  const [reports, setReports] = useState([]);
  const [numPages, setNumPages] = useState(1);
  const [pageNumber, setPageNumber] = useState(1);
  const { actions } = useStitchProviders();
  const [loading, setLoading] = useState(true);
  /*
  const deviceMap = {BT004: 'Weight Scale (BT004)',
                    BT105: 'Blood Pressure Cuff (BT105)',
                    SMIG: 'iGlucose Glucometer (SMIG)',
                    ACKM: 'AliveCor Kardia Mobile (ACKM)',
                    Vivosmart4: 'Garmin Vivosmart 4'}
  */
  useEffect(() => {
    if (userCustomData) {
      actions.loadProvider(userCustomData.group_id, props.providerId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userCustomData, props.providerId]);

  useEffect(() => {
    if (pafs) {
      pafs
        .find(
          {
            owner_id: userCustomData.group_id,
            npi: props.providerId,
          },
          { pdfBase64: -1 }
        )
        .then((data) => {
          data.sort((a, b) => b.created_at.localeCompare(a.created_at));
          setReports(data);
          setReportSelected(data[0].fn);
          pafs
            .findOne({
              owner_id: userCustomData.group_id,
              npi: props.providerId,
              fn: data[0].fn,
            })
            .then((data0) => setPdf(data0.pdfBase64));
          setLoading(false);
        });
    }
  }, [pafs, userCustomData.group_id, props.providerId]);

  const changeReport = (fn) => {
    setReportSelected(fn);
    setPdf(reports.find((x) => x.fn === fn).pdfBase64);
  };

  if (loading) {
    return (
      <div className="messages box">
        <Dimmer active inverted>
          <Loader inverted>Loading</Loader>
        </Dimmer>
      </div>
    );
  } else {
    return (
      <div className="messages box">
        <Grid style={{ margin: 0 }}>
          <Grid.Row columns={2}>
            <Grid.Column width={10}>
              {pdf && (
                <div>
                  <span>
                    <Button
                      size="mini"
                      primary
                      onClick={() => save(pdf, `${reportSelected}.pdf`)}
                    >
                      Download PDF
                    </Button>
                    <Button
                      size="mini"
                      basic
                      onClick={() => setPageNumber(Math.max(pageNumber - 1, 1))}
                    >
                      Back
                    </Button>
                    <Button
                      size="mini"
                      basic
                      onClick={() =>
                        setPageNumber(Math.min(pageNumber + 1, numPages))
                      }
                    >
                      Next
                    </Button>
                  </span>
                  <small>
                    {" "}
                    Page {pageNumber} of {numPages}{" "}
                  </small>
                  <Document
                    file={"data:application/pdf;base64," + pdf}
                    onLoadSuccess={({ numPages }) => setNumPages(numPages)}
                  >
                    <Page pageNumber={pageNumber} />
                  </Document>
                </div>
              )}
            </Grid.Column>
            <Grid.Column width={6}>
              <Header>Monthly Reports</Header>
              <p>
                On this page, you can browse through or download the Patient
                Assessment Forms for your patients. The PDF bundle contains all
                your patients. If you have many patients, we recommend you
                download the PDF by clicking the "Download PDF" button. You can
                also check previous reports by clicking the older reports below.
              </p>
              <List>
                {reports.map((report, i) => (
                  <List.Item key={report.fn + i}>
                    <Button
                      basic={report.fn !== reportSelected}
                      color={
                        report.fn.toLowerCase().includes("_incomplete")
                          ? "red"
                          : "green"
                      }
                      active={report.fn === reportSelected}
                      onClick={() => changeReport(report.fn)}
                    >
                      Report Name: {report.fn}
                      {report.fn === reportSelected && " (currently showing)"}
                    </Button>
                  </List.Item>
                ))}
              </List>
              <Header>AWV Cheat Sheet</Header>
              <p>
                Please use this AWV (G0438/G0439) cheat sheet to improve your
                AWV coding. Read the CPT descriptions below and see if you can
                include these codes in the AWV claim.
              </p>
              <List bulleted>
                <List.Item>
                  <b>99406</b>: Smoking and tobacco cessation counseling visit
                  for the asymptomatic patient; intermediate, greater than 3
                  minutes, up to 10 minutes.
                </List.Item>
                <List.Item>
                  <b>1100F</b>: Patient screened for future fall risk;
                  documentation of two or more fallsin the past year or any fall
                  with injury in the past year.
                </List.Item>
                <List.Item>
                  <b>0518F</b>: Falls plan of care documented.
                </List.Item>
                <List.Item>
                  <b>1123F</b>: Advance Care Planning discussed and documented;
                  advance care plan and surrogate decision maker documented in
                  the medical record.
                </List.Item>
                <List.Item>
                  <b>1124F</b>: Advance Care Planning discussed and documented
                  in the medical record; patient did not wish or was not able to
                  name a surrogate decision maker or provide an advance care
                  plan.
                </List.Item>
                <List.Item>
                  <b>3017F</b>: Colorectal cancer screening results documented
                  and reviewed.
                </List.Item>
                <List.Item>
                  <b>G9899</b>: Screening, diagnostic, film, digital or digital
                  breat Tomosynthesis (3D) mammography results documented and
                  reviewed.
                </List.Item>
                <List.Item>
                  <b>G9509</b>: Adult patients 18 years of age or older with
                  major depression or dysthymia who reached remission at twelve
                  months as demonstrated by a twelve-month (+/-60days) PHQ-9 or
                  PHQ-9M score of less than 5.
                </List.Item>
                <List.Item>
                  <b>G9511</b>: Index Event Date PHQ-9 or PHQ-9M Score greater
                  than 9 documented during the twelve-month denominator
                  identification period.
                </List.Item>
              </List>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </div>
    );
  }
}
