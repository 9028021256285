import React, { useEffect, useState } from "react";
import { Grid, Dimmer, Loader, Label, Table } from "semantic-ui-react";
import { useStitchMembers } from "../StitchMembers";
import { useStitchAuth } from "../StitchAuth";
import { useHistory } from "react-router-dom";

export default function RiskInsights(props) {
  const { memberOne, actions } = useStitchMembers();
  const [loading, setLoading] = useState(true);
  const { userCustomData, accessLevel } = useStitchAuth();
  const history = useHistory();

  useEffect(() => {
    if (memberOne && memberOne.error !== "no member found") {
      setLoading(false);
    }
  }, [memberOne]);

  useEffect(() => {
    if ((memberOne || {}).mbi !== props.memberId) {
      actions.loadMember(props.memberId);
    }

    if (
      ((memberOne || {}).mbi === props.memberId &&
        accessLevel === "limited" &&
        userCustomData.npi !== (memberOne || {}).pcp_npi) ||
      (memberOne || {}).error === "no member found"
    ) {
      history.push({
        pathname: "/members",
        search: `?&npi=${userCustomData.npi}`,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.memberId, memberOne]);

  if (loading) {
    return (
      <div className="messages box">
        <Dimmer active inverted>
          <Loader inverted>Loading</Loader>
        </Dimmer>
      </div>
    );
  } else {
    return (
      <div className="messages box" style={{ margin: 0 }}>
        <Grid>
          <Grid.Row columns={1}>
            <Grid.Column>
              <Table basic="very" size="small" textAlign="center">
                <Table.Header>
                  <Table.Row>
                    <Table.HeaderCell textAlign="left">
                      HCC Desription
                    </Table.HeaderCell>
                    <Table.HeaderCell textAlign="left">
                      Category
                    </Table.HeaderCell>
                    <Table.HeaderCell textAlign="left">
                      Prior Evidence
                    </Table.HeaderCell>
                  </Table.Row>
                </Table.Header>
                <Table.Body>
                  {Object.keys(memberOne.hcc.raf_details)
                    .sort((a, b) => {
                      let offsetA =
                        memberOne.hcc.raf_details[a].status === "closed"
                          ? -1000
                          : 0;
                      let offsetB =
                        memberOne.hcc.raf_details[b].status === "closed"
                          ? -1000
                          : 0;
                      return (
                        parseInt(a.split(" ")[0].slice(3)) +
                        offsetA -
                        parseInt(b.split(" ")[0].slice(3)) +
                        offsetB
                      );
                    })
                    .map((k) => {
                      const v = memberOne.hcc.raf_details[k];
                      return (
                        <Table.Row key={k}>
                          <Table.Cell textAlign="left">{k}</Table.Cell>
                          <Table.Cell textAlign="left">
                            <Label
                              basic
                              color={
                                v.status === "closed"
                                  ? "green"
                                  : v.status === "recapture"
                                  ? "red"
                                  : "purple"
                              }
                            >
                              {v.status}
                            </Label>
                          </Table.Cell>
                          <Table.Cell textAlign="left">
                            <ul>
                              {Object.values(v.evidence).map((vv) => (
                                <li key={vv}>{vv}</li>
                              ))}
                            </ul>
                          </Table.Cell>
                        </Table.Row>
                      );
                    })}
                </Table.Body>
              </Table>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </div>
    );
  }
}
