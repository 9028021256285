import React, { useState, useEffect } from "react";
import { Grid, Header, Table, Dimmer, Loader, Label } from "semantic-ui-react";
import { useStitchAuth } from "../StitchAuth";
import _ from "lodash";

export default function Accounts(props) {
  const {
    userCustomData,
    actions,
    db: { userstats },
  } = useStitchAuth();
  const [accounts, setAccounts] = useState([]);
  const [accessCnts, setAccessCnts] = useState({});

  useEffect(() => {
    let mounted = true;

    if (userCustomData) {
      actions.getOtherUsers(userCustomData.group_id).then((x) => {
        if (mounted) {
          setAccounts(x);
        }
      });
    }

    if (userstats) {
      userstats.find({ owner_id: userCustomData.group_id }).then((res) => {
        let tmpCnt = {};
        const currentYear = new Date().toISOString().substring(0, 4);
        res.forEach((x) => {
          if (x.date.substring(0, 4) === currentYear) {
            tmpCnt[x.email] = tmpCnt[x.email] ? tmpCnt[x.email] + 1 : 1;
          }
        });
        if (mounted) {
          setAccessCnts(tmpCnt);
        }
      });
    }

    return () => {
      mounted = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userCustomData, userstats]);

  if (!userCustomData) {
    return (
      <div className="messages box">
        <Dimmer active inverted>
          <Loader inverted>Loading</Loader>
        </Dimmer>
      </div>
    );
  } else {
    return (
      <div className="messages box">
        <Grid style={{ margin: 0 }}>
          <Grid.Row>
            <Grid.Column>
              <Header as="h4">
                Registered Accounts
                <Header.Subheader>
                  All registered accounts for your organization.
                </Header.Subheader>
              </Header>
              <Table basic="very" collapsing>
                <Table.Header>
                  <Table.Row>
                    <Table.HeaderCell>Name</Table.HeaderCell>
                    <Table.HeaderCell>E-mail</Table.HeaderCell>
                    <Table.HeaderCell>NPI</Table.HeaderCell>
                    <Table.HeaderCell>ADT Alert</Table.HeaderCell>
                    <Table.HeaderCell>Access Cnt</Table.HeaderCell>
                    <Table.HeaderCell>Role</Table.HeaderCell>
                    <Table.HeaderCell>Access Level</Table.HeaderCell>
                  </Table.Row>
                </Table.Header>
                <Table.Body>
                  {_.map(accounts, (d) => (
                    <Table.Row key={d.name + d.email}>
                      <Table.Cell>{d.name}</Table.Cell>
                      <Table.Cell>
                        <a href={`mailto:${d.email}`}>{d.email}</a>
                      </Table.Cell>
                      <Table.Cell>{d.npi}</Table.Cell>
                      <Table.Cell>{d.adtTarget}</Table.Cell>
                      <Table.Cell>
                        {accessCnts[d.email] ? accessCnts[d.email] : 0}
                      </Table.Cell>
                      <Table.Cell>
                        <Label
                          size="mini"
                          basic
                          color={d.role === "admin" ? "red" : "blue"}
                        >
                          {d.role}
                        </Label>
                      </Table.Cell>
                      <Table.Cell>
                        <Label
                          size="mini"
                          basic
                          color={
                            (d.accessLevel || "full") === "full"
                              ? "orange"
                              : "green"
                          }
                        >
                          {d.accessLevel || "full"}
                        </Label>
                      </Table.Cell>
                    </Table.Row>
                  ))}
                </Table.Body>
              </Table>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </div>
    );
  }
}
