import React, { useEffect, useState } from "react";
import {
  Grid,
  Dimmer,
  Loader,
  Header,
  Popup,
  Divider,
} from "semantic-ui-react";
import { useStitchAuth } from "../StitchAuth";
import { useStitchProviders } from "../StitchProviders";
import { Link } from "react-router-dom";
import { numberWithCommas } from "../Common/utils";
import { ResponsiveBar } from "@nivo/bar";

function Chart(props) {
  return (
    <Grid.Column>
      <Link to={props.path || "#"}>{props.title}</Link>
      <ResponsiveBar
        data={props.data}
        height={400}
        keys={["cnt"]}
        indexBy="name"
        layout="horizontal"
        margin={{ top: 0, right: 10, bottom: 100, left: 310 }}
        padding={0.3}
        valueScale={{ type: "linear" }}
        indexScale={{ type: "band", round: true }}
        colors={{ scheme: "nivo" }}
        colorBy="index"
        axisTop={null}
        axisRight={null}
        axisBottom={{
          tickSize: 5,
          tickPadding: 5,
          tickRotation: -45,
          legend: "",
          legendPosition: "middle",
          legendOffset: 50,
        }}
        axisLeft={{
          tickSize: 5,
          tickPadding: 5,
          tickRotation: 0,
          legend: "",
          legendPosition: "middle",
          legendOffset: -60,
        }}
        labelSkipWidth={12}
        labelSkipHeight={12}
        labelTextColor={{
          from: "color",
          modifiers: [["darker", 1.6]],
        }}
      />
    </Grid.Column>
  );
}

export default function Profile(props) {
  const {
    userCustomData,
    db: { userstats },
  } = useStitchAuth();
  const { providerOne, actions } = useStitchProviders();
  const [loading, setLoading] = useState(true);
  const [lastAccessDt, setLastAccessDt] = useState("N/A");
  const [accessCnt, setAccessCnt] = useState(0);

  useEffect(() => {
    if (userCustomData) {
      actions.loadProvider(userCustomData.group_id, props.providerId);

      if (userstats) {
        userstats
          .find({
            owner_id: userCustomData.group_id,
            npi: props.providerId,
          })
          .then((res) => {
            const currentYear = new Date().toISOString().substring(0, 4);
            let tmpCnt = 0;
            let tmpLast = "1970-01-01";
            res.forEach((x) => {
              if (x.date.substring(0, 4) === currentYear) {
                tmpCnt += 1;
                if (x.date.localeCompare(tmpLast) > 0) {
                  tmpLast = x.date;
                }
              }
            });
            setAccessCnt(tmpCnt);
            if (tmpLast !== "1970-01-01") {
              setLastAccessDt(tmpLast);
            }
          });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userCustomData, props.providerId, userstats]);

  useEffect(() => {
    if (providerOne) {
      setLoading(false);
    }
  }, [providerOne]);

  if (loading) {
    return (
      <div className="messages box">
        <Dimmer active inverted>
          <Loader inverted>Loading</Loader>
        </Dimmer>
      </div>
    );
  } else {
    return (
      <div className="messages box">
        {providerOne && userCustomData && (
          <Grid style={{ margin: 0 }}>
            <Grid.Row columns="four">
              <Grid.Column>
                <Header as="h2">
                  <Link to={`/members?npi=${providerOne.npi}`}>
                    {numberWithCommas(providerOne.member_cnt)}
                  </Link>
                  <Header.Subheader>Number of Members</Header.Subheader>
                </Header>
              </Grid.Column>
              <Grid.Column>
                <Header as="h4">
                  {providerOne._org_data["Address 1"]}
                  <br />
                  {providerOne._org_data["City"]}
                  {", "}
                  {providerOne._org_data["State/Territory"]}{" "}
                  {providerOne._org_data["Zip Code"]}
                  <Header.Subheader>Provider Address</Header.Subheader>
                </Header>
              </Grid.Column>
              <Grid.Column>
                <Header as="h4">
                  {providerOne.phone}
                  <Header.Subheader>Phone</Header.Subheader>
                </Header>
              </Grid.Column>
              <Grid.Column>
                <Header as="h4">
                  {accessCnt}
                  <Header.Subheader>Portal Access Count</Header.Subheader>
                  <Header.Subheader>
                    Last Access: {lastAccessDt}
                  </Header.Subheader>
                </Header>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row columns="four">
              <Grid.Column>
                <Header as="h2">
                  {Math.round(providerOne.raf_my * 1000) / 1000}
                  <Header.Subheader>
                    RAF Estimate {`(Y${new Date().getFullYear() - 2000})`}
                  </Header.Subheader>
                </Header>
              </Grid.Column>
              <Grid.Column>
                <Header as="h2" className="teal">
                  {Math.round(providerOne.raf_py * 1000) / 1000}
                  <Header.Subheader>
                    RAF Estimate {`(Y${new Date().getFullYear() - 2001})`}
                  </Header.Subheader>
                </Header>
              </Grid.Column>
              <Grid.Column>
                <Header as="h2">
                  <Popup
                    content={
                      "Ideally, this should be over 103% by the end of the year."
                    }
                    trigger={
                      <span
                        className={
                          providerOne.raf_my / providerOne.raf_py > 1.03
                            ? "text green"
                            : "text red"
                        }
                      >
                        {Math.round(
                          (providerOne.raf_my / providerOne.raf_py) * 1000
                        ) / 10}
                        %
                      </span>
                    }
                  ></Popup>
                  <Header.Subheader>RAF Completion Ratio</Header.Subheader>
                </Header>
              </Grid.Column>
              <Grid.Column>
                <Header as="h2">
                  <Popup
                    content={
                      "Ideally, this should be below 7% by the end of the year."
                    }
                    trigger={
                      <span
                        className={
                          providerOne.acr_num / providerOne.acr_denom < 0.07
                            ? "text green"
                            : "text red"
                        }
                      >
                        {Math.round(
                          (providerOne.acr_num / providerOne.acr_denom) * 1000
                        ) / 10}
                        %
                      </span>
                    }
                  ></Popup>

                  <Header.Subheader>
                    All-cause Readmission Rate
                  </Header.Subheader>
                </Header>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row columns="four">
              <Grid.Column>
                <Header as="h2">
                  $
                  {numberWithCommas(
                    Math.round(providerOne.total_cost_my * 100) / 100
                  )}
                  <Header.Subheader>
                    Cost PMPY {`(Y${new Date().getFullYear() - 2000})`}
                  </Header.Subheader>
                </Header>
              </Grid.Column>
              <Grid.Column>
                <Header as="h2" className="teal">
                  $
                  {numberWithCommas(
                    Math.round(providerOne.total_cost_py * 100) / 100
                  )}
                  <Header.Subheader>
                    Cost PMPY {`(Y${new Date().getFullYear() - 2001})`}
                  </Header.Subheader>
                </Header>
              </Grid.Column>
              <Grid.Column>
                <Header as="h2">
                  <Popup
                    content={
                      "Ideally, this should be less than 100% by the end of the year."
                    }
                    trigger={
                      <span className="text red">
                        {Math.round(
                          (providerOne.total_cost_my /
                            providerOne.total_cost_py) *
                            1000
                        ) / 10}
                        %
                      </span>
                    }
                  ></Popup>
                  <Header.Subheader>Cost Build-up Ratio</Header.Subheader>
                </Header>
              </Grid.Column>
              <Grid.Column>
                <Header as="h2">
                  <Popup
                    content={
                      "Unplanned Admission with Multiple Chronic Conditions."
                    }
                    trigger={<span>{Math.round(providerOne.uamcc)}</span>}
                  ></Popup>
                  <Header.Subheader>UAMCC Counts</Header.Subheader>
                </Header>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row columns="four">
              <Grid.Column>
                <Header as="h2">
                  <Popup
                    content={
                      "Ideally, this should be over 85% by the end of the year."
                    }
                    trigger={
                      <span
                        className={
                          providerOne.awv > 0.85 ? "text green" : "text red"
                        }
                      >
                        {Math.round(providerOne.awv * 1000) / 10}%
                      </span>
                    }
                  ></Popup>
                  <Header.Subheader>AWV Rate</Header.Subheader>
                </Header>
              </Grid.Column>
              <Grid.Column>
                <Header as="h2">
                  {Math.round(providerOne.npv * 1000) / 1000}
                  <Header.Subheader>Avg. PCP Visits</Header.Subheader>
                </Header>
              </Grid.Column>
              <Grid.Column>
                <Header as="h2">
                  {Math.round(providerOne.gic_open_cnt * 1000) / 1000}
                  <Header.Subheader>Avg. Open Gaps-in-Care</Header.Subheader>
                </Header>
              </Grid.Column>
              <Grid.Column>
                <Header as="h2">
                  {Math.round(providerOne.gic_closed_cnt * 1000) / 1000}
                  <Header.Subheader>Avg. Closed Gaps-in-Care</Header.Subheader>
                </Header>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row columns="four">
              <Grid.Column>
                <Header as="h2">
                  {Math.round(providerOne.hcc_closed_cnt * 1000) / 1000}
                  <Header.Subheader>Avg. HCC Captured</Header.Subheader>
                </Header>
              </Grid.Column>
              <Grid.Column>
                <Header as="h2">
                  {Math.round(providerOne.hcc_recapture_cnt * 1000) / 1000}
                  <Header.Subheader>
                    Avg. HCC Recap. Opportunity
                  </Header.Subheader>
                </Header>
              </Grid.Column>
              <Grid.Column>
                <Header as="h2">
                  {Math.round(providerOne.hcc_suspect_cnt * 1000) / 1000}
                  <Header.Subheader>
                    Avg. HCC Susp. Opportunity
                  </Header.Subheader>
                </Header>
              </Grid.Column>
              <Grid.Column>
                <Header as="h2">
                  {Math.round(providerOne.days_at_care * 1000) / 1000}
                  <Header.Subheader>Avg. Days at Care</Header.Subheader>
                </Header>
              </Grid.Column>
            </Grid.Row>
            <Divider />
            <Grid.Row columns="two">
              <Chart
                title="HCC Distribution (Closed)"
                data={providerOne.hcc_closed.slice(0, 10).reverse()}
              />
              <Chart
                title="HCC Recapture Opportunities (Open)"
                data={providerOne.hcc_recapture.slice(0, 10).reverse()}
              />
            </Grid.Row>
            <Grid.Row columns="two">
              <Chart
                title="Acute Inpatient Hospital Visits"
                data={providerOne.ip_facilities.slice(0, 10).reverse()}
              />
              <Chart
                title="Outpatient Visits"
                data={providerOne.op_facilities.slice(0, 10).reverse()}
              />
            </Grid.Row>
            <Grid.Row columns="two">
              <Chart
                title="Skilled Nursing Facilities Visits"
                data={providerOne.snf_facilities.slice(0, 10).reverse()}
              />
              <Chart
                title="Home Health Visits"
                data={providerOne.hh_facilities.slice(0, 10).reverse()}
              />
            </Grid.Row>
            <Grid.Row columns="two">
              <Chart
                title="Gaps-in-Care Closed"
                data={providerOne.gic_closed.slice(0, 10).reverse()}
              />
              <Chart
                title="Gaps-in-Care Open"
                data={providerOne.gic_open.slice(0, 10).reverse()}
              />
            </Grid.Row>
          </Grid>
        )}
      </div>
    );
  }
}
