import React, { useEffect, useState } from "react";
import {
  Grid,
  Table,
  Dimmer,
  Loader,
  Dropdown,
  List,
  Popup,
  Label,
  Item,
} from "semantic-ui-react";
import { useStitchAuth } from "../StitchAuth";
import { useStitchMembers } from "../StitchMembers";
import { Link, useHistory } from "react-router-dom";
import {
  numberWithCommas,
  useQuery,
  getEndOfMonth,
  toTitleCase,
} from "../Common/utils";
import serviceOptions from "../data/service_options.json";
import { ResponsiveCalendar } from "@nivo/calendar";

export default function ClaimsData(props) {
  const {
    db: { providers },
    userCustomData,
    accessLevel,
  } = useStitchAuth();
  const { memberOne, actions } = useStitchMembers();
  const [loading, setLoading] = useState(true);
  const [claimsData, setClaimsData] = useState([]);
  let query = useQuery();
  const history = useHistory();
  const [dateRange, setDateRange] = useState([]);
  const serviceOptions2 = [
    { key: "any", value: "any", text: "Any Service Types" },
    ...serviceOptions,
  ];
  //const [rafOptions, setRafOptions] = useState([{key: 'any', value: 'any', text: 'Any RAF'}];
  const [nivoCalendar, setNivoCalendar] = useState([]);
  const [nivoCIdx, setNivoCIdx] = useState(-1);
  const [pcps, setPcps] = useState([]);

  useEffect(() => {
    if (providers) {
      providers
        .find(
          {},
          { projection: { npi: 1, firstName: 1, lastName: 1, nMembers: 1 } }
        )
        .then((res) => {
          setPcps(res);
        });
    }
  }, [providers]);

  useEffect(() => {
    if ((memberOne || {}).mbi !== props.memberId) {
      actions.loadMember(props.memberId);
    }
    if (
      ((memberOne || {}).mbi === props.memberId &&
        accessLevel === "limited" &&
        userCustomData.npi !== (memberOne || {}).pcp_npi) ||
      (memberOne || {}).error === "no member found"
    ) {
      history.push({
        pathname: "/members",
        search: `?&npi=${userCustomData.npi}`,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.memberId, memberOne]);

  useEffect(() => {
    const filterClaims = async (serviceType, dateRangeStart, dateRangeEnd) => {
      if (!query.serviceType || !query.dateRangeStart || !query.dateRangeEnd) {
        history.push({
          pathname: `/members/${props.memberId}/claims-data`,
          search:
            `?view=${query.view || "all-members"}` +
            `&npi=${query.npi || "any"}` +
            `&raf=${query.raf || "any"}` +
            `&sort=${query.sort || "riskScoreDesc"}` +
            `&page=${query.page || 1}` +
            `&serviceType=${query.serviceType || "any"}` +
            `&dateRangeStart=${query.dateRangeStart || "na"}` +
            `&dateRangeEnd=${query.dateRangeEnd || "na"}`,
        });
      } else {
        setLoading(true);
        let claimsTmp = [...memberOne.claimsData].reverse();
        if (query.dateRangeStart !== "na") {
          claimsTmp = claimsTmp.filter(
            (x) => new Date(x.time) >= new Date(query.dateRangeStart + "-01")
          );
        }
        if (query.dateRangeEnd !== "na") {
          claimsTmp = claimsTmp.filter(
            (x) =>
              new Date(x.time) < new Date(getEndOfMonth(query.dateRangeEnd))
          );
        }
        if (query.serviceType !== "any") {
          claimsTmp = claimsTmp.filter(
            (x) => x.serviceGroup === query.serviceType
          );
        }
        let nivoTmp = {};
        claimsTmp.forEach((x) => {
          const date = x.time.substring(0, 10);
          nivoTmp[date] = Math.round(
            nivoTmp[date] ? nivoTmp[date] + x.cost : x.cost
          );
        });
        nivoTmp = Object.entries(nivoTmp)
          .map((x) => {
            return { day: x[0], value: x[1], year: x[0].substring(0, 4) };
          })
          .sort((a, b) => a.day.localeCompare(b.day));
        nivoTmp = nivoTmp.reduce((acc, value) => {
          if (!acc[value.year]) {
            acc[value.year] = { year: value.year, data: [] };
          }
          acc[value.year].data.push(value);
          return acc;
        }, {});

        setNivoCalendar(nivoTmp);
        const years = Object.keys(nivoTmp);
        setNivoCIdx(years.length > 0 ? years[years.length - 1] : -1);

        setClaimsData(claimsTmp);
        await new Promise((r) => setTimeout(r, 100));
        setLoading(false);
      }
    };

    if (memberOne && memberOne.error !== "no member found") {
      filterClaims(query.serviceType, query.dateRangeStart, query.dateRangeEnd);
      setDateRange(
        [...new Set(memberOne.claimsData.map((x) => x.time.substring(0, 7)))]
          .reverse()
          .map((x) => {
            return { key: x, value: x, text: x };
          })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    memberOne,
    history,
    query.serviceType,
    query.dateRangeStart,
    query.dateRangeEnd,
  ]);

  if (loading) {
    return (
      <div className="messages box">
        <Dimmer active inverted>
          <Loader inverted>Loading</Loader>
        </Dimmer>
      </div>
    );
  } else {
    return (
      <div className="messages box" style={{ margin: 0 }}>
        <Grid>
          <Grid.Row columns={3}>
            <Grid.Column>
              <small style={{ fontWeight: 300 }}>
                Show claims that are &nbsp;
                <Dropdown
                  placeholder="any service type"
                  inline
                  scrolling
                  value={query.serviceType}
                  options={serviceOptions2}
                  onChange={(e, { value }) => {
                    history.push({
                      pathname: `/members/${props.memberId}/claims-data`,
                      search:
                        `?view=${query.view || "all-members"}` +
                        `&npi=${query.npi || "any"}` +
                        `&raf=${query.raf || "any"}` +
                        `&sort=${query.sort || "riskScoreDesc"}` +
                        `&page=${query.page || 1}` +
                        `&serviceType=${value}` +
                        `&dateRangeStart=${query.dateRangeStart || "na"}` +
                        `&dateRangeEnd=${query.dateRangeEnd || "na"}`,
                    });
                  }}
                />
                <br />
                &nbsp;between&nbsp;
                <Dropdown
                  placeholder="Any"
                  inline
                  scrolling
                  value={query.dateRangeStart}
                  options={[
                    { key: "na", value: "na", text: "Earliest Date" },
                    ...dateRange,
                  ]}
                  onChange={(e, { value }) => {
                    history.push({
                      pathname: `/members/${props.memberId}/claims-data`,
                      search:
                        `?view=${query.view || "all-members"}` +
                        `&npi=${query.npi || "any"}` +
                        `&raf=${query.raf || "any"}` +
                        `&sort=${query.sort || "riskScoreDesc"}` +
                        `&page=${query.page || 1}` +
                        `&serviceType=${query.serviceType || "any"}` +
                        `&dateRangeStart=${value}` +
                        `&dateRangeEnd=${query.dateRangeEnd || "na"}`,
                    });
                  }}
                />
                &nbsp;and&nbsp;
                <Dropdown
                  placeholder="Any"
                  inline
                  scrolling
                  value={query.dateRangeEnd}
                  options={[
                    { key: "na", value: "na", text: "Latest Date" },
                    ...dateRange,
                  ]}
                  onChange={(e, { value }) => {
                    history.push({
                      pathname: `/members/${props.memberId}/claims-data`,
                      search:
                        `?view=${query.view || "all-members"}` +
                        `&npi=${query.npi || "any"}` +
                        `&raf=${query.raf || "any"}` +
                        `&sort=${query.sort || "riskScoreDesc"}` +
                        `&page=${query.page || 1}` +
                        `&serviceType=${query.serviceType || "any"}` +
                        `&dateRangeStart=${query.dateRangeStart || "na"}` +
                        `&dateRangeEnd=${value}`,
                    });
                  }}
                />
                <br />
                Results: {claimsData.length} claims...
              </small>
            </Grid.Column>
            <Grid.Column>
              <small>
                The chart on the right shows the Calendar View of all filtered
                claims. The color represents the total cost of each day. Click
                "Years" to view other years. Years:&nbsp;&nbsp;
                {Object.keys(nivoCalendar).map((x) => (
                  <Item key={x} as="a" onClick={() => setNivoCIdx(x)}>
                    <span style={{ fontWeight: nivoCIdx === x ? 900 : 300 }}>
                      {x}
                    </span>
                    &nbsp;&nbsp;
                  </Item>
                ))}
              </small>
            </Grid.Column>
            <Grid.Column style={{ height: 80 }}>
              {nivoCIdx > 0 && (
                <ResponsiveCalendar
                  data={nivoCalendar[nivoCIdx].data}
                  height={80}
                  from={nivoCIdx + "-01-01T00:00:00"}
                  to={nivoCIdx + "-12-31T00:00:00"}
                  align="left"
                  emptyColor="#eeeeee"
                  colors={["#61cdbb", "#97e3d5", "#e8c1a0", "#f47560"]}
                  margin={{ top: 20, right: 20, bottom: 20, left: 20 }}
                  yearSpacing={40}
                  monthBorderColor="#ffffff"
                  dayBorderWidth={2}
                  dayBorderColor="#ffffff"
                />
              )}
            </Grid.Column>
          </Grid.Row>
          <Grid.Row columns={1}>
            <Grid.Column textAlign="center">
              <Table basic="very" size="small" textAlign="center">
                <Table.Header>
                  <Table.Row>
                    <Table.HeaderCell width={2}>Date</Table.HeaderCell>
                    <Table.HeaderCell width={2}>Service Type</Table.HeaderCell>
                    <Table.HeaderCell width={3}>Provider</Table.HeaderCell>
                    <Table.HeaderCell width={2}>Cost / Risk</Table.HeaderCell>
                    <Table.HeaderCell>
                      <span className="red text">Diagnoses</span> /{" "}
                      <span className="purple text">Procedures</span> /{" "}
                      <span className="green text">Medications</span>
                    </Table.HeaderCell>
                  </Table.Row>
                </Table.Header>
                <Table.Body>
                  {claimsData.map((d, i) => (
                    <Table.Row key={i}>
                      <Table.Cell>
                        {d.time}
                        <br />
                        {d.los > 0 && <small> ({d.los} days)</small>}
                        <br />
                        {d.isIndexAdmission && (
                          <Label basic size="mini" color="yellow">
                            Index Admission
                          </Label>
                        )}
                      </Table.Cell>
                      <Table.Cell>
                        {d.serviceGroup.slice(7)}&nbsp;
                        {d.instCategory !== "" && (
                          <Label size="mini" basic>
                            {d.instCategory}
                          </Label>
                        )}
                        <br />
                        <small className="grey text">
                          <i>Source: {d.source}</i>
                        </small>
                      </Table.Cell>
                      <Table.Cell>
                        <List>
                          {d.providers.map((x, j) => {
                            if (pcps.find((y) => y.npi === x.npi)) {
                              return (
                                <Link
                                  to={`/providers/${x.npi}/profile?sort=scoreDesc`}
                                  key={j}
                                >
                                  <Popup
                                    content={
                                      <small>
                                        <List>
                                          <List.Item>NPI: {x.npi}</List.Item>
                                          <List.Item>
                                            Phone: {(x.details || {}).phone}
                                          </List.Item>
                                          <List.Item>
                                            Specialty: {x.details.specialtyDesc}
                                          </List.Item>
                                        </List>
                                      </small>
                                    }
                                    trigger={
                                      <List.Item key={j}>
                                        {Object.keys(x.details).length > 0
                                          ? x.details.type === "1"
                                            ? `${toTitleCase(
                                                x.details.firstName
                                              )} ${toTitleCase(
                                                x.details.lastName
                                              )}`
                                            : `${toTitleCase(
                                                x.details.businessName
                                              )}`
                                          : "NA"}
                                        {` (${x.category})`}
                                      </List.Item>
                                    }
                                  />
                                </Link>
                              );
                            } else {
                              return (
                                <Popup
                                  key={j}
                                  content={
                                    <small>
                                      <List>
                                        <List.Item>NPI: {x.npi}</List.Item>
                                        {x.details && (
                                          <List.Item>
                                            Phone: {x.details.phone}
                                          </List.Item>
                                        )}
                                        {x.details && (
                                          <List.Item>
                                            Specialty: {x.details.specialtyDesc}
                                          </List.Item>
                                        )}
                                      </List>
                                    </small>
                                  }
                                  trigger={
                                    <List.Item key={j}>
                                      {Object.keys(x.details || {}).length > 0
                                        ? x.details.type === "1"
                                          ? `${toTitleCase(
                                              x.details.firstName
                                            )} ${toTitleCase(
                                              x.details.lastName
                                            )}`
                                          : `${toTitleCase(
                                              x.details.businessName
                                            )}`
                                        : "NA"}
                                      {` (${x.category})`}
                                    </List.Item>
                                  }
                                />
                              );
                            }
                          })}
                        </List>
                      </Table.Cell>
                      <Table.Cell textAlign="center">
                        {numberWithCommas(Math.round(d.cost), "$")}
                        <br />
                        <small className="grey text">
                          Risk: {Math.round(d.riskScore * 1000) / 1000}
                        </small>
                        <br />
                        {[...new Set(d.riskDescShort)].map((x) => (
                          <Label size="mini" basic key={x}>
                            {x}
                          </Label>
                        ))}
                      </Table.Cell>
                      <Table.Cell>
                        {d.msdrg.length > 0 &&
                          d.msdrg.some(
                            (x) => x.code !== "" && x.code !== "000"
                          ) && (
                            <div style={{ padding: "0.5em" }}>
                              {d.msdrg.map((x, j) => (
                                <Label key={j} size="mini" color="red">
                                  DRG{x.code}, {x.display}
                                </Label>
                              ))}
                            </div>
                          )}
                        {d.diagnoses.length > 0 && (
                          <div style={{ padding: "0.5em" }}>
                            {d.diagnoses.map((x, j) => {
                              if (d.riskProfile[x.code]) {
                                return (
                                  <Popup
                                    key={j}
                                    content={
                                      <small>
                                        {x.display +
                                          " *" +
                                          d.riskProfile[x.code].join(",") +
                                          "*"}
                                      </small>
                                    }
                                    trigger={
                                      <Label
                                        size="mini"
                                        basic
                                        color={
                                          d.riskProfile[x.code] ? "red" : "grey"
                                        }
                                      >
                                        {x.code}: {x.display.substring(0, 20)}
                                        ...
                                      </Label>
                                    }
                                  />
                                );
                              } else if (x.display.length > 20) {
                                return (
                                  <Popup
                                    key={j}
                                    content={<small>{x.display}</small>}
                                    trigger={
                                      <Label
                                        size="mini"
                                        basic
                                        color={
                                          d.riskProfile[x.code] ? "red" : "grey"
                                        }
                                      >
                                        {x.code}: {x.display.substring(0, 20)}
                                        ...
                                      </Label>
                                    }
                                  />
                                );
                              } else {
                                return (
                                  <Label
                                    key={j}
                                    size="mini"
                                    basic
                                    color={
                                      d.riskProfile[x.code] ? "red" : "grey"
                                    }
                                  >
                                    {x.code}: {x.display}
                                  </Label>
                                );
                              }
                            })}
                          </div>
                        )}
                        {d.procedures.length > 0 && (
                          <div style={{ padding: "0.5em" }}>
                            {d.procedures.map((x, j) => {
                              if (x.display.length > 20) {
                                return (
                                  <Popup
                                    key={j}
                                    content={x.display}
                                    trigger={
                                      <Label size="mini" basic color="violet">
                                        {x.code}: {x.display.substring(0, 20)}
                                        ...
                                      </Label>
                                    }
                                  />
                                );
                              } else {
                                return (
                                  <Label
                                    key={j}
                                    size="mini"
                                    basic
                                    color="violet"
                                  >
                                    {x.code}: {x.display}
                                  </Label>
                                );
                              }
                            })}
                          </div>
                        )}
                        {d.medications.length > 0 && (
                          <div style={{ padding: "0.5em" }}>
                            {d.medications.map((x, j) => (
                              <Label key={j} size="mini" basic color="green">
                                {x.code}: {x.display} ({x.gpi4})
                              </Label>
                            ))}
                          </div>
                        )}
                      </Table.Cell>
                    </Table.Row>
                  ))}
                </Table.Body>
              </Table>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </div>
    );
  }
}
