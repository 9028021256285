import React, {useState } from "react";
import AppBar from "../Common/AppBar";
import "../Common/Layout.css";
import { Header, Form, Grid, } from 'semantic-ui-react'
import { useStitchAuth } from '../StitchAuth';
import { 
  useHistory,
} from "react-router-dom";

export default function Agreement(props){

  const { actions } = useStitchAuth();
  const [name, setName] = useState({firstName: '', 
                                    lastName: ''});
  const [phoneNumber, setPhoneNumber] = useState('');
  const [address, setAddress] = useState({line1: '', 
                                      line2: '',
                                      city: '',
                                      state: '',
                                      zipCode: ''});
  let history = useHistory();

  const handlePhoneNumberChange = (event) => {
    const {value} = event.target;
    if (value.length < phoneNumber.length){
      if (value.length > 0 && value[value.length-1]==='-'){
        setPhoneNumber(value.slice(0,value.length-1))
      }else{
        setPhoneNumber(value);        
      }
    }else{
      const numOnly = value.replace(/(\D+)/g, "");
      let phoneFormat = numOnly.slice(0,3);
      if (numOnly.length >= 3){ 
        phoneFormat = phoneFormat + '-' + numOnly.slice(3,6);
      }
      if (numOnly.length >= 6){
        phoneFormat = phoneFormat + '-' + numOnly.slice(6, 10);
      }
      setPhoneNumber(phoneFormat);      
    }

  }

  const handleAddressChange = (event) => {
    const {name, value} = event.target;
    setAddress({...address, [name]: value});
  }

  const handleSubmit = (event) => {

    const fullname =  name.firstName.replace(/ /g, "") + ' ' + name.lastName.replace(/ /g, "");  
    const fulladdress = (address.line1 + ' ' + address.line2 + ', ' + 
                        address.city + ', ' + address.state + ' ' + address.zipCode).replace(/\s\s+/g, ' '); 
    const agreement = {
      agreement: {
        contactName: fullname,
        address: fulladdress,
        phoneNumber: phoneNumber
      },
      termsAndConditions: 'agreed', // deprecated
      customer_agreement_consent: true, // deprecated
      hasBasicInformation: true,
      signedContract: false,
      signedDate: new Date().toISOString()
    };
    actions.updateAgreement(agreement).then(()=>{
      history.push('/home/all-members');
    });
  }

  return (
      <div className='app-layout'>
        <AppBar />
        <div className='channels box'>
          <Header as='h4' style={{paddingLeft: '1em', color: '#fff'}}>
            User Info
          </Header>
        </div>
        <div className='messages'>
          <Grid style={{margin: 0, padding: '2em'}}>
            <Grid.Row textAlign="left" columns={1} >
              <Grid.Column width={10} verticalAlign='middle'>
                <Header as='h2' style={{fontWeight: 300}}>
                  Tell us more about yourself to finalize your account settings.
                  <Header.Subheader>

                  </Header.Subheader>
                </Header>
                <Form >
                  <Form.Input fluid 
                    required
                    name='firstName' label='First Name' placeholder='First name' 
                    value={name.firstName} 
                    onChange={(e) => setName({...name, firstName: e.target.value})} />
                  <Form.Input fluid 
                    required
                    name='lastName' label='Last name' placeholder='Last name' 
                    value={name.lastName}  
                    onChange={(e) => setName({...name, lastName: e.target.value})} />     
                  <Form.Input fluid name="phoneNumber" 
                      required
                      label="Your Phone Number"
                      placeholder='000-000-0000' 
                      value={phoneNumber} 
                      onChange={handlePhoneNumberChange} />
                  <Form.Input fluid 
                      required
                      name='line1' label='Address 1' 
                      placeholder='Street Address'
                      value={address.line1}
                      onChange={handleAddressChange} />
                  <Form.Input fluid 
                      name='line2' label='Address 2' 
                      placeholder='Apartment, Building, Floor, etc.' 
                      value={address.line2}
                      onChange={handleAddressChange} />
                  <Form.Input fluid 
                      required
                      name='city' label='City' 
                      placeholder='City'
                      value={address.city}
                      onChange={handleAddressChange} />
                  <Form.Group widths='equal'>
                    <Form.Input fluid 
                      required
                      name='state' label='State' 
                      placeholder='State'
                      value={address.state}
                      onChange={handleAddressChange} />
                    <Form.Input fluid   
                      required
                      name='zipCode' label='Zip/Postal Code' 
                      placeholder='00000'
                      value={address.zipCode}
                      onChange={handleAddressChange} />
                  </Form.Group>
                  <Form.Button primary onClick={handleSubmit}>
                    Submit
                  </Form.Button>
                </Form>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </div>
      </div>
    );
}
