import React, { useEffect, useState } from "react";
import { Grid, Dimmer, Loader, List, Button } from "semantic-ui-react";
import { useStitchAuth } from "../StitchAuth";
import { saveSync } from "save-file";

export default function Wcrfs(props) {
  const {
    userCustomData,
    currentUser,
    db: { wcrfs },
  } = useStitchAuth();
  const [reportList, setReportList] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (currentUser && userCustomData) {
      currentUser.functions
        .fetchReports(userCustomData.group_id)
        .then((data) => {
          setReportList(data.sort((a, b) => b.fn.localeCompare(a.fn)));
          setLoading(false);
        });
    }
  }, [currentUser, userCustomData]);

  const exportReport = async (fn) => {
    setLoading(true);

    const doc = await wcrfs.findOne({
      owner_id: userCustomData.group_id,
      fn: fn,
    });
    const csvFile =
      doc.header.join(",") + "\n" + doc.data.map((d) => d.join(",")).join("\n");
    await saveSync(`${csvFile}`, fn);

    setLoading(false);
  };

  const exportReportAll = async () => {
    setLoading(true);

    const docs = await wcrfs.find({
      owner_id: userCustomData.group_id,
    });

    let csvFile = "";
    docs.forEach((doc) => {
      const reportDateRaw = doc.fn.split(".")[4];
      const reportDate =
        "20" +
        reportDateRaw.slice(1, 3) +
        "-" +
        reportDateRaw.slice(3, 5) +
        "-" +
        reportDateRaw.slice(5, 7);
      if (csvFile === "") {
        csvFile =
          doc.header.join(",") +
          ",Reporting Date" +
          "\n" +
          doc.data.map((d) => d.join(",") + "," + reportDate).join("\n");
      } else {
        csvFile =
          csvFile +
          "\n" +
          doc.data.map((d) => d.join(",") + "," + reportDate).join("\n");
      }
    });

    await saveSync(`${csvFile}`, "wcrfs-all.csv");

    setLoading(false);
  };

  if (loading) {
    return (
      <div className="messages box">
        <Dimmer active inverted>
          <Loader inverted>Loading</Loader>
        </Dimmer>
      </div>
    );
  } else {
    return (
      <div className="messages box">
        <Grid style={{ margin: 0 }}>
          <Grid.Row columns={2}>
            <Grid.Column>
              <List>
                <List.Item>
                  <Button primary onClick={() => exportReportAll()}>
                    Download All in One File
                  </Button>
                </List.Item>
                {reportList.map((d) => (
                  <List.Item key={d.fn}>
                    <Button basic key={d.fn} onClick={() => exportReport(d.fn)}>
                      Download {d.fn}
                    </Button>
                  </List.Item>
                ))}
              </List>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </div>
    );
  }
}
