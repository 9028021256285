import _ from 'lodash';
import 'moment-timezone';
import moment from 'moment-timezone';

function getDates(member, key, timezone){
	const data = _.filter(member[key], x => !x.exclude);
	return _.map(data, x => moment.tz(x.time, timezone).format('YYYY-MM-DD'));
}

function getDayOfWeek(member, key, timezone){
	const data = _.filter(member[key], x => !x.exclude);
	return _.map(data, x => moment.tz(x.time, timezone).format('E'));
}

function getTimeOfDay(member, key, timezone){
	const data = _.filter(member[key], x => !x.exclude);
  
	return _.map(data, x => moment.tz(x.time, timezone).format('HH'));
}

function getActiveDates(member, timezone){
	return _.union(getDates(member, 'bodyWeightData', timezone),
					getDates(member, 'systolicBPData', timezone),
					getDates(member, 'ecgData', timezone));
}



export function getYM(x){ 
  if (x){
    return new Date(x).toISOString().slice(0,7);
  }else{
    return new Date().toISOString().slice(0,7);
  }
}

export function getOperationalInsights(memberAll, timezone, billingConfig){

  let numActives = {};
  let totTexts = 0;
  let numTexts = {};
  let totNotes = 0;
  let numNotes = {};
  let dayOfWeek = {};
  let timeOfDay = {};
  let dayOfWeekBW = {};
  let timeOfDayBW = {};
  let dayOfWeekBP = {};
  let timeOfDayBP = {};  
  let dayOfWeekECG = {};
  let timeOfDayECG = {};    
  const ISODoW = {0: 'Mon', 1: 'Tue', 2: 'Wed', 3: 'Thr', 4: 'Fri', 5: 'Sat', 6: 'Sun'};

  let cpts = {CPT99453: 0,
  				CPT99454: 0,
  				CPT99457: 0,
  				CPT99458: 0};

  _.forEach(memberAll, (member) => {

  	// trends
  	_.forEach(getActiveDates(member, timezone), date => {
  		numActives[date] = numActives[date]?(numActives[date]+1):1;
  	});
  	_.forEach(getDates(member, 'messageData', timezone), date => {
  		numTexts[date] = numTexts[date]?(numTexts[date]+1):1;
      totTexts = totTexts + 1;
  	});
  	_.forEach(getDates(member, 'noteData', timezone), date => {
  		numNotes[date] = numNotes[date]?(numNotes[date]+1):1;
      totNotes = totNotes + 1;
  	});

  	// billing
  	_.forEach(member.cpts, (v, k) =>{
  		if (v){
  			cpts[k] = cpts[k] + 1;
  		}
  	});

  	// day of week
  	_.forEach(getDayOfWeek(member, 'bodyWeightData', timezone), day => {
  		dayOfWeekBW[day] = dayOfWeekBW[day]?(dayOfWeekBW[day]+1):1;
  	});
  	_.forEach(getDayOfWeek(member, 'systolicBPData', timezone), day => {
  		dayOfWeekBP[day] = dayOfWeekBP[day]?(dayOfWeekBP[day]+1):1;
  	});
  	_.forEach(getDayOfWeek(member, 'ecgData', timezone), day => {
  		dayOfWeekECG[day] = dayOfWeekECG[day]?(dayOfWeekECG[day]+1):1;
  	});  	

  	// time of day
  	_.forEach(getTimeOfDay(member, 'bodyWeightData', timezone), HH => {
  		timeOfDayBW[HH] = timeOfDayBW[HH]?(timeOfDayBW[HH]+1):1;
  	});  	
  	_.forEach(getTimeOfDay(member, 'systolicBPData', timezone), HH => {
  		timeOfDayBP[HH] = timeOfDayBP[HH]?(timeOfDayBP[HH]+1):1;
  	});  	  	
    _.forEach(getTimeOfDay(member, 'ecgData', timezone), HH => {
      timeOfDayECG[HH] = timeOfDayECG[HH]?(timeOfDayECG[HH]+1):1;
    });           

  });

  numActives = _.sortBy(_.map(numActives, 
  					(v, k) => { return {time: k, value: v}; }), 
  				"time");
  numTexts = _.sortBy(_.map(numTexts, 
  					(v, k) => { return {time: k, value: v}; }), 
  				"time");
  numNotes = _.sortBy(_.map(numNotes, 
  					(v, k) => { return {time: k, value: v}; }), 
  				"time");
  cpts = _.filter(_.map(cpts, (v, k) => {
          	return {cpt: k.slice(3,8), cnt: v};
          }), x => billingConfig[x.cpt]);
  dayOfWeek = _.map([...Array(7).keys()], day => {
  	return {dayOfWeek: ISODoW[day],
  			bodyWeight: dayOfWeekBW[day+1]?dayOfWeekBW[day+1]:0,
  			bloodPressure: dayOfWeekBP[day+1]?dayOfWeekBP[day+1]:0,
  			ECG: dayOfWeekECG[day+1]?dayOfWeekECG[day+1]:0};
  });
  timeOfDay = _.map([...Array(24).keys()], HH => {
  	return {timeOfDay: parseInt(HH),
  			bodyWeight: timeOfDayBW[HH]?timeOfDayBW[HH]:0,
  			bloodPressure: timeOfDayBP[HH]?timeOfDayBP[HH]:0,
  			ECG: timeOfDayECG[HH]?timeOfDayECG[HH]:0};
  });

  return {totMembers: memberAll.length,
        totTexts: totTexts, totNotes: totNotes,
  			numActives: numActives, numTexts: numTexts, numNotes: numNotes,
  			cpts: cpts, dayOfWeek: dayOfWeek, timeOfDay: timeOfDay};
};

function getService30D(member, ymRef){

  // NOTE: 99454 is billable every 30 days, but 99457 is each calendar month
  // This service dates are for the code 99454
  let fromDate = '1970-01-01';
  let toDate = '1970-01-30';
  let i = 0;
  const enrollTime = new Date(member.enrollmentDate).getTime();
  while (toDate.slice(0,7)!==ymRef && i < 48){
    toDate = new Date(enrollTime + (30 * i) * 24 * 60 * 60 * 1000).toISOString().slice(0,10);
    i++;
  }
  fromDate = new Date(enrollTime + (30 * (i-2)) * 24 * 60 * 60 * 1000).toISOString().slice(0,10);    
  return {fromDate: fromDate, toDate: toDate};
}

function isIn30DWindow(x, dateRange){
  return (new Date(x.time).getTime() >= new Date(dateRange.fromDate).getTime() && 
          new Date(x.time).getTime() < new Date(dateRange.toDate).getTime());
}

function get99457FromDate(member, ymRef){
  if(member.enrollmentDate.slice(0,7) === ymRef){
    return member.enrollmentDate.slice(0,10);
  }else{
    return ymRef + '-01';
  }
}

function getLastDateOfMonth(ymRef){
  let endDate = 31
  while (new Date(ymRef + '-' + endDate).toISOString().slice(0,7) !== ymRef && endDate > 0){
    endDate--;
  }
  return new Date(ymRef + '-' + endDate).toISOString();
}

export  function getBillingInsights(member, ymRef=getYM()){

  // NOTE: Billing Insights are based on "UTC" - no timezone specific.
  // NOTE: This should be the replica of the "billingInsights" function in Realm
  const service30D = getService30D(member, ymRef);
  let bState = {
    CPT99453a: false,
    CPT99454a: false,
    CPT99454b: 0,
    CPT99454c: 0,
    CPT99457a: false,
    CPT99457b: false,
    CPT99457c: false,
    numNotes: 0,
    numTxts: 0,
    CPT99458a: false,
    CPT99458b: false,
    CPT99458c: false,
    HCPCSG2012a: false,
    fromDate99454: service30D.fromDate,
    toDate99454: service30D.toDate,
    fromDate99457: get99457FromDate(member, ymRef),
    toDate99457: getLastDateOfMonth(ymRef).slice(0,10),
  };

  const days = [...(member.bodyWeightData || []).filter(x => isIn30DWindow(x, service30D)).map(y => y.time.substring(0,10)),
            ...(member.systolicBPData || []).filter(x => isIn30DWindow(x, service30D)).map(y => y.time.substring(0,10)),
            ...(member.bloodSugarData || []).filter(x => isIn30DWindow(x, service30D)).map(y => y.time.substring(0,10)),
            ...(member.ecgData || []).filter(x => isIn30DWindow(x, service30D)).map(y => y.time.substring(0,10))];

  bState.CPT99453a = ymRef === member.enrollmentDateStr.slice(0,7);

  bState.CPT99454b = ((member.bodyWeightData || []).filter(x => isIn30DWindow(x, service30D)).length + 
                      (member.systolicBPData || []).filter(x => isIn30DWindow(x, service30D)).length + 
                      (member.bloodSugarData || []).filter(x => isIn30DWindow(x, service30D)).length + 
                      (member.ecgData || []).filter(x => isIn30DWindow(x, service30D)).length);
  
  bState.CPT99454c = [...new Set(days)].length;

  bState.CPT99454a = (bState.CPT99454b > 15); // 16 days of data

  // NOTE: timeSpent should be also dependent on ymRef
  if (member.timeSpent[ymRef]){
    bState.CPT99457a = ((member.timeSpent[ymRef].value)/1000/60 > 1)?true:false;
    bState.CPT99458a = ((member.timeSpent[ymRef].value)/1000/60 > 20)?true:false;      
  }

  bState.numNotes = (member.noteData || []).filter(x => getYM(x.time)===ymRef).length;
  bState.numTxts = (member.messageData || []).filter(x => getYM(x.time)===ymRef).length;

  if (bState.numNotes > 0){
    bState.CPT99457b = true;
    bState.CPT99458b = true;
  }

  if (bState.numTxts > 0){
    bState.CPT99457c = true;
    bState.CPT99458c = true;
  }

  _.each(member.messageData, x => {
    bState.HCPCSG2012a = (x.isVirtualCheckIn && getYM(x.time)===ymRef)?true:bState.HCPCSG2012a;
  });

  member.billingInsights = bState;
  member.cpts = {
    CPT99453: bState.CPT99453a,
    CPT99454: bState.CPT99454a,
    CPT99457: bState.CPT99457a && bState.CPT99457b && bState.CPT99457c,
    CPT99458: bState.CPT99458a && bState.CPT99458b && bState.CPT99458c
  };

  return member;
}

export function getFlatRawMeasuresHeader(){
  return ["Name", "DOB", "Reading Type", "Time", "Value"];
}

export function getFlatRawMeasures(member, selectedYm) {

  let bwData = (member.bodyWeightData || []).filter(x => !x.exclude);
  let sbpData = (member.systolicBPData || []).filter(x => !x.exclude);
  let bsData = (member.bloodSugarData || []).filter(x => !x.exclude);
  let dbpMap = {};
  let pulseMap = {};
  (member.diastolicBPData || []).forEach(x => {
    dbpMap[x.time] = x.value;
  });          
  (member.pulseData || []).forEach(x => {
    pulseMap[x.time] = x.value;
  });                              
  
  if (selectedYm){
    bwData = bwData.filter(x => x.time.substring(0,7)===selectedYm);
    sbpData = sbpData.filter(x => x.time.substring(0,7)===selectedYm);
  }

  const bwFlat = bwData.map(x => {
    return [member.name, member.dobStr, 'Weight', 
            new Date(x.time).toLocaleString(), 
            Math.round(x.value) + " lbs"];
  });

  const bsFlat = bsData.map(x => {
    return [member.name, member.dobStr, 'Blood Sugar', 
            new Date(x.time).toLocaleString(), 
            Math.round(x.value) + " mg/dL"];
  }); 

  const sbpFlat = sbpData.map(x => {
    return [member.name, member.dobStr, 'Blood Pressure', 
          new Date(x.time).toLocaleString(), 
          Math.round(x.value*10)/10 + "/" + Math.round(dbpMap[x.time]*10)/10 + " mmHg, " + Math.round(pulseMap[x.time]) + " bpm"];
  });          

  return [...bwFlat, ...sbpFlat, ...bsFlat];

}

function getSimpleStats(arrayInput){
  // mean, sd, CI, numObs, min, max, 25%, 75%
  if (arrayInput.length > 0){
    const array = arrayInput.sort();
    const mean = array.reduce((a, b) => a + b) / array.length;
    const variance = array.reduce((a, b) => (b - mean)**2 + a, 0) / array.length;
    const stats = {
      mean: mean,
      variance: variance,
      sd: Math.sqrt(variance),
      ci95: 1.96 * Math.sqrt(variance) / Math.sqrt(array.length),
      minimum: array[0],
      maximum: array[array.length - 1],
      q1: array[Math.floor(array.length/4)],
      median: array[Math.floor(array.length/2)],
      q3: array[Math.floor(array.length*3/4)]};
    return stats;
  }else{
    return {
      mean: null,
      variance: null,
      sd: null,
      minimum: null,
      maximum: null,
      q1: null,
      median: null,
      q3: null,
    }
  }
}

export function getMedVitalStats(member){
  return member.medications.map(x => {
    const startDate = new Date(x.startYM + '-01').getTime();
    const endDate = x.endYM===""?(new Date().getTime()):(new Date(x.endYM + '-31').getTime());

    x.stats = {
      bodyWeight: getSimpleStats(member.bodyWeightData.filter(
                      x => (!x.exclude && 
                        new Date(x.time).getTime() >= startDate &&
                        new Date(x.time).getTime() <= endDate))
                    .map(x=>x.value)),
      systolicBP: getSimpleStats(member.systolicBPData.filter(
                      x => (!x.exclude && 
                        new Date(x.time).getTime() >= startDate &&
                        new Date(x.time).getTime() <= endDate))
                    .map(x=>x.value)),
      diastolicBP: getSimpleStats(member.diastolicBPData.filter(
                      x => (!x.exclude && 
                        new Date(x.time).getTime() >= startDate &&
                        new Date(x.time).getTime() <= endDate))
                    .map(x=>x.value)),
      bloodSugar: getSimpleStats(member.bloodSugarData.filter(
                      x => (!x.exclude && 
                        new Date(x.time).getTime() >= startDate &&
                        new Date(x.time).getTime() <= endDate))
                    .map(x=>x.value))};
    return x;
  });
}