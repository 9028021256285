import React, { useEffect, useState } from "react";
import { Grid, Dimmer, Loader } from "semantic-ui-react";
import { useStitchAuth } from "../StitchAuth";
import { useStitchProviders } from "../StitchProviders";
import { Link } from "react-router-dom";
import { ResponsiveBar } from "@nivo/bar";

function Chart(props) {
  return (
    <Grid.Column>
      <Link to={props.path || "#"}>{props.title}</Link>
      <ResponsiveBar
        data={props.data}
        height={1500}
        keys={["cnt"]}
        indexBy="name"
        layout="horizontal"
        margin={{ top: 0, right: 10, bottom: 100, left: 310 }}
        padding={0.3}
        valueScale={{ type: "linear" }}
        indexScale={{ type: "band", round: true }}
        colors={{ scheme: "nivo" }}
        colorBy="index"
        axisTop={null}
        axisRight={null}
        axisBottom={{
          tickSize: 5,
          tickPadding: 5,
          tickRotation: -45,
          legend: "",
          legendPosition: "middle",
          legendOffset: 50,
        }}
        axisLeft={{
          tickSize: 5,
          tickPadding: 5,
          tickRotation: 0,
          legend: "",
          legendPosition: "middle",
          legendOffset: -60,
        }}
        labelSkipWidth={12}
        labelSkipHeight={12}
        labelTextColor={{
          from: "color",
          modifiers: [["darker", 1.6]],
        }}
      />
    </Grid.Column>
  );
}

export default function Profile(props) {
  const { userCustomData } = useStitchAuth();
  const { providerOne, actions } = useStitchProviders();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (userCustomData) {
      actions.loadProvider(userCustomData.group_id, props.providerId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userCustomData, props.providerId]);

  useEffect(() => {
    if (providerOne) {
      setLoading(false);
    }
  }, [providerOne]);

  if (loading) {
    return (
      <div className="messages box">
        <Dimmer active inverted>
          <Loader inverted>Loading</Loader>
        </Dimmer>
      </div>
    );
  } else {
    return (
      <div className="messages box">
        {providerOne && userCustomData && (
          <Grid style={{ margin: 0 }}>
            <Grid.Row columns="two">
              <Chart
                title="Acute Inpatient Facilities"
                data={providerOne.ip_facilities.slice(0, 100).reverse()}
              />
              <Chart
                title="Outpatient Facilities"
                data={providerOne.op_facilities.slice(0, 100).reverse()}
              />
            </Grid.Row>
            <Grid.Row columns="two">
              <Chart
                title="Skilled Nursing Facilities"
                data={providerOne.snf_facilities.slice(0, 100).reverse()}
              />
              <Chart
                title="Home Health Facilities"
                data={providerOne.hh_facilities.slice(0, 100).reverse()}
              />
            </Grid.Row>
          </Grid>
        )}
      </div>
    );
  }
}
